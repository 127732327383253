import React, { useEffect, useState } from 'react'
import { Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deftemples from '../../../../assets/images/templeicon.jpg'
import horoscope_icon from "../../../../assets/images/horoscope-img.png";

const { Title, Paragraph } = Typography;

const AboutUs = () => {

    const { t } = useTranslation();
    const userRole = localStorage.getItem('urole');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_terms_conditions')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    &nbsp; <span className="bc-link">{t('About')}</span>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                <div className="privacy-policy-container">

                    <div className="privacy-policy-content">
                        <Typography>
                            <Title level={2}>About Us</Title>
                            <Paragraph>
                                Welcome to www.templeswiki.com. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                            </Paragraph>
                        </Typography>
                    </div>
                </div>
                <Footer/>
                </div>
            </div>
        </>
    )
}

export default AboutUs;