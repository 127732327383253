import HTTPClient from "../http-common";
import API_KEYS from "./apiKeys";

class PriestDataService {

  getPriestDetails(id) {
    return HTTPClient.getAuthHttp().get(`/priest/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getPriestBySpclty(state, city, spclty, lr) {
    return HTTPClient.getHttp().get(`/priest/state/${state}/city/${city}/splty/${spclty}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }


  createPriest(data) {
    return HTTPClient.getAuthHttp().post("/priest", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createNewPriest(id, data) {
    return HTTPClient.getAuthHttp().post(`/priest/${id}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestDetails(id, data) {
    return HTTPClient.getAuthHttp().put(`/priest/${id}/contacts`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestDegreeDetails(data) {
    return HTTPClient.getAuthHttp().put(`/priest/degree`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestSpclty(data) {
    return HTTPClient.getAuthHttp().put(`/priest/splty`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestReqRecent(id, data) {
    return HTTPClient.getAuthHttp().put(`/priest/${id}/recent`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }


  createPriestReq(data) {
    return HTTPClient.getAuthHttp().post("/priestreq", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getMyRequests(status, lr) {
    return HTTPClient.getAuthHttp().get(`/priestreq/myqueries/status/${status}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getPriestRequests(status, lr) {
    return HTTPClient.getAuthHttp().get(`/priestreq/status/${status}/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getMyRequestDetails(id) {
    return HTTPClient.getAuthHttp().get(`/priestreq/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestReqQury(id, data) {
    return HTTPClient.getAuthHttp().put(`/priestreq/${id}/query`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestReqStatus(id, data) {
    return HTTPClient.getAuthHttp().put(`/priestreq/${id}/query`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updatePriestReqResolution(id, data) {
    return HTTPClient.getAuthHttp().put(`/priestreq/${id}/resolution`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

}

export default new PriestDataService();