import axios from "axios";
import API_KEYS from "./services/apiKeys";

export const DEV_API = "https://api.templeswiki.com/templeswiki/api/v1";
export const IMG_API = "https://images.templeswiki.com/images_small/"
export const MEDIUM_IMG_API = "https://images.templeswiki.com/images_medium/"
export const AUDIO_API = "https://images.templeswiki.com/audios/"
export const CORE_IMAGES_URL = "https://images.templeswiki.com/"

// export const AUDIO_API = "https://api.templeswiki.com/templeswiki/api/v1/temple/audio/"


// export const getImageAPI = () => {return DEV_API+'/temple/img/'};
export const getImageAPI = () => {return IMG_API};
export const getMediumImageAPI = () => {return MEDIUM_IMG_API};
export const getAudioAPI = () => {return AUDIO_API};
export const getCoreImagesAPI = () => {return CORE_IMAGES_URL};
export const getServerImageAPI = () => {return DEV_API + '/user/img/'};

export const AUTH_CODE = ":@gB20sNd#11"

class HTTPClient {
  
  getHttp() {
    return axios.create({
      baseURL: DEV_API,
      headers: {
        "Content-type": "application/json",
        'Cross-Origin-Resource-Policy': 'same-origin',
        'Access-Control-Allow-Origin': 'https://www.templeswiki.com',
        'x-api-plain-json' : 'true',
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.TEMPLE_API_KEY,
      }
    });
  }

  getAuthHttp() {
    const auth_token = localStorage.getItem('token');
    const bufferObj = Buffer.from(auth_token+AUTH_CODE, "utf8");
    const decodedString = bufferObj.toString("base64");
    return axios.create({
      baseURL: DEV_API,
      headers: {
        "Content-type": "application/json",
        "authorization": 'Basic '+decodedString,
        'Cross-Origin-Resource-Policy': 'same-origin',
        'Access-Control-Allow-Origin': 'https://www.templeswiki.com',
        'x-api-plain-json' : 'true',
        'X-API-CID': API_KEYS.getClientToken(),
      }
    });
  }

  getUserAuthHttp(uid, pwd) {
    const bufferObj = Buffer.from(uid+":"+pwd, "utf8");
    const decodedString = bufferObj.toString("base64");
    return axios.create({
      baseURL: DEV_API,
      headers: {
        "Content-type": "application/json",
        "authorization": 'Basic '+decodedString,
        'Cross-Origin-Resource-Policy': 'same-origin',
        'Access-Control-Allow-Origin': 'https://www.templeswiki.com',
      }
    });
  }

}

export default new HTTPClient();
