import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchNotifications, postClearALLNotifi, removeNotification } from '../../../../redux/actions/acions';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Card, Modal, Popconfirm, Result, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

let last_rec = 0;
const Notification = () => {
  const { t } = useTranslation();
  const records_per_page = 12;
  const [clearAll, setClearAll] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
const storedLanguage = localStorage.getItem('lng') || 5;
  const dispatch = useDispatch();
  const { nList, nLoading, nError } = useSelector(state => ({
    nList: getNotificationsList(state),
    nLoading: getNotificationstionsLoading(state),
    nError: getNotificationsError(state),
  }));

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t('label_delete_notifi_sucess'));
    // Re-fetch notifications after deletion
    dispatch(fetchNotifications(storedLanguage, last_rec));
  };

  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  const clearAllsuccess = () => {
    showToast('success', t('label_clearnotifi_success'));
    window.location.reload();
  };

  const clearAllfailure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    dispatch(fetchNotifications(storedLanguage, 0));
  }, []);

  function deleteNotification(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ""
    };

    // Dispatch an action to remove the notification via API
    dispatch(removeNotification(data, success, failure));
  }

  function ClearALLNotifi() {
    if (nList && nList.length === 0) {
      showToast('info', t('error_msg_33'));
      return;
    }
    dispatch(postClearALLNotifi(clearAllsuccess,clearAllfailure));
    setClearAll(true);
  }

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'));
  }, []);

  function isNotificationDeleted(id) {
    return deletedIds.includes(id);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);

  useEffect(() => {
    dispatch(fetchNotifications(storedLanguage, last_rec));
  }, [storedLanguage, currentPage]);

  useEffect(() => {
    if (!nLoading && nList) {
      setCurrentPageTemples([...currentPageTemples, ...nList]);
    }
  }, [nLoading]);

  function nextTemplesList() {
    setIsLoading(true);
    last_rec = last_rec + records_per_page;
    dispatch(fetchNotifications(last_rec));
  }

  const { confirm } = Modal;
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const shareResLink = (title, txt, url) => {
      if (navigator.share) {
          navigator.share({
              title: title,
              text: txt,
              url: 'https://www.templeswiki.com/share/'+url
          })
          .then(() => console.warn("Share successful."))
          .catch((error) => console.error("Error sharing:", error));
      } else {
          // Fallback for browsers that do not support the Web Share API
          console.error("Web Share API not supported.");
          window.flutter_inappwebview.callHandler('shareResLink', title, txt, url);
      }
  };

  return (
    <>
      <div className="mainsec">
        <div className="mob-nav fluid-container page-title justify-content-between">
          <div className="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack}><i className="fa-solid fa-angle-left"></i></Link>
            <span className="bc-link">{t('label_notif')}</span>
          </div>
          <div></div>
        </div>
        <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link">
          <Link style={{ color: 'black' }} to="/">
            <span className="bc-active-link">{t('Home')}</span>
          </Link> - <span className="bc-link"> {t("label_notif")}</span>
        </div>

        <div className="bg-details-highlight">
          <div className="details-headertxt-singleLine container">
            <div className="header_txt"> {t("label_notif")}</div>
            <div className="details-headertxt-singleLine-btns">
              <a type="button" className="button_move" onClick={ClearALLNotifi} style={{ fontSize: "bold" }}>{t("label_clear_all")}</a>
            </div>
          </div>
        </div>
        <div className="mainsec-block-addtemple-detailspage">
          {clearAll ? (
            <></>
          ) : (
            <div className="container">
              {nLoading ? (
                <Error />
              ) : (
                nList && nList.length > 0 ? (
                  nList
                    .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                    .map((notificationList) => {
                      const isRead = notificationList.read;
                      const notificationClass = isRead ? 'notification-read' : 'notification-unread'; // apply different class based on read status
                      return (
                        <div
                          key={notificationList.nid}
                          className="tiles-designs-events"
                        >
                          <div className="d-flex flex-space-between">
                            <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                              <div className="f1 events-block-link-title">
                                <i className="fa-solid fa-bell mr-3"></i>{' '}
                                <span className="f1">{notificationList.subject}</span>{' '}
                              </div>
                            </Link>
                            <div className="d-flex">
                              <a
                                className="detail-but mr-2"
                                onClick={() => shareResLink(
                                      "Know " + notificationList.subject,
                                      "Know " + notificationList.subject,
                                      `eventDetails/${notificationList.nid}`
                                  )}
                              >
                                <i title={t('label_share')} className="fa-solid fa-share-nodes"></i>
                              </a>
                              <Popconfirm
                                title="Are you sure you want to delete this notification?"
                                okText="Yes"
                                okType="danger"
                                cancelText="No"
                                onConfirm={() => {
                                  deleteNotification(notificationList.nid);
                                }}
                                onCancel={() => {
                                  console.warn('Deletion cancelled');
                                }}
                              >
                                <a className='detail-but'><i className="fa-solid fa-trash" style={{ color: "#c15506" }}></i></a>
                              </Popconfirm>
                            </div>
                          </div>
                          <span className="f1 events-block-link-value">{notificationList.txt}</span>
                        </div>
                      );
                    })
                ) : (
                  <Result
                    status="404"
                    title={t("label_nodata_found")}
                    subTitle={t("label_no_notify_msg")}
                  />
                )
              )}
            </div>
          )}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Notification;