import React from 'react'
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error = () => {
    const { t } = useTranslation();
    return (
        <div className="spinner-container">
        <div className="loading-spinner">
        </div>
      </div>
    )
}

export default Error;
