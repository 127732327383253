import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, message } from 'antd';
import { getContributionsError, getContributionsList, getContributionsLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getPendingTemplesList, getPendingTemplesListError, getPendingTemplesListLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTemple, fetchContributions, fetchFavourites, fetchGodNames, fetchNotifications, fetchPendingTemples, postFavourites, removeFavourites, retrieveGodNames, retrieveTemples } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import { getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import TemplesPage from '../../../common/TemplesPage';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

let last_rec = 0;
const PendingTemples = () => {
    const { t } = useTranslation();
    const localEnv = false;
    const records_per_page = 12;
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
const storedLanguage = localStorage.getItem('lng');
    const [messageApi, contextHolder] = message.useMessage();
    // const failure = (msg) => {
    //     messageApi.open({
    //         type: 'error',
    //         content: msg,
    //     });
    // };

    const success = (tname) => {
        showToast('success', t(`Activated ${tname} `))
        // messageApi.open({
        //     type: 'success',
        //     content: `Added ${tname} to favorites`,
        // });
    };
    const warning = (tname) => {
        showToast('info', t(`Removed ${tname} from favourites`))
        // messageApi.open({
        //     type: 'warning',
        //     content: `Removed ${tname} from favourites`,
        // });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };


    const { cList, cLoading, cError, fList, fLoading, fError, godCtgryList, godCtgryListLoading, getContributionsError } = useSelector(state => ({
        cList: getPendingTemplesList(state),
        cLoading: getPendingTemplesListLoading(state),
        cError: getPendingTemplesListError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),

    })
    )
    useEffect(() => {
        dispatch(fetchPendingTemples(storedLanguage, 0))
    }, [storedLanguage])


    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    useEffect(() => {
        if (getToken) {
            dispatch(fetchFavourites(storedLanguage, last_rec))
        }
    }, [getFavouriteTemples])

    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);

    // const [messageApi, contextHolder] = message.useMessage();


    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function addFavourites(id) {
        dispatch(postFavourites(id, failure))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId, failure))
    }

    const getToken = Boolean(localStorage.getItem('token'));

    const navigate = useNavigate();


    function toggleFavorite(temple) {
        const index = favList.findIndex(t => t === temple.tid);
        if (index === -1) {
            addFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            if (getToken) {
                success(temple.tname);
            }
        } else {
            deleteFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            warning(temple.tname);
        }
    }

    // useEffect(() => {
    //     dispatch(fetchFavourites(0));
    // }, [refresh]);

    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [filterId, setFilterId] = useState(0);
    function getStyle(godCategoryId) {
        if (godCategoryId === filterId) {
            return {
                color: '#4096ff',
                borderColor: '#4096ff',
            };
        } else {
            return {
                borderColor: '#d9d9d9',
                color: '#000',
            };
        }
    }

    const tid = cList && cList.length > 0 && cList[0].tid;
    const ludt = cList && cList.length > 0 && cList[0].ludt;


    function handleDelete() {
        dispatch(deleteTemple(tid, ludt, success, failure))
        message.success('Deleted successfully');
    }

    //     const nextSetRef = useRef(null);


    //   useEffect(() => {
    //       // Add a scroll event listener to the window
    //       window.addEventListener("scroll", handleScroll);
    //       return () => {
    //           // Remove the scroll event listener when the component unmounts
    //           window.removeEventListener("scroll", handleScroll);
    //       };
    //   }, []);

    //   const handleScroll = () => {
    //       const windowHeight =
    //           "innerHeight" in window
    //               ? window.innerHeight
    //               : document.documentElement.offsetHeight;
    //       const body = document.body;
    //       const html = document.documentElement;
    //       const docHeight = Math.max(
    //           body.scrollHeight,
    //           body.offsetHeight,
    //           html.clientHeight,
    //           html.scrollHeight,
    //           html.offsetHeight
    //       );
    //       const windowBottom = windowHeight + window.pageYOffset;

    //       // Detect if the user has scrolled to the bottom of the page
    //       if (windowBottom >= docHeight && !isLoading) {
    //           // Load more data when scrolling to the end
    //           nextTemplesList();
    //       }
    //   };

    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentPageTemples, setCurrentPageTemples] = useState([]);

    // useEffect(() => {
    //     dispatch(fetchContributions(storedLanguage, last_rec));
    // }, [storedLanguage, currentPage]);

    // useEffect(() => {
    //     if (!cLoading && cList) {
    //         setCurrentPageTemples([...currentPageTemples, ...cList]);
    //     }
    // }, [cLoading]);

    const nextTemplesList = async () => {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchPendingTemples(storedLanguage, last_rec));
        // setIsLoading(false);
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        dispatch(fetchPendingTemples(storedLanguage, last_rec));

        // Navigate to the previous page in the browser history
        navigate(-1); // This will take you back to the previous page
    }

    const [did, setDid] = useState(0);

    function filterTemplesList(gDid) {
        setDid(gDid);
        if (gDid === filterId) {
            setFilterId(0)
            dispatch(retrieveTemples(storedLanguage, last_rec));
            dispatch(fetchPendingTemples(storedLanguage, 0, 0))
        }
        else {
            setFilterId(gDid);
            dispatch(fetchPendingTemples(storedLanguage, gDid, 0))
        }
    }

    return (
        <>
            {contextHolder}
            <CustomToastContainer />
            <TemplesPage
                title={t("label_pending_temples")}
                description={'This is Pending Temples page'}
                tLoading={cLoading}
                tList={cList}
                favList={favList}
                toggleFavorite={toggleFavorite}
                toggleFilled={toggleFilled}
                pageTitle={'Favourites'}
                handleDelete={handleDelete}
                godCtgryList={godCtgryList}
                filterTemplesList={filterTemplesList}
                godCtgryListLoading={godCtgryListLoading}
                filterId={filterId}
                setFilterId={setFilterId}
                nextTemplesList={nextTemplesList}
                prevTemplesList={prevTemplesList}
            />

        </>


    )
}



export default PendingTemples;