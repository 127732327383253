import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Table, Popconfirm } from 'antd';
import { getTempleServiceBookingList, getTempleServiceBookingListLoading, getTempleServiceBookingListError, getDonationsListError, getDonationsListLoading, getMyServicesList, getMyServicesListError, getMyServicesListLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { fetchServiceRegList } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const TempleServiceRegistrationList = () => {
    const { t } = useTranslation();
const storedLanguage = localStorage.getItem('lng');
    const userid = localStorage.getItem('urole')
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('failure', t('label_donation_emptylist_failure'))
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleServiceBookingList(state),
        rLoading: getTempleServiceBookingListLoading(state),
        rError: getTempleServiceBookingListError(state),
    }));
    

    useEffect(() => {
        dispatch(fetchServiceRegList(0));
    }, [dispatch]);

    const modifiedData = rList && rList.map((item, index) => ({ ...item, "S.No": index + 1 }));
    const downloadAsPDF = () => {
        const doc = new jsPDF();

        const headers = columns.map(column => column.title.props.children);

        const data = modifiedData.map(item => columns.map(column => item[column.dataIndex]));

        doc.autoTable({
            head: [headers],
            body: data,
        });

        doc.save('donations.pdf');
    };

    const getDonationTypeLabel = (dontype) => {
        switch (dontype) {
            case 'M':
                return t('label_Maintainance');
            case 'H ':
                return t('label_Hundi');
            case 'A':
                return t('label_Annadanam');
            case 'D ':
                return t('label_daily_archana');
            case 'R':
                return t('label_repaire');
            case 'N':
                return t('label_new_constr');
            default:
                return t('label_notchosen');
        }
    };


    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_id")}
                </span>
            ),
            dataIndex: "sregid",
            key: "sregid",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/serviceRegDetails/${record.sregid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_quantity")}
                </span>
            ),
            dataIndex: "qty",
            key: "qty",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_service_date")}
                </span>
            ),
            dataIndex: "servdt",
            key: "servdt",
            sorter: (a, b) => a.company.localeCompare(b.company),
            render: (date) => dayjs(date).format('YYYY-MM-DD'),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_devotee")}
                </span>
            ),
            dataIndex: "devotee",
            key: "devotee",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_status")}
                </span>
            ),
            dataIndex: "status",
            key: "status",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t("label_download")}
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i class="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_servicereg_list')}</span>
                </div>
                <div></div>
            </div>
                {contextHolder}
                
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t("Home")}</span></Link> - <span className="bc-link">{t("label_servicereg_list")}</span>
                </div>
                <div class="bg-details-highlight" >
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t("label_servicereg_list")}</div> 
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container' style={{ overflowX: 'auto' }}>
                        <Table dataSource={modifiedData} columns={columns} bordered
                            style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                        />

                    </div>
                    <div className='profile-butt' style={{ marginTop: '5px' }}>


                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default TempleServiceRegistrationList;
