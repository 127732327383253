import React from 'react';
import { PageHeader, Typography } from 'antd';
import './PrivacyPolicy.css';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

const TermsConditions = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };
    return (
        <>
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_terms_conditions')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    &nbsp; <span className="bc-link">{t('label_terms_conditions')}</span>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                <div className="privacy-policy-container">

                    <div className="privacy-policy-content">
                        <Typography>
                            <Title level={2}>Terms & Conditions for www.templeswiki.com</Title>
                            <Title level={3}>Effective Date: 10-August-2024</Title>
                            <Paragraph>
                                Welcome to www.templeswiki.com. By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                            </Paragraph>

                            <Title level={3}>1. Acceptance of Terms</Title>
                            <Paragraph>
                                By using this website, you confirm that you understand and accept these terms. If you do not agree, please refrain from using our site.
                            </Paragraph>
                            <Title level={3}>2. Use of the Website</Title>
                            <Paragraph>
                                Eligibility: You must be at least 18 years old to use our website. If you are under 18, you may only use our site with the involvement of a parent or guardian.
                                License: We grant you a limited, non-exclusive, and non-transferable license to access our website and use the information provided for personal, non-commercial purposes.
                            </Paragraph>
                            <Title level={3}>3. User Accounts</Title>
                            <Paragraph>
                                You may be required to create an account to access certain features. You agree to provide accurate information and to update it as necessary.
                            </Paragraph>
                            <Paragraph>
                                You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account.
                            </Paragraph>
                            <Title level={3}>4. Content Ownership</Title>
                            <Paragraph>
                                All content on this website, including text, images, and graphics, is the property of www.templeswiki.com or its licensors and is protected by copyright and other intellectual property laws.
                            </Paragraph>
                            <Paragraph>
                                You may not reproduce, distribute, or create derivative works from any content on this site without express written permission.
                            </Paragraph>
                            <Title level={3}>5. User Contributions</Title>
                            <Paragraph>
                                If you contribute content (like temple details, comments or reviews), you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, publish, and distribute such content.
                            </Paragraph>
                            <Paragraph>
                                You are responsible for the content you submit and must ensure it does not violate any laws or rights of third parties.
                            </Paragraph>
                            <Title level={3}>6. Disclaimer of Warranties</Title>
                            <Paragraph>
                                We strive to provide accurate and up-to-date information, but we make no guarantees regarding the completeness or accuracy of the content.
                            </Paragraph>
                            <Paragraph>
                                All the data has been collected from various websites, books, authors, priests and public with the consent of originators.
                            </Paragraph>
                            <Paragraph>
                                Your use of the website is at your own risk, and we do not warrant that the site will be uninterrupted or free from errors.
                            </Paragraph>
                            <Title level={3}>7. Limitation of Liability</Title>
                            <Paragraph>
                                To the fullest extent permitted by law, www.templeswiki.com will not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the website or the content therein.
                            </Paragraph>
                            <Title level={3}>8. Links to Third-Party Websites</Title>
                            <Paragraph>
                                Our website may contain links to third-party sites. We do not endorse these sites and are not responsible for their content or practices. Accessing these sites is at your own risk.
                            </Paragraph>
                            <Title level={3}>9. Changes to Terms</Title>
                            <Paragraph>
                                We reserve the right to modify these terms at any time. Changes will be effective upon posting on the website. Your continued use after changes indicates your acceptance of the new terms.
                            </Paragraph>
                            <Title level={3}>10. Governing Law</Title>
                            <Paragraph>
                                These terms shall be governed by and construed in accordance with the laws of Telangana State, India. Any disputes arising under these terms will be subject to the exclusive jurisdiction of the courts in Hyderabad, Telangana.
                            </Paragraph>
                            <Title level={3}>11. Contact Us</Title>
                            <Paragraph>
                                For any questions or concerns regarding these Terms and Conditions, please contact us at: contact@vavili.com
                            </Paragraph>
                        </Typography>
                    </div>
                </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TermsConditions;
