
import dd from '../../assets/images/vishnu-wbg.jpg';
import sp from '../../assets/images/sp.jpg';
import jl from '../../assets/images/jl.jpg';
import vm from '../../assets/images/vm.jpg';
import cd from '../../assets/images/cd.jpg';
import am from '../../assets/images/murugan_wbg.jpg';
import ct from '../../assets/images/ct.jpg';
import ng from '../../assets/images/nava_wbg.jpg';
import pm from '../../assets/images/pm.jpg';
import nk from '../../assets/images/ln_wbg.jpg';
import ss from '../../assets/images/ss.jpg';
import at from '../../assets/images/dp.jpg';
import jn from '../../assets/images/jn.jpg';
import lm from '../../assets/images/lakshmi_wbg.jpg';
import dp from '../../assets/images/dp.jpg';
import js from '../../assets/images/js.jpg';
import nd from '../../assets/images/nd.jpg';
import tk from '../../assets/images/pb.jpg';
import cc from '../../assets/images/pb.jpg';
import pb from '../../assets/images/pb.jpg';
import uk from '../../assets/images/uk.jpg';
import usa from '../../assets/images/usa.jpg';
import oc from '../../assets/images/oc.jpg';
import baps from '../../assets/images/baps.jpg';
import iscon from '../../assets/images/iskon.jpg';
import india from '../../assets/images/india.png';

const Images = {
    "dd.jpg": dd,
    "sp.jpg": sp,
    "jl.jpg": jl,
    "vm.jpg": vm,
    "cd.jpg": cd,
    "am.jpg": am,
    "ct.jpg": ct,
    "ng.jpg": ng,
    "pm.jpg": pm,
    "nk.jpg": nk,
    "ss.jpg": ss,
    "at.jpg": at,
    "jn.jpg": jn,
    "lm.jpg": lm,
    "dp.jpg": dp,
    "js.jpg": js,
    "nd.jpg": nd,
    "tk.jpg": tk,
    "cc.jpg": cc,
    "pb.jpg": pb,
    "uk.jpg": uk,
    "usa.jpg": usa,
    "oc.jpg": oc,
    "baps.jpg": baps,
    "iskon.jpg": iscon,
    "null":india
};

export default Images;
