import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import showToast from '../../../utils/showToast';

const AccountActivation = () => {
    const { userid } = useParams(); 
    const navigate = useNavigate();

    useEffect(() => {      
        showToast('success', `Account activated for ${userid} successfully! Thank You!`);     
        const timer = setTimeout(() => {
            navigate('/');
        }, 2000);      
        return () => clearTimeout(timer);
    }, []); 

    return null;
};

export default AccountActivation;
