import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const AP_LANGUAGES = [
    {
        value: '5',
        label: 'English',
        lang_code: 'en',
        short_label: 'Eng',
        label2: 'Eng',
        link: <Link to={'/english'}>English</Link>,
        key: '1',
    },
    {
        value: '4',
        label: 'हिंदी',
        lang_code: 'hi',
        short_label: 'हिंदी',
        label2: 'हिं',
        link: <Link to={'/hindi'}>हिंदी</Link>,
        key: '2',
    },
    {
        value: '1',
        label: 'తెలుగు',
        lang_code: 'te',
        short_label: 'తెలుగు',
        label2: 'తె',
        link: <Link to={'/telugu'}>తెలుగు</Link>,
        key: '3',
    },
    {
        value: '2',
        label: 'ಕನ್ನಡ',
        lang_code: 'kn',
        short_label: 'ಕನ್ನಡ',
        label2: 'ಕ',
        link: <Link to={'/kannada'}>ಕನ್ನಡ</Link>,
        key: '4',
    },
    {
        value: '3',
        label: 'தமிழ்',
        lang_code: 'ta',
        short_label: 'தமிழ்',
        label2: 'த',
        link: <Link to={'/tamil'}>தமிழ்</Link>,
        key: '5',
    },
    {
        value: '7',
        label: 'বাংলা',
        lang_code: 'bn',
        short_label: 'বাংলা',
        label2: 'বা',
        link: <Link to={'/bangla'}>বাংলা</Link>,
        key: '6',
    },
    {
        value: '6',
        label: 'Española',
        short_label: 'Esp',
        label2: 'Esp',
        link: <Link to={'/spanish'}>Española</Link>,
        key: '7',
    }
  ];
  
  export default AP_LANGUAGES;
  