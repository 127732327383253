import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import statesData from '../../states.json';
import india from '../../../../assets/images/india.png';
import showToast from '../../../../utils/showToast';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import { Col, Row } from 'antd';
import Search from 'antd/es/transfer/search';

const CityPage = () => {
    const { state } = useParams();
    const { t } = useTranslation();
    const normalizedState = state.trim().toLowerCase();
    const { gid } = useParams();
    const states = statesData.states.find((s) => s.name.toLowerCase() === normalizedState);
    const cities = states ? states.cities : []; // Original cities list
    const [filteredCities, setFilteredCities] = useState(cities); // Initialize with all cities
    const [searchTerm, setSearchTerm] = useState('');

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', 'Please Login to use additional features');
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (value.trim() === '') {
            // If search term is empty, show all cities
            setFilteredCities(cities);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = cities.filter((city) =>
                city.toLowerCase().includes(lowercasedValue)
            );
            setFilteredCities(filtered);
        }
    };

    const handleReset = () => {
        setSearchTerm('');
        setFilteredCities(cities); // Reset to original list of cities
    };

    const handleStateClick = (state) => {
        if (gid === "507") {
            navigate(`/cityPage/${state}`);
        } else {
            navigate(`/state/${state}/city/all`);
        }
    };

    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{`${t('label_cities')} ${t(state)}`}</span>
                </div>
                <div></div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link style={{ color: 'black' }} to='/templeCtgry'><span class="bc-active-link">{t("label_temples_list")}</span></Link> - <span class="bc-link">{`${t('label_cities')} ${t(state)}`}</span></div>
            <div class="mainsec-block">
                <div class="f2 font-weight-bold  container title-head-txt" style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }} >
                    {token ? (
                        <Link to='/addPage'><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                    <Link to='/recentlyViewed'><button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    <Link to='/nearby'> <button type="button" class="declineButton ">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                </div>
                <div className="container">
                <div class="container">
                        <div class="card-text-title d-flex justify-content-between">
                            <div class="d-flex align-items-center">
                            {`${t('label_cities')} ${t(state)}`}
                            </div>

                            <div class="sec-cat">
                            <Search
                                placeholder="Search Cities"
                                value={searchTerm}
                                onSearch={handleSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ marginRight: '10px', width: '30%' }}
                            />
                            {/* <button className="button_move" onClick={handleReset}>
                                Reset
                            </button> */}
                            </div>
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </div>
                    </div>

                    <section className="container h-100 d-flex flex-column">
                        <div className="search-rt-sec1 tile-design-city">
                            <Row>
                                <Col className="youtube-video">
                                    <div className="tiles-design animate__animated animate__flipInX">
                                        <div>
                                            <div className="card-body p-0">
                                                <Link to={`/state/${state}/city/all`}>
                                                    <div className="p-2">
                                                        <h6 className="home-temple-text" title="All Cities">
                                                            {t('label_all_cities')}
                                                        </h6>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {filteredCities.map((city, index) => {
                                    const cleanedCity = city.trim().replace(/[{}]/g, '');
                                    const cityURL = encodeURIComponent(cleanedCity);
                                    return (
                                        <Col className="youtube-video" key={index}>
                                            <div className="tiles-design animate__animated animate__flipInX">
                                                <div>
                                                    <div className="card-body p-0">
                                                        <Link to={`/state/${state}/city/${cityURL}`}>
                                                            <div className="p-2">
                                                                <h6 className="home-temple-text" title={city}>
                                                                    {city}
                                                                </h6>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default CityPage;
