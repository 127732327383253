import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, Table, message } from 'antd';
import { getTempleServiceRegistrationList, getTempleServiceRegistrationListError, getTempleServiceRegistrationListLoading, getTempleServiceRegistratiorListLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchNotificationDetails, fetchRegionalNotifi, fetchServicesDetails, fetchTempleServiceRegistration, updateNotificationDetails } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../../../http-common";
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Option } from 'antd/es/mentions';
import cholaTemples from '../../../../assets/images/darshan.jpg'
import Footer from '../footer/footer';

const TempleServiceRegistration = () => {
    const { t } = useTranslation();
const storedLanguage = localStorage.getItem('lng');
    const { servid } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const failure = (msg) => {
        messageApi.error(msg);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleServiceRegistrationList(state),
        rLoading: getTempleServiceRegistrationListLoading(state),
        rError: getTempleServiceRegistrationListError(state),
    }));

    useEffect(() => {
        dispatch(fetchTempleServiceRegistration(0));
    }, [storedLanguage, dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const token = localStorage.getItem('token');

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('Please Login to use additional features');
    };

    const modifiedData = Array.isArray(rList) ? rList.map((item, index) => ({
        ...item,
        "S.No": index + 1
    })) : [];


    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Service Registration ID
                </span>
            ),
            dataIndex: "sregid",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Devotee Name
                </span>
            ),
            dataIndex: "devotee",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/serviceRegDetails/${record.sregid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Phone
                </span>
            ),
            dataIndex: "phone",
            key: "ctgry",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Quantity
                </span>
            ),
            dataIndex: "qty",
            key: "sponsor",
            sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Status
                </span>
            ),
            dataIndex: "status",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        // {
        //     title: 'Actions',
        //     dataIndex: '{t("label_cancel")}',
        //     key: 'delete',
        //     render: (text, record) => (
        //       <Popconfirm
        //         title="Are you sure you want to {t("label_cancel")}?"
        //         onConfirm={() => deleteService(record.sregid)}
        //         okText="Yes"
        //         cancelText="No"
        //       >
        //         <Link>{t("label_cancel")}</Link>
        //       </Popconfirm>
        //     ),
        //   },
    ];

    return (
        <div className='main'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_service_details')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <Link style={{ color: 'black' }} onClick={handleGoBack}><span className="bc-active-link" >{t('label_services')}</span></Link> - <span className="bc-link">{t("label_service_details")}</span>
            </div>
            {/* <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"> Service Registration</div>
                   

                </div>
            </div> */}
            <div className="container">

                {/* <div class="container"><h5 class="card-text-title">{t("label_service_registration")} <span>| {t("label_services_like_msg")}</span></h5>
                </div> */}
                <div className='container'>
                    <Table dataSource={modifiedData} columns={columns} bordered
                        style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                    />

                </div>
            </div>
            <Footer />
        </div >

    );
};

export default TempleServiceRegistration;
