import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading} from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchGodNames, fetchResourceByTxt, fetchTempleAudio, postBooksFavourites, removeBooksFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import CommonResource from './commonResource';
import showToast from '../../../../utils/showToast';

function Audio() {
    const { t } = useTranslation();
    const records_per_page = 12;
    const [isLoading, setIsLoading] = useState(false);
const storedLanguage = localStorage.getItem('lng');
    const [did, setDid] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const localEnv = false;
    const [filterId, setFilterId] = useState(0);
    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();
    const [audios] = useState('audios');


    const { bDetails } = useSelector(state => ({
        bDetails: getBooksList(state),
        bLoading: getBooksLoading(state),
        bError: getBooksError(state),
        // bDetails: getTempleAudioList(state),
        // bLoading: getTempleAudioLoading(state),
        // bError: getTempleAudioError(state),
        godCtgryList: getGodNames(state) || [],
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    const audioUrl = useSelector(state => state.src_url);
    
    useEffect(() => {
        if (selectedItem === null) {
          dispatch(fetchBooks(audios, storedLanguage, did, 0))
        }
        else if (selectedItem != null) {
          dispatch(fetchBooksByTag(audios, storedLanguage, filterId, selectedItem, 0));
        }
      }, [storedLanguage, did])
      
    useEffect(() => {
        if (selectedItem === null) {
            const audioPath = 'telugubhakti';
            const audioFilename = 'Namo_narayanaya_namo.mp3';
            dispatch(fetchTempleAudio(audioPath, audioFilename));
        } else if (selectedItem !== null) {
            dispatch(fetchBooksByTag('audios', storedLanguage, filterId, selectedItem, 0));
        }
    }, [storedLanguage, did, selectedItem, dispatch]);
    

    const { tDetails } = useSelector(state => ({
        tDetails: getBooksByTagList(state),
        tLoading: getBooksByTagLoading(state),
        tError: getBooksByTagError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])



    function filterTemplesList(gDid) {
        if (gDid === filterId) {
            setDid(0);
            setFilterId(0);
            // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
        } else {
            setDid(gDid);
            setFilterId(gDid);
            // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
        }
    }



    const [currentPage, setCurrentPage] = useState(0);
    const mainSecBlockRef = useRef(null);
    const nextTemplesList = async () => {
      setIsLoading(true);
      setCurrentPage(prevPage => {
        const newPage = prevPage + 1;
  
        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page;
          dispatch(fetchBooks(audios, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * records_per_page;
          dispatch(fetchBooksByTag(audios, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * records_per_page;
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
            mainSecBlockRef.current.scrollTop = 0; 
          }
        return newPage;
      });
      setIsLoading(false);
    };
  
    // Previous button handler
    const prevTemplesList = () => {
      setIsLoading(true);
      setCurrentPage(prevPage => {
        if (prevPage > 0) { // Ensure we don't go below the first page
          const newPage = prevPage - 1; // Decrement page
  
          if (filterId !== null && selectedItem === null) {
            const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
            dispatch(fetchBooks(audios, storedLanguage, did, newLastRec));
          }
          else if (selectedItem !== null) {
            const newSelectedLastRec = newPage * records_per_page; // Previous offset for selected items
            dispatch(fetchBooksByTag(audios, storedLanguage, filterId, selectedItem, newSelectedLastRec));
          }
          else if (searchText !== null) {
            const newSearchLastRec = newPage * records_per_page; // Previous offset for search audios
            dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
          }
          if (mainSecBlockRef.current) {
            mainSecBlockRef.current.scrollTop = 0; 
          }
          return newPage; // Return updated page number
        }
  
        return prevPage; // If we're on the first page, don't decrement
      });
      setIsLoading(false);
    };
  
    const [refresh] = useState(false);

    const success = (tname) => {
        showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
    };

    const warning = (tname) => {
        showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
    };

    const failure = (msg) => {
        showToast('error', t('label_loginerror'))
    };

    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [filledTemples, setFilledTemples] = useState({});

    const { fList } = useSelector(state => ({
        fList: getBooksFavouritesList(state),
        fLoading: getBooksFavouritesLoading(state),
        fError: getBooksFavouritesError(state),
    }));

    useEffect(() => {
        dispatch(fetchBooksFavourites());
    }, [getFavouriteTemples, refresh]);

    const [favList, setFavList] = useState([]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
        }
    }, [fList]);



    function addFavourites(rid) {
        dispatch(postBooksFavourites(rid, failure))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeBooksFavourites(deleteId, failure));
    }

    // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(temple) {
        if (getToken) {
            const templeId = temple.rid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.title);
                warning(temple.title);
                // Update favList by removing the temple ID
                setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.title);
                success(temple.title);
                // Update favList by adding the temple ID
                setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }

    const { sList } = useSelector(state => ({
        sList: getResourceByTxtList(state),
        sLoading: getResourceByTxtLoading(state),
        sError: getResourceByTxtError(state),
    }));

    useEffect(() => {
        dispatch(fetchResourceByTxt(audios, storedLanguage, searchText, 0));
    }, [searchText]);

    const [searchText, setSearchText] = useState('');

    const [isGodModalVisible, setIsGodModalVisible] = useState(false);

    return (
        <>
            {contextHolder}
            
            <CommonResource
                title={t("label_audios")}
                description={t('label_about_audio')}
                bDetails={bDetails}
                sList={sList}
                tDetails={tDetails}
                nextTemplesList={nextTemplesList}
                prevTemplesList={prevTemplesList}
                fetchResource={fetchBooks}
                text={audios}
                filterTemplesList={filterTemplesList}
                src={audioUrl}
                currentPage={currentPage}
                mainSecBlockRef={mainSecBlockRef}
            />

        </>
    )
}


export default Audio;