import { Form, Input, Typography, message, Image, Col, Row, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGodNames, postTemples, postUploadImageRequest, retrieveGodNames } from '../../../../redux/actions/acions';
import Cropper from 'cropperjs';
import { useTranslation } from 'react-i18next';
import aum from '../../../../assets/icons/om.jpg'
import statesData from '../../states.json';
import { GoogleMap, Marker } from '@react-google-maps/api';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import { getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import deityImages from '../../../common/godImages';
import AP_LANGUAGES from "../../../common/appLangs"

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
};

const AddPage1 = () => {
  const [showMap, setShowMap] = useState(false);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);
  const [mapKey, setMapKey] = useState(1);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [error, setError] = useState(false);
  const [currentLocationClicked, setCurrentLocationClicked] = useState(false);
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [selectedDeity, setSelectedDeity] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const localEnv = false;
  const [markerVisible, setMarkerVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [newImagePath, setNewImagePath] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const { godCtgryList } = useSelector(state => ({
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const tid = localStorage.getItem('templeid')

  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t('label_add_temple_sucess'))
  };

  const failure = (msg) => {
    showToast('error', msg)
  };

  const handleCameraClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        dispatch(postUploadImageRequest(tid, formData, () => {
          const uploadedImagePath = URL.createObjectURL(selectedFile);
          setNewImagePath(uploadedImagePath);
          console.warn('Image uploaded successfully');
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
        }));
      } else {
        console.warn('No file selected');
      }

      document.body.removeChild(fileInput); // Clean up the file input
    });

    document.body.appendChild(fileInput);
    fileInput.click();
  };

  const [cropper, setCropper] = useState(null);
  const imageRef = useRef();

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const handleGalleryClick = (event) => {
    event.preventDefault();
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result); // Show image in modal
          setIsModalVisible(true); // Open modal
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    fileInput.click();
  };

  const handleImageCloseModal = () => {
    setIsModalVisible(false);
    if (cropper) {
      cropper.destroy(); // Destroy cropper instance when modal is closed
    }
  };

  const onImgDelete = () => {
    setNewImagePath(null); // Remove the image path
    // Add any other functionality you need here
  };

  const handleCropAndUpload = (event) => {
    event.preventDefault();
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob);

        // Replace the following line with your image upload logic
        dispatch(postUploadImageRequest(0, formData, () => {
          const uploadedImagePath = URL.createObjectURL(blob);
          setNewImagePath(uploadedImagePath);
          handleImageCloseModal(); // Close modal after uploading
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
        }));
      });
    }
  };

  useEffect(() => {
    if (isModalVisible && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
        viewMode: 1,
        autoCropArea: 1, // Ensure the crop area covers the whole image
        responsive: true, // Ensure responsiveness
        scalable: false,
        zoomable: true,
        cropBoxResizable: true,
        minCropBoxHeight: 200, // Min height as per your style
        maxCropBoxHeight: 200, // Max height as per your style
        minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
        maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
      });
      setCropper(cropperInstance);
    }

    return () => {
      if (cropper) {
        cropper.destroy(); // Cleanup when the modal is closed or component unmounts
      }
    };
  }, [isModalVisible]);

const storedLanguage = localStorage.getItem('lng') || 5;

  const onFinish = () => {
    form.validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        const data = {
          tname: values.tname,
          deityname: values.deityname,
          avatar: selectedDeity !== null ? selectedDeity : values.maindeity,
          imgpath: localStorage.getItem('img'),
          addr1: values.addr1,
          addr2: values.addr2,
          history: values.history || '',
          descr: values.descr || '',
          city: values.city,
          st: values.st,
          pincode: values.pincode,
          country: "india",
          website: values.website,
          latit: parseFloat(values.lat),
          longi: parseFloat(values.lon),
          lang: values.lang,
          otherdeities: values.otherdeities,
        };

        if (imageFile) {
          // Upload image first if imageFile is present
          uploadImageWithTid(0, imageFile, (response) => {
            console.log('Upload response received:', response); // Log the entire response

            // Dispatch the postTemples action with the updated data
            dispatch(postTemples(data, success(), failure()))
              .then((tid) => {
                console.debug('Form submission successful, tid:', tid);
              })
              .catch((error) => {
                console.error('Form submission failed:', error);
                // Do not reset the form on failure
              });
          });
        } else {
          // If no image file is selected, directly submit the form
          dispatch(postTemples(data, success()))
            .then((tid) => {
              console.debug('Form submission successful, tid:', tid);
              // Only reset the form if the submission is successful
              form.resetFields();
            })
            .catch((error) => {
              console.error('Form submission failed:', error);
              // Do not reset the form on failure
            });
        }
      })
      .catch((errorInfo) => {
        console.error('Validation failed:', errorInfo);
      });
  };

  const uploadImageWithTid = (tid = 0, file, onSuccess) => {
    const formData = new FormData();
    formData.append('file', file);

    dispatch(
      postUploadImageRequest(
        tid,
        formData,
        (response) => {
          console.log('Raw response from upload API:', response); // Log the raw response
          if (onSuccess) onSuccess(response); // Pass the raw response to onSuccess callback
        },
        (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
          if (onSuccess) onSuccess(null); // Pass null on error
        }
      )
    );
  };

  useEffect(() => {
    if (imageFile) {
      uploadImageWithTid(0, imageFile); // Use 0 as the temporary tid for the upload
    }
  }, [imageFile]);

  const pincodeRegex = /^[0-9]{6}$/;

  const validatePincode = (_, value) => {
    if (value && !pincodeRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid pincode! It must be exactly 6 digits.'));
    }
    return Promise.resolve();
  };

  const [showAllImages, setShowAllImages] = useState(false);

  const handleImageClick = () => {
    setShowAllImages(true);
  };

  const handleDeityImageClick = (deityNumber) => {
    setSelectedDeity(deityNumber);
    form.setFieldsValue({ avatar: deityNumber }); // Update the form field with the selected deity
    handleCloseModal(); // Close the modal after selecting the deity
  };

  const handleCloseModal = () => {
    setShowAllImages(false);
  };

  const handleStateChange = (value) => {
    // Update the selected state and list of cities
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities : []);
  };

  useEffect(() => {
    if (selectedState) {
      form.setFieldsValue({ city: selectedState.cities[0] });
    }
  }, [selectedState]);

  const handleLocationButtonClick = () => {
    setShowMap(!showMap);
    setMarkerVisible(true);
    setLocationSelected(false);
  };

  const handleMarkerDragEnd = (e) => {
    setCurrentLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    // Update latitude and longitude state variables
    setLat(e.latLng.lat().toFixed(6));
    setLon(e.latLng.lng().toFixed(6));
  };

  const handleGetCurrentLocation = (event) => {
    event.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCurrentLocation({ lat: latitude, lng: longitude });
          setCurrentLocationClicked(true);
          setError(false);
          setLat(latitude.toFixed(6));
          setLon(longitude.toFixed(6));
          setShowMap(true);
          setMarkerVisible(true);
          setMapKey(prevKey => prevKey + 1); // Force map re-render
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  };

  const handleGetLatLonFromMap = () => {
    if (currentLocation) {
      setLat(currentLocation.lat.toFixed(6));
      setLon(currentLocation.lng.toFixed(6));
      form.setFieldsValue({
        lat: currentLocation.lat.toFixed(6),
        lon: currentLocation.lng.toFixed(6),
      });

      // Close map after selection
      setShowMap(false);
    }
    if (!currentLocationClicked) {
      setError(true); // Show the error if 'Use Current Location' wasn't clicked first
    } else {
      setError(false); // No error if 'Use Current Location' was already clicked
      // Logic for using the selected location from the map
    }
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const indiaCenter = { lat: 20.5937, lng: 78.9629 };

  const customButtonStyle = {
    marginBottom: '10px',
    flex: '1',
  };

  const handleCancelClick = () => {
    // Reset the form to its initial values
    form.resetFields();
    navigate(-1);
    // setEditable(false);
  };

  return (
    <>
      {contextHolder}

      <div class='mainsec'>
        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span class="bc-link">{t('add_temple')}</span>
          </div>
          <div></div>
        </div>
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
          <Link style={{ color: 'black' }} to='/'>
            <span className="bc-active-link">{t('Home')}</span>
          </Link> -&nbsp;<span className="bc-active-link" onClick={handleGoBack}>{t('label_temples_list')}</span> -&nbsp;<span className="bc-link">{t("add_temple")}</span>
        </div>
        <div class="bg-details-highlight">
          <div class=" details-headertxt-singleLine container">
            <div class="header_txt"> {t('label_add_new_tmpl')}</div>
            <div class="details-headertxt-singleLine-btns">
              <a class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                {t('label_cancel')}
              </a>
              <a class="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                {t('label_save')}
              </a>
            </div>

          </div>
        </div>
        <div class="mainsec-block-addtemple-detailspage">
          <div className='container'>
            <Form form={form} name="nest-messages" onFinish={onFinish}
              initialValues={{
                lat: lat || undefined,
                lon: lon || undefined,
              }}
            >
              <div class="float-left w-100" ><h5 class="card-text-title">{t("label_enter_tmpl_det")}</h5></div>
              <div class="active-details">
                <div class="tiles-designs" >
                  <Form.Item
                    name="tname"
                    label={<Typography.Title level={4}>{t('label_temple_name')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_required_field'),
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_temple_name')} />
                  </Form.Item>
                </div>

                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                    rules={[{ required: true, message: t('error_msg_avatar'), }]}
                    name='avatar'
                  >
                    <>
                      <img
                        src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                        alt="Selected Deity Image"
                        onClick={handleImageClick}
                        class="add-temple-img"
                      />
                      <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                        <div class="popup-main-filter animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_god_categ')}</span>
                            <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <div className=" god-category-list">
                                <div className="all-gods-sec">{t('label_all')}</div>
                                <div className="god_select">
                                  <div className="god_pic">
                                    <img
                                      className="dietyImg"
                                      src={aum}
                                      alt="All"
                                      onClick={() => handleDeityImageClick(null)}
                                    />
                                  </div>
                                </div>
                                <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                <Row gutter={[16, 16]}>
                                  {godCtgryList && godCtgryList.map((godCtgry) => (
                                    <Col key={godCtgry.avatar}>
                                      <div className="god_select">
                                        <div className="god_pic">
                                          <img
                                            src={deityImages[godCtgry.avatar]}
                                            alt="Deity Image"
                                            style={{
                                              width: '80px',
                                              height: '80px',
                                              margin: '5px',
                                              cursor: 'pointer',
                                              border: '1px solid #ddd'
                                            }}
                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                          />
                                        </div>
                                        <p>{godCtgry.deity}</p>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>
                          </div>
                        </div>
                      </div>
                    </>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="deityname"
                    label={<Typography.Title level={4}>{t('label_main_deity')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_msg_main_diety')
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_main_deity')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="otherdeities"
                    label={<Typography.Title level={4}>{t('label_other_deities')}</Typography.Title>}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_other_deities')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="descr"
                    label={<Typography.Title level={4}>{t('label_descr')}</Typography.Title>}
                  >
                    <TextArea rows={4} placeholder={t('label_enter_details')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="history"
                    label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                  >
                    <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('label_enter_history')} />
                  </Form.Item>
                </div>
              </div>
              <div class="float-left w-100" ><h5 class="card-text-title">{t('label_addr_loc_dtl')}</h5></div>
              <div class="active-details">
                <div class="tiles-designs" >
                  <Form.Item
                    name="addr1"
                    label={<Typography.Title level={4}>{t('label_village_area')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_msg_addrs')
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_vill_loc')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="addr2"
                    label={<Typography.Title level={4}>{t('label_addr')}</Typography.Title>}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_address')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('label_state')}</Typography.Title>}
                    name="st"
                    rules={[
                      { required: true, message: t('error_msg_st') },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={t('label_select_state')}
                      onChange={handleStateChange}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {states.map((state) => (
                        <Select.Option key={state} value={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    label={<Typography.Title level={4}>{t("label_city")}</Typography.Title>}
                    name="city"
                    rules={[
                      { required: true, message: t('error_msg_city') },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={t('label_select_city')}
                      value={selectedState ? undefined : ''}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map((city) => (
                        <Select.Option key={city} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="tiles-designs">
                  <Form.Item
                    name="pincode"
                    label={<Typography.Title level={4}>{t('label_pin')}</Typography.Title>}
                    rules={[
                      {
                        validator: validatePincode,
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      maxLength={6}
                      style={{ height: '30px' }}
                      placeholder={t('label_enter_pincode')}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="website"
                    label={<Typography.Title level={4}>{t('label_website')}</Typography.Title>}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_website')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="lang"
                    label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_msg_lng'),
                      },
                    ]}
                  >
                    <Select options={[{ value: '', label: t('label_select_language') }, ...AP_LANGUAGES]}
                      placeholder={t('label_select_language')}
                      style={{ width: "30%" }}
                    />
                  </Form.Item>
                </div>
                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_map')}
                </h5></div>
                {/* <div class="center-align"> */}
                <div className="tiles-designs">
                  <button type="button" class="button_move mb-2" style={{marginLeft:'5px'}} onClick={handleLocationButtonClick}>{t('label_show_map')}
                </button>
                {showMap && (
                  <>
                    <div class="popup-bg" style={{ display: showMap ? 'flex' : 'none' }}>
                      <div class="popup-main animate__animated animate__fadeInUp ">
                        <div class="popup-main-header">
                          <span class="popup-main-header-text">{t('label_sel_loc_on_map')}</span>
                          <div class="popup-close" onClick={() => setShowMap(false)}><i class="fa-solid fa-xmark"></i></div>
                          <div class="popup-back" onClick={() => setShowMap(false)}><i class="fa-solid fa-chevron-left"></i></div>
                        </div>
                        <div class="popup-main-container1">
                          <div class="d-block htinner-popup-main-container">
                            <GoogleMap
                              center={currentLocation || indiaCenter}
                              zoom={currentLocation ? 14 : 5}
                              mapContainerStyle={{ width: '100%', height: '64vh' }}
                            >
                              {currentLocation && markerVisible && (
                                <Marker
                                  position={currentLocation}
                                  draggable={true}
                                  onDragEnd={handleMarkerDragEnd}
                                />
                              )}
                            </GoogleMap>
                          </div>
                        </div>
                        <div className="popup-container">
                          <div className="popup-main-footer1">
                            <div className="location-button">
                              <button
                                key="button"
                                className="button_move1"
                                type="primary"
                                onClick={handleGetCurrentLocation}
                                style={customButtonStyle}
                              >
                                {t("label_use_curr_loc")}
                              </button>
                              <button
                                key="submit"
                                className="button_move1"
                                type="primary"
                                onClick={handleGetLatLonFromMap}
                                style={customButtonStyle}
                              >
                                {t("label_use_this_loc")}
                              </button>
                            </div>
                            <div className="location-button1">
                              <button
                                key="back"
                                className="button_move1"
                                type="primary"
                                onClick={() => setShowMap(false)}
                                style={customButtonStyle}
                              >
                                {t("label_cancel")}
                              </button>
                            </div>
                          </div>
                        </div>
                        {error && (
                          <div className="popup-error" style={{ color: "red", marginTop: "10px" }}>
                            {t("label_location_error")}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div class="tiles-designs" >
                  <Form.Item name="lat" label={<Typography.Title level={4}>{t('label_latit')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_msg_map'),
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_select_lat')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item name="lon" label={<Typography.Title level={4}>{t('label_longi')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: t('error_msg_map'),
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_select_lon')} />
                  </Form.Item>
                </div>
              </div>
              </div>
              <div class="float-left w-100" ><h5 class="card-text-title">{t('label_add_photos')}</h5></div>
              <div className="active-details">
                <div className="tiles-designs">
                  <div text="Add Image" >
                    {previewImage ? (
                      <img src={previewImage} alt="Captured Image" />
                    ) : (
                      <>
                        {isMobile && (
                          <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                            {t('label_camera')}
                          </button>
                        )}
                      </>
                    )}
                    <button className="button_move" onClick={handleGalleryClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>
                      {t('label_upload')}
                    </button>
                  </div>
                  <div className="detail-container detail-container-image">
                    {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                    {newImagePath && (
                      <CloseCircleOutlined
                        className="close-icon"
                        onClick={onImgDelete}
                      />
                    )}
                  </div>
                  <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                    <div class="popup-main-filter animate__animated animate__fadeInUp ">
                      <div class="popup-main-header">
                        <span class="popup-main-header-text">Main header</span>
                        <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                      </div>
                      <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                          {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                        </div>
                      </div>
                      <div class="popup-main-footer">
                        <button class="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default AddPage1;