const godNames = {
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
    17: 'Naga',
    18: 'Gurudev',
    19: 'Balaji'
};

export default godNames;