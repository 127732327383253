import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Form, Input, Button, Space, DatePicker, Result, Calendar } from 'antd';
import { getDailyHoroscopeDetailsList, getDailyHoroscopeDetailsLoading, getHoroDetailsByDtError, getHoroDetailsByDtList, getHoroDetailsByDtLoading } from '../../../../redux/selectors/selectors';
import { editHoroDetails, fetchDailyHoroscopeDetails, fetchHoroDetailsByDate, updateHoroTitle, updateHoroTxt } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import moment from 'moment';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';
import dayjs from 'dayjs';
import Error from '../error';
import bordertop from '../../../../assets/images/top-border.png';
import lineborder from '../../../../assets/images/lineborder.png';


const TodayPanchangam = () => {
    const { t } = useTranslation();
const storedLanguage = localStorage.getItem('lng');
    const { hid } = useParams();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [title, setTitle] = useState(null);
    const [txt, setTxt] = useState(null);
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedDateFull, setSelectedDateFull] = useState(dayjs());
    const userid = localStorage.getItem('urole');
    const [isDateChanged, setIsDateChanged] = useState(false);

    const shareResLink = (title, txt, url) => {
        if (navigator.share) {
            navigator.share({
                title: title,
                text: txt,
                url: 'https://www.templeswiki.com/share/'+url
            })
            .then(() => console.warn("Share successful."))
            .catch((error) => console.error("Error sharing:", error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error("Web Share API not supported.");
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, url);
        }
    };

    const { horoDet, horLoading, horoError } = useSelector((state) => ({
        horoDet: getHoroDetailsByDtList(state),
        horLoading: getHoroDetailsByDtLoading(state),
        horoError: getHoroDetailsByDtError(state),
    }));

    const fetchHoroscopeForDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchHoroDetailsByDate(1, storedLanguage, formattedDate, failure));
    };

    useEffect(() => {
        const selectedFullDate = selectedDateFull ? selectedDateFull.format('YYYY-MM-DD') : selectedDate;
        fetchHoroscopeForDate(selectedFullDate);
    }, [storedLanguage, dispatch, selectedDate, selectedDateFull]);

    useEffect(() => {
        setSelectedDate(new Date().toISOString().split('T')[0]);
    }, []);

    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const onChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        setSelectedDateFull(dayjs(date));
        setIsDateChanged(true);
        fetchHoroscopeForDate(date);
    };


    const onCalenderChange = (date) => {
        if (date) {
            const formattedDate = date.format('YYYY-MM-DD');
            setSelectedDateFull(date);
            setSelectedDate(formattedDate);
            fetchHoroscopeForDate(formattedDate);
        }
    };

    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getDailyHoroscopeDetailsList(state),
        hLoading: getDailyHoroscopeDetailsLoading(state),
    }));

    useEffect(() => {
        if(hid){
        dispatch(fetchDailyHoroscopeDetails(hid, storedLanguage));
        }
    }, [storedLanguage, dispatch]);

    useEffect(() => {
        if (hDetails && hDetails.publish_date) {
            // Assuming `hDetails.publish_date` is in YYYY-MM-DD format, convert it to Day.js
            const formattedDate = dayjs(hDetails.publish_date);
            setSelectedDate(hDetails.publish_date); // This is a string, used for input field
            setSelectedDateFull(formattedDate); // This is a Day.js object, used for Calendar
        }
    }, [hDetails]);

    const success = () => {
        showToast('success', t('label_horoedit_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', msg);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setTitle(hDetails && hDetails.title);
            setTxt(hDetails && hDetails.txt);
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            year_info: values.year_info || hDetails.year_info,
            maasam: values.maasam || hDetails.maasam,
            vaaram: values.vaaram || hDetails.vaaram,
            ruthuvu: values.ruthuvu || hDetails.ruthuvu,
            nakshatra: values.nakshatra || hDetails.nakshatra,
            chandrarasi: values.chandrarasi || hDetails.chandrarasi,
            karanam: values.karanam || hDetails.karanam,
            publish_date: values.publish_date || hDetails.publish_date,
            shubhakal: values.shubhakal || hDetails.shubhakal,
            sunrise: values.sunrise || hDetails.sunrise,
            sunset: values.sunset || hDetails.sunset,
            suryarasi: values.suryarasi || hDetails.suryarasi,
            tithi: values.tithi || hDetails.tithi,
            yg_time: values.yg_time || hDetails.yg_time,
            rh_time: values.rh_time || hDetails.rh_time,
            varjyam: values.vaaram || hDetails.vaaram,
            amruthkal: values.amruthkal || hDetails.amruthkal,
            durmuhurth: values.durmuhurth || hDetails.durmuhurth,
            yoga: values.yoga || hDetails.yoga,
        };
        dispatch(editHoroDetails(hid, storedLanguage, dataToSend, success, failure));
    };

    const updateTitle = () => {
        dispatch(updateHoroTitle(hid, storedLanguage, { title: title }, success()));
    };

    const updateTxt = () => {
        dispatch(updateHoroTxt(hid, storedLanguage, { txt: txt }, success()));
    };

    const dataToDisplay = isDateChanged ? horoDet : hDetails;

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('User does not have permission !');
    };

    function formatBoldText(txt) {
        if (txt) {
            return <div dangerouslySetInnerHTML={
                { __html: formatPlainText(txt) }
            } />
        } else {
            return ''
        }
    }

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = ''
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                if (allLines[i].includes('<B>')) {
                    finalTxt = finalTxt + (allLines[i] + '</B>') + '\n';
                } else {
                    finalTxt = finalTxt + allLines[i] + '\n';
                }
            }
            return finalTxt;
        } else {
            return ''
        }
    }

    const handlePrevious = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        setSelectedDateFull(dayjs(newDate)); // Set the full date for calendar
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    const handleNext = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        setSelectedDateFull(dayjs(newDate)); // Set the full date for calendar
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = ''
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                if (allLines[i].includes('<B>')) {
                    finalTxt = finalTxt + (allLines[i] + '</B>') + '\n';
                } else {
                    finalTxt = finalTxt + allLines[i] + '\n';
                }
            }
            return finalTxt;
        } else {
            return ''
        }
    }

    const dayinfoRef = useRef(null);
    const dayspecialRef = useRef(null);
    const sunmoonRef = useRef(null);
    const goodtimeRef = useRef(null);
    const badtimeRef = useRef(null);

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_daily_panchamgam_det')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                {/* <span className="breadcrumb-link"> */}
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}> {t('label_panchangam')}</span> - <span className="bc-link">{t("label_daily_panchamgam_det")}</span>
            </div>
            <div className="bg-details-highlight">
                <div className="details-headertxt-singleLine container">
                    <div className="header_txt">
                        {/* <LeftOutlined onClick={handlePrevious} /> */}

                        {dataToDisplay && dataToDisplay.title}
                        {/* <RightOutlined onClick={handleNext} /> */}
                    </div>
                    <div className="details-headertxt-singleLine-btns">

                        <button style={{ fontWeight: "bold" }} className="button_move mr-2" onClick={handlePrevious}>

                            <span class="web-display">{t('label_previous')}</span>
                            <i class="mob-display fa-solid fa-backward-step"></i>
                        </button>

                        <input
                            type="date"
                            value={selectedDate}
                            onChange={onChange}
                            className="date-input"
                        />

                        <button style={{ fontWeight: "bold" }} className="button_move ml-2" onClick={handleNext}>
                            <span class="web-display">{t('label_next')}</span>
                            <i class="mob-display fa-solid fa-forward-step"></i></button>
                        {(userid === "AD" || userid === "AS") && editable && (
                            <a className="button_move"
                                onClick={handleSaveClick}
                                style={{ marginLeft: "5px", marginRight: '5px', fontWeight: '200px' }}
                            >
                                {t("label_save")}
                            </a>
                        )}
                        {(userid === "AD" || userid === "AS") ? (
                            <a className="button_move"
                                onClick={editable ? handleCancelClick : handleEditClick} style={{ marginLeft: "5px" }}>
                                <span class="web-display">{buttonText}</span><i class="mob-display fa-solid fa-pen-to-square"></i></a>
                        ) : ('')}
                        <a
                            className="button_move ml-2"
                            type="link"
                            onClick={() => shareResLink(dataToDisplay && dataToDisplay.title,
                                            "Check out this horoscope!",
                                            `horoscopeDetails/${hid}`
                            )}
                        >
                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                        </a>
                    </div>
                    {/* {(userid === "AD" || userid === "AS") && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'end' }}>
                             <Link to='/editDailyHoroscope/0' className="btn btn-warning shadow-0 yellowbtn">{t("label_create_daily_horo")}</Link>
                        </div>
                    )} */}
                </div>
            </div>
            <div className="details-icons-main mob-nav">
                <div className="container details-icons">
                    <div className={`details-icons-list `} onClick={() => scrollToSection(dayinfoRef)}>
                        <i className="fa-regular fa-newspaper btn-c1"></i>
                        <span className='names'>{t("label_day_info")}</span>
                    </div>
                    <div className={`details-icons-list `} onClick={() => scrollToSection(dayspecialRef)}>
                        <i className="fa-regular fa-image btn-c2"></i>
                        <span>{t('label_day_special')}</span>
                    </div>
                    <div className={`details-icons-list `} onClick={() => scrollToSection(sunmoonRef)}>
                        <i className="fa-solid fa-clock-rotate-left btn-c3"></i>
                        <span>{t('label_sun_moon')}</span>
                    </div>
                    <div className={`details-icons-list `} onClick={() => scrollToSection(goodtimeRef)}>
                        <i className="fa-solid fa-clock-rotate-left btn-c4"></i>
                        <span>{t('label_good_time')}</span>
                    </div>
                    <div className={`details-icons-list `} onClick={() => scrollToSection(badtimeRef)}>
                        <i className="fa-solid fa-clock-rotate-left btn-c4"></i>
                        <span>{t('label_bad_time')}</span>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-panchang-detailspage">
                <div className='container'>
                    {horLoading ? (
                        <Error />
                    ) : (
                        dataToDisplay ? (


                            <div className='horoscope-item '>

                                <div class="container">
                                    <div class="row">


                                        <div class="col-lg-4 col-md-12 p-0">
                                            <div class="weather-card rain">
                                                <div class="top-sec">
                                                    <div class="wrapper">
                                                        <div class="panchang-img" ref={dayinfoRef}></div>
                                                        {/* <h1 class="heading"> {dataToDisplay && dataToDisplay.title}</h1> */}
                                                        <h3 class="location text-center"> {dataToDisplay && dataToDisplay.title}</h3>

                                                    </div>
                                                </div>
                                                <img src={bordertop} class="w-100" />



                                                <div class="bottom-sec">
                                                    <div class="wrapper">

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_year_info")}</Typography.Title>} name="year_info">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.year_info} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.year_info}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_maasam")}</Typography.Title>} name="maasam">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.maasam} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.maasam}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>


                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_vaaram")}</Typography.Title>} name="vaaram">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.vaaram} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.vaaram}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_ruthuvu")}</Typography.Title>} name="ruthuvu">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.ruthuvu} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.ruthuvu}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_nak")}</Typography.Title>} name="nakshatra">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.nakshatra} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.nakshatra}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_tith")}</Typography.Title>} name="tithi">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.tithi} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.tithi}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_yogam")}</Typography.Title>} name="yoga">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.yoga} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.yoga}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>

                                                        <div className="tiles-designs panchang-main-sec">
                                                            <Form.Item label={<Typography.Title level={4}>{t("label_karanam")}</Typography.Title>} name="karanam">
                                                                {editable ? (
                                                                    <Input defaultValue={dataToDisplay.karanam} />
                                                                ) : (
                                                                    <Typography.Text className='typ'>{dataToDisplay.karanam}</Typography.Text>
                                                                )}
                                                            </Form.Item>
                                                        </div>


                                                    </div>
                                                </div>
                                                <img src={bordertop} class="w-100 rotate-180 mt-4 mb-2" />
                                            </div>
                                        </div>

                                        <div class="col-lg-8 col-md-12 mt-3 p-0">
                                            <div class="grey-bg container-fluid h-100">
                                                <section ref={dayspecialRef}>
                                                    <div>
                                                        <div class="row" >
                                                            <div class="col-12 mt-3 mb-1">
                                                                <h4 class="text-uppercase"><i class="fa-solid fa-hands-praying mr-2"></i>{t("label_todays_special")}</h4>
                                                            </div>
                                                        </div>


                                                        <hr class="mt-1" />
                                                        <p className='bold-text-line-popup'> {formatBoldText(dataToDisplay.txt)}</p>

                                                    </div>
                                                </section>
                                                {/* <section id="stats-subtitle">
                                                        <div class="row">
                                                            <div class="col-12 mt-3 mb-1">
                                                                <h4 class="text-uppercase">Statistics With Subtitle</h4>
                                                                <p>Statistics on minimal cards with Title &amp; Sub Title.</p>
                                                            </div>
                                                        </div>
                                                    </section> */}

                                                <section >
                                                    <div class="row" ref={sunmoonRef}>
                                                        <div class="col-12 mt-3 mb-1">
                                                            <h4 class="text-uppercase"><i class="fa-regular fa-sun mr-2"></i>{t("label_sun_moon_details")}<i class="fa-solid fa-moon ml-2"></i></h4>
                                                        </div>
                                                    </div>


                                                    <hr class="mt-1" />
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="danger">{t("label_suryarasi")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input defaultValue={dataToDisplay.suryarasi} />
                                                                                    ) : (
                                                                                        dataToDisplay.suryarasi
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-solid fa-cloud-sun panchang-icon3" style={{ color: 'rgb(174 174 171)' }}></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-rocket danger font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="success">{t("label_chandrarasi")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input defaultValue={dataToDisplay.chandrarasi} />
                                                                                    ) : (
                                                                                        dataToDisplay.chandrarasi
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-solid fa-cloud-moon panchang-icon3" style={{ color: 'rgb(174 174 171)' }}></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-user success font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="warning"> {t("label_sunrise")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input defaultValue={dataToDisplay.sunrise} />
                                                                                    ) : (
                                                                                        dataToDisplay.sunrise
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-solid fa-sun panchang-icon3" style={{ color: 'red' }}></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-pie-chart warning font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="warning">{t("label_moon_rise")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input defaultValue={dataToDisplay.moonrise} />
                                                                                    ) : (
                                                                                        dataToDisplay.moonrise
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-solid fa-moon panchang-icon3" style={{ color: '#e9d61c' }}></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-pie-chart warning font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="primary">{t("label_sunset")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input defaultValue={dataToDisplay.sunset} />
                                                                                    ) : (
                                                                                        dataToDisplay.sunset
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-sun panchang-icon3" style={{ color: '#ffa5a5' }}></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-support primary font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="primary">{t("label_moon_set")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.moonset} />
                                                                                    ) : (
                                                                                        dataToDisplay.moonset
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-moon panchang-icon3" style={{ color: '#e9e3af' }}></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-support primary font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </section>
                                                <section >
                                                    <div class="row">
                                                        <div class="col-12 mt-5 mb-1">
                                                            <h4 class="text-uppercase"><i class="fa-regular fa-clock mr-2"></i>{t("label_good_bad_times")}</h4>

                                                        </div>
                                                    </div>

                                                    <hr class="mt-1" />

                                                    <div class="row">

                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="primary">{t("label_amruthkal")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.amruthkal} />
                                                                                    ) : (
                                                                                        dataToDisplay.amruthkal
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-clock mr-2 panchang-icon2"></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-support primary font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2" ref={goodtimeRef}>
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="primary">{t("label_shubhkal")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.shubhakal} />
                                                                                    ) : (
                                                                                        dataToDisplay.shubhakal
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-clock mr-2 panchang-icon2"></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-support primary font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <img src={lineborder} class="w-100 mb-3 mt-2" />


                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="danger">{t("label_rahu_time")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.rh_time} />
                                                                                    ) : (
                                                                                        dataToDisplay.rh_time
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-clock mr-2 panchang-icon2-red"></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-rocket danger font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="success">{t("label_yam")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.yg_time} />
                                                                                    ) : (
                                                                                        dataToDisplay.yg_time
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-clock mr-2 panchang-icon2-red"></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-user success font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2">
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="warning">{t("label_varjyam")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.varjyam} />
                                                                                    ) : (
                                                                                        dataToDisplay.varjyam
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-clock mr-2 panchang-icon2-red"></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-pie-chart warning font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-sm-12 mb-2" ref={badtimeRef}>
                                                            <div class="card">
                                                                <div class="card-content">
                                                                    <div class="card-body">
                                                                        <div class="media d-flex">
                                                                            <div class="media-body text-left">
                                                                                <h3 class="warning">{t("label_durmuhurtham")}</h3>
                                                                                <span>
                                                                                    {editable ? (
                                                                                        <Input.TextArea defaultValue={dataToDisplay.durmuhurth} />
                                                                                    ) : (
                                                                                        dataToDisplay.durmuhurth
                                                                                    )}

                                                                                </span>
                                                                            </div>
                                                                            <i class="fa-regular fa-clock mr-2 panchang-icon2-red"></i>
                                                                            {/* <div class="align-self-center">
                                                                                    <i class="icon-pie-chart warning font-large-2 float-right"></i>
                                                                                </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>


                                                </section>

                                                {/* <section id="stats-subtitle">
                                                        <div class="row">
                                                            <div class="col-12 mt-3 mb-1">
                                                                <h4 class="text-uppercase">Statistics With Subtitle</h4>
                                                                <p>Statistics on minimal cards with Title &amp; Sub Title.</p>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-xl-6 col-md-12">
                                                                <div class="card overflow-hidden">
                                                                    <div class="card-content">
                                                                        <div class="card-body cleartfix">
                                                                            <div class="media align-items-stretch">
                                                                                <div class="align-self-center">
                                                                                    <i class="icon-pencil primary font-large-2 mr-2"></i>
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <h4>Total Posts</h4>
                                                                                    <span>Monthly blog posts</span>
                                                                                </div>
                                                                                <div class="align-self-center">
                                                                                    <h1>18,000</h1>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-6 col-md-12">
                                                                <div class="card">
                                                                    <div class="card-content">
                                                                        <div class="card-body cleartfix">
                                                                            <div class="media align-items-stretch">
                                                                                <div class="align-self-center">
                                                                                    <i class="icon-speech warning font-large-2 mr-2"></i>
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <h4>Total Comments</h4>
                                                                                    <span>Monthly blog comments</span>
                                                                                </div>
                                                                                <div class="align-self-center">
                                                                                    <h1>84,695</h1>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-xl-6 col-md-12">
                                                                <div class="card">
                                                                    <div class="card-content">
                                                                        <div class="card-body cleartfix">
                                                                            <div class="media align-items-stretch">
                                                                                <div class="align-self-center">
                                                                                    <h1 class="mr-2">$76,456.00</h1>
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <h4>Total Sales</h4>
                                                                                    <span>Monthly Sales Amount</span>
                                                                                </div>
                                                                                <div class="align-self-center">
                                                                                    <i class="icon-heart danger font-large-2"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-6 col-md-12">
                                                                <div class="card">
                                                                    <div class="card-content">
                                                                        <div class="card-body cleartfix">
                                                                            <div class="media align-items-stretch">
                                                                                <div class="align-self-center">
                                                                                    <h1 class="mr-2">$36,000.00</h1>
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <h4>Total Cost</h4>
                                                                                    <span>Monthly Cost</span>
                                                                                </div>
                                                                                <div class="align-self-center">
                                                                                    <i class="icon-wallet success font-large-2"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>






                                <div class="panchang-sec d-none">

                                    <div class="timeline">
                                        <p class="float-left w-100 cardTop p-4">
                                            {/* <span class="title">{t('label_details')}</span> */}
                                            <span class="date">  <ShowMoreText charLimit={50} text={dataToDisplay && dataToDisplay.txt ? dataToDisplay.txt.split("<CM>").join("\n") : ""} /></span></p>
                                        <div class="timeline-main">
                                            <Form form={form}>

                                                <div className="active-details position-relative d-none">
                                                    {/* <div className="calendar-wrapper">
                                       <Calendar fullscreen={false} onPanelChange={onPanelChange} value={selectedDateFull}
                                           onChange={onCalenderChange} />
                                   </div> */}
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_year_info")}</Typography.Title>} name="year_info">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.year_info} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.year_info}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_maasam")}</Typography.Title>} name="maasam">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.maasam} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.maasam}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_vaaram")}</Typography.Title>} name="vaaram">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.vaaram} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.vaaram}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_ruthuvu")}</Typography.Title>} name="ruthuvu">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.ruthuvu} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.ruthuvu}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_nak")}</Typography.Title>} name="nakshatra">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.nakshatra} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.nakshatra}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_tith")}</Typography.Title>} name="tithi">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.tithi} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.tithi}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>





                                                    <div className="tiles-designs" >
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_amruthkal")}</Typography.Title>} name="amruthkal">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.amruthkal} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.amruthkal}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs" >
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_shubhkal")}</Typography.Title>} name="shubhakal">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.shubhakal} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.shubhakal}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs" >
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_rahu_time")}</Typography.Title>} name="rh_time">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.rh_time} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.rh_time}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_yam")}</Typography.Title>} name="yg_time">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.yg_time} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.yg_time}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_varjyam")}</Typography.Title>} name="varjyam">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.varjyam} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.varjyam}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>

                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_durmuhurtham")}</Typography.Title>} name="durmuhurth">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.durmuhurth} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.durmuhurth}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>




                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_suryarasi")}</Typography.Title>} name="suryarasi">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.suryarasi} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.suryarasi}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_chandrarasi")}</Typography.Title>} name="chandrarasi">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.chandrarasi} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.chandrarasi}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs" >
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_sunrise")}</Typography.Title>} name="sunrise">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.sunrise} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.sunrise}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_sunset")}</Typography.Title>} name="sunset">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.sunset} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.sunset}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_yogam")}</Typography.Title>} name="yoga">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.yoga} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.yoga}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>

                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_karanam")}</Typography.Title>} name="karanam">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.karanam} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.karanam}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_author")}</Typography.Title>} name="author">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.author} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.author}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>} name="date">
                                                            {editable ? (
                                                                <Input defaultValue={dataToDisplay.publish_date} />
                                                            ) : (
                                                                <Typography.Text className='typ'>{dataToDisplay.publish_date}</Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="tiles-designs">
                                                        <Form.Item label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>} name="txt">
                                                            {editable ? (
                                                                <TextArea defaultValue={dataToDisplay.txt} onChange={(e) => setTxt(e.target.value)} />
                                                            ) : (
                                                                <Typography.Text
                                                                    style={{ whiteSpace: "pre-wrap" }}
                                                                    level={5}
                                                                    className='typ'
                                                                >
                                                                    <ShowMoreText charLimit={50} text={dataToDisplay && dataToDisplay.txt ? dataToDisplay.txt.split("<CM>").join("\n") : ""} />
                                                                </Typography.Text>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    {editable && (
                                                        <div className="tiles-designs">
                                                        </div>
                                                    )}
                                                </div>
                                            </Form >
                                            {/* <div class="timeline-sec">
                                            </div>
                                            <div class="timeline-sec">
                                            </div> */}
                                        </div>
                                    </div>

                                </div>

                                <div className="float-left w-100 d-none"><h5 className="card-text-title">{t('label_details')}
                                    {/* <span>| {t('label_horo_det_tit')}</span> */}
                                </h5></div>


                                <Form form={form}>

                                    <div className="active-details position-relative d-none">
                                        <div className="calendar-wrapper">
                                            <Calendar fullscreen={false} onPanelChange={onPanelChange} value={selectedDateFull}
                                                onChange={onCalenderChange} />
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_year_info")}</Typography.Title>} name="year_info">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.year_info} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.year_info}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_maasam")}</Typography.Title>} name="maasam">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.maasam} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.maasam}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_vaaram")}</Typography.Title>} name="vaaram">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.vaaram} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.vaaram}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_ruthuvu")}</Typography.Title>} name="ruthuvu">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.ruthuvu} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.ruthuvu}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_nak")}</Typography.Title>} name="nakshatra">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.nakshatra} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.nakshatra}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_tith")}</Typography.Title>} name="tithi">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.tithi} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.tithi}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs" >
                                            <Form.Item label={<Typography.Title level={4}>{t("label_rahu_time")}</Typography.Title>} name="rh_time">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.rh_time} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.rh_time}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_yam")}</Typography.Title>} name="yg_time">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.yg_time} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.yg_time}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_varjyam")}</Typography.Title>} name="varjyam">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.varjyam} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.varjyam}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs" >
                                            <Form.Item label={<Typography.Title level={4}>{t("label_amruthkal")}</Typography.Title>} name="amruthkal">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.amruthkal} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.amruthkal}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_durmuhurtham")}</Typography.Title>} name="durmuhurth">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.durmuhurth} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.durmuhurth}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs" >
                                            <Form.Item label={<Typography.Title level={4}>{t("label_shubhkal")}</Typography.Title>} name="shubhakal">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.shubhakal} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.shubhakal}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_suryarasi")}</Typography.Title>} name="suryarasi">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.suryarasi} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.suryarasi}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_chandrarasi")}</Typography.Title>} name="chandrarasi">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.chandrarasi} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.chandrarasi}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs" >
                                            <Form.Item label={<Typography.Title level={4}>{t("label_sunrise")}</Typography.Title>} name="sunrise">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.sunrise} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.sunrise}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_sunset")}</Typography.Title>} name="sunset">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.sunset} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.sunset}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_yogam")}</Typography.Title>} name="yoga">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.yoga} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.yoga}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_karanam")}</Typography.Title>} name="karanam">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.karanam} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.karanam}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_author")}</Typography.Title>} name="author">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.author} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.author}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>} name="date">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.publish_date} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.publish_date}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>} name="txt">
                                                {editable ? (
                                                    <TextArea defaultValue={dataToDisplay.txt} onChange={(e) => setTxt(e.target.value)} />
                                                ) : (
                                                    <Typography.Text
                                                        style={{ whiteSpace: "pre-wrap" }}
                                                        level={5}
                                                        className='typ'
                                                    >
                                                        <ShowMoreText charLimit={50} text={dataToDisplay && dataToDisplay.txt ? dataToDisplay.txt.split("<CM>").join("\n") : ""} />
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        {editable && (
                                            <div className="tiles-designs">
                                            </div>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        ) : (
                            <Result
                                status="404"
                                title={t("label_nodata_found")}
                                subTitle={t("label_no_horo_msg")}
                            />
                        )
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default TodayPanchangam;
