import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTempleGroups, postTempleGroup, postTemples } from '../../../../redux/actions/acions';

import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import AP_LANGUAGES from "../../../common/appLangs"

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const AddTempleGroup = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
const storedLanguage = localStorage.getItem('lng');
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    let rid = useParams();

    const onImageLoaded = (image) => {
        console.warn(image);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = (crop, pixelCrop) => {
        console.warn(crop, pixelCrop);
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            // File has been uploaded successfully
            setSelectedImage(info.file.response.path); // Update state with the uploaded image path
        }
    };

    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        dispatch(fetchTempleGroups(selectedLanguage, 0));
    }, [dispatch, selectedLanguage]);

    const [form] = Form.useForm();

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        showToast('success', t('label_addtemgrp_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', t('label_addtemgrp_failure'));
    };

    const onFinish = () => {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            const data = {
                "gid": 1001,
                "group_name": values.group_name,
                "imgpath": selectedImage,
                "pgid": 1,
                "descr": values.descr,
                "grank": values.grank,
                "lang": values.lang,
            };
            dispatch(postTempleGroup(data, success, failure));
        }).catch((errorInfo) => {
            console.error('Validation failed:', errorInfo);
        });
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
    };

    return (
        <>

            {contextHolder}

            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_tmpl_group_list')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                    &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_tmpl_group_list')}</span> - <span className="bc-link">{rid === 0 ? (
                        <span>{t('label_create_temple_group')}</span>
                    ) : (
                        <span>{t('label_add_tmpl_grp')}</span>
                    )}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt-singleLine container">
                        <div class="header_txt">{rid === 0 ? (
                            <>{t('label_create_temple_group')}</>
                        ) : (
                            <>{t('label_add_tmpl_grp')}</>
                        )} </div>
                        <div class="details-headertxt-singleLine-btns">
                            <a   class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t('label_cancel')}
                            </a>
                            <a   class="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                                {t('label_save')}
                            </a>
                        </div>

                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages" onFinish={onFinish} >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_tmpl_group_details")} <span>| {t('label_group_name_description')}</span></h5></div>
                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='group_name'
                                        label={<Typography.Title level={4}>{t('label_temple_group_name')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter Group Name' />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='descr'
                                        label={<Typography.Title level={4}>{t('Description')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} placeholder={t('label_enter_desscription')} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='grank'
                                        label={<Typography.Title level={4}>{t('label_rank')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Enter Rank' />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name="lang"
                                        label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a language.',
                                            },
                                        ]}
                                    >
                                        <Select options={[{ value: '', label: t('label_select_language') }, ...AP_LANGUAGES]}
                                            placeholder={t('label_select_language')}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="float-left w-100" ><h5 class="card-text-title">{t('label_add_remove_img')}</h5></div>
                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name="img"
                                        label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        extra="use png/jpg/jpeg format only"
                                    >
                                        <ImgCrop rotationSlider>
                                            <Upload
                                                name="logo"
                                                action="/upload.do"
                                                listType="picture"
                                                onChange={onFileChange}
                                            >
                                                <button icon={<UploadOutlined style={{ marginBottom: '5px' }} />} class="btn btn-outline-dark"><i class="fa-solid fa-upload"></i>&nbsp;{t('Click to upload')}</button>
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>

                                    <ReactCrop
                                        src="https://via.placeholder.com/150"
                                        crop={crop}
                                        onImageLoaded={onImageLoaded}
                                        onChange={onCropChange}
                                        onComplete={onCropComplete}
                                    />
                                </div>

                                {/* <Button type="primary" htmlType="submit" class="button_move" >{t("label_save")}</Button> */}
                            </div>
                        </Form>
                    </div>
                    <Footer />
                </div >
            </div >

        </>
    );
};
export default AddTempleGroup;