import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { fetchBooksByTag, fetchBooksFavourites, fetchGodNames, fetchLiveTv, fetchResourceByTxt, fetchVideoLinks, postBooksFavourites, removeBooksFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getLiveTvError, getLiveTvList, getLiveTvLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading, getVideoLinksList, getVideoLinksLoading } from '../../../../redux/selectors/selectors';
import deftemples from '../../../../assets/images/templeicon.png';
import { Row, Col, Card, Skeleton, message, FloatButton, Button, Select, Image, Modal, Space, Input, Result } from 'antd';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone, SearchOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import aum from '../../../../assets/icons/om.jpg'
import YouTube from './youtube';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import deityImages from '../../../common/godImages';
import godNames from '../../../common/godNames';

let last_rec = 0;
let selected_last_rec = 0;
function LiveTv() {
  const storedLanguage = localStorage.getItem('lng') || 5;
  const localEnv = false;
  // const [isLoading, setIsLoading] = useState(false);
  const records_per_page = 12;
  const [did, setDid] = useState(0);
  const [lastRec, setLastRec] = useState(0);
  const [filterId, setFilterId] = useState(0);
  const { t } = useTranslation();
  const [livetv, setLivetv] = useState('livetv');
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const userid = localStorage.getItem('urole')
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { uid } = useParams()

  const { lDetails, lLoading, lError, godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
    lDetails: getVideoLinksList(state),
    lLoading: getVideoLinksLoading(state),
    lError: getVideoLinksLoading(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  // useEffect(() => {
  //   setIsLoading(true)
  //   dispatch(fetchVideoLinks(livetv, storedLanguage, did, 0))
  //   setIsLoading(false)
  // }, [storedLanguage, did])

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const { sList, sLoading, sError } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtLoading(state),
    sError: getResourceByTxtError(state),
  }));

  useEffect(() => {
    dispatch(fetchResourceByTxt(storedLanguage, searchText, 0));
  }, []);

  const getToken = Boolean(localStorage.getItem('token'));

  const success = (title) => {
    showToast('success', t(`Added ${title} to favorites`))
  };
  const warning = (title) => {
    showToast('info', t(`Removed ${title} from favourites`))
  };

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }

  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };
  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    dispatch(fetchBooksFavourites(livetv));
  }, [getFavouriteTemples, refresh]);

  const { tDetails, tLoading, tError } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
  }))

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }

  function addFavourites(rid) {
    dispatch(postBooksFavourites(livetv, rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(livetv, deleteId, failure));
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal and set the selected video URL
  const openVideoModal = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal and clear the selected video URL
  const closeVideoModal = () => {
    setSelectedVideoUrl(null);
    setIsModalOpen(false);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [videos, setvideos] = useState('videos');
  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null);
      dispatch(fetchVideoLinks(livetv, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(livetv, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  const description = t("label_about_live_darshan");

  const categoryButtons = ['kids', 'temple', 'puran', 'dharma', 'god', 'puja', 'festival'];

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (uid) {
      setSearchText(uid);
      if (uid.length >= 5) {
        dispatch(fetchResourceByTxt(livetv, storedLanguage, uid, 0));
      }
    }
  }, [uid, dispatch]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(livetv, storedLanguage, newValue, 0));
    }
  };


  const [isGodModalVisible, setIsGodModalVisible] = useState(false);
  const [selectedFilterGod, setSelectedFilterGod] = useState(null);
  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  function getYouTubeVideoId(url) {
    // This function extracts the YouTube video ID from a YouTube URL
    const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  const [currentPage, setCurrentPage] = useState(0);
  const mainSecBlockRef = useRef(null);
  const nextTemplesList = async () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;

      if (filterId !== null && selectedItem === null) {
        const newLastRec = newPage * records_per_page;
        dispatch(fetchVideoLinks(videos, storedLanguage, did, newLastRec));
      }
      else if (selectedItem !== null) {
        const newSelectedLastRec = newPage * records_per_page;
        dispatch(fetchBooksByTag(videos, storedLanguage, filterId, selectedItem, newSelectedLastRec));
      }
      else if (searchText !== null) {
        const newSearchLastRec = newPage * records_per_page;
        dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
      }
      if (mainSecBlockRef.current) {
        mainSecBlockRef.current.scrollTop = 0;
      }
      return newPage;
    });
    setIsLoading(false);
  };

  // Previous button handler
  const prevTemplesList = () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      if (prevPage > 0) { // Ensure we don't go below the first page
        const newPage = prevPage - 1; // Decrement page

        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
          dispatch(fetchVideoLinks(videos, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * records_per_page; // Previous offset for selected items
          dispatch(fetchBooksByTag(videos, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * records_per_page; // Previous offset for search text
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
          mainSecBlockRef.current.scrollTop = 0;
        }
        return newPage; // Return updated page number
      }

      return prevPage; // If we're on the first page, don't decrement
    });
    setIsLoading(false);
  };

  // const nextTemplesList = async () => {
  //   setIsLoading(true);
  //   if (filterId !== null && selectedItem === null) {
  //     last_rec = last_rec + records_per_page;
  //     // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
  //     dispatch(fetchVideoLinks(livetv, storedLanguage, did, last_rec));
  //     // setIsLoading(false);
  //   } else if (selectedItem !== null) {
  //     selected_last_rec = selected_last_rec + records_per_page;
  //     dispatch(fetchBooksByTag(livetv, storedLanguage, filterId, selectedItem, selected_last_rec));
  //   }
  // }



  // function prevTemplesList() {
  //   if (filterId !== null && selectedItem === null) {
  //     setLastRec(prevLastRec => Math.max(prevLastRec - records_per_page, 0));
  //     dispatch(fetchVideoLinks(livetv, storedLanguage, did, lastRec));
  //   } else if (selectedItem !== null) {
  //     selected_last_rec = selected_last_rec - records_per_page;
  //     dispatch(fetchBooksByTag(livetv, storedLanguage, filterId, selectedItem, selected_last_rec));
  //   }
  // }

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading data
    const loadData = async () => {
      setIsLoading(true);
      try {
        dispatch(fetchVideoLinks(livetv, storedLanguage, did, 0))
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [searchText, selectedItem, lDetails, sList, tDetails]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loading">
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      );
    }

    if (!searchText && !selectedItem) {
      return lDetails && lDetails.length > 0 ? (
        lDetails.map((temple) => (
          <YouTube
            key={temple.id}
            temple={temple}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            favList={favList}
            getYouTubeVideoId={getYouTubeVideoId}
            text={'liveTv'}
          />
        ))
      ) : (
        <div className="centered-container">
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_livetv_msg")}
        />
        </div>
      );
    }

    if (uid >= 5 || (searchText && searchText.length >= 5)) {
      return sList && sList.length > 0 ? (
        sList.map((temple) => (
          <YouTube
            key={temple.id}
            temple={temple}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            favList={favList}
            getYouTubeVideoId={getYouTubeVideoId}
            text={'liveTv'}
          />
        ))
      ) : (
        <div className="centered-container">
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_livetv_msg")}
        />
        </div>
      );
    }

    if (selectedItem != null) {
      return tDetails && tDetails.length > 0 ? (
        tDetails.map((temple) => (
          <YouTube
            key={temple.id}
            temple={temple}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            favList={favList}
            getYouTubeVideoId={getYouTubeVideoId}
            text={'liveTv'}
          />
        ))
      ) : (
        <div className="centered-container">
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_livetv_msg")}
        />
        </div>
      );
    }

    return lDetails && lDetails.length > 0 ? (
      lDetails.map((temple) => (
        <YouTube
          key={temple.id}
          temple={temple}
          toggleFavorite={toggleFavorite}
          toggleFilled={toggleFilled}
          favList={favList}
          getYouTubeVideoId={getYouTubeVideoId}
          text={'liveTv'}
        />
      ))
    ) : (
      <div className="centered-container">
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, there is no live TV available at the moment."
        />
      </div>
    );
  };


  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (

    <div class="mainsec" ref={mainSecBlockRef}>
      <div class=" mob-nav fluid-container page-title justify-content-between">
        <div class="d-flex align-items-center left-sec-mob-nav">
          <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
          <span className="bc-link">{t("lable_live_darshan")}</span>
          {did !== 0 && (
            <div className="detail-container">
            </div>
          )}
        </div>

        <div class="right-sec-mob-nav d-flex align-items-center">
          {/* <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
            <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>
            {did !== 0 && (
              <>
                <div className="detail-container">
                  <span className="god-button">{godNames[filterId]}</span>
                  <CloseCircleOutlined
                    className="close-icon"
                    onClick={(event) => {
                      event.stopPropagation();
                      setFilterId(0);
                      setDid(0);
                      dispatch(fetchVideoLinks(livetv, storedLanguage, 0, 0))
                    }}
                  />
                </div>
              </>
            )}
            {did === 0 && (
              <div className="detail-container d-flex align-items-center">
                <span className="detail-but-god ml-2">{t("label_all")}</span>
              </div>
            )}

          </button> */}
        </div>
      </div>
      <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

        <div class="d-flex">
          <Link style={{ color: 'black' }} to='/'>
            <span className="bc-active-link">{t('Home')}</span>
          </Link>&nbsp;-&nbsp;{' '}
          <Link to='/library'>
            <span className="bc-active-link" > {t('label_library')}</span></Link>&nbsp;-&nbsp;

          <span className="bc-link">{t("label_livetv")}</span>{' '}&nbsp;&nbsp;
          {/* <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
            <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>
            {did !== 0 && (
              <>
                <div className="detail-container">
                  <span className="god-button">{godNames[filterId]}</span>
                  <CloseCircleOutlined
                    className="close-icon"
                    onClick={(event) => {
                      event.stopPropagation();
                      setFilterId(0);
                      setDid(0);
                      dispatch(fetchVideoLinks(livetv, storedLanguage, 0, 0))
                    }}
                  />
                </div>
              </>
            )}
            {did === 0 && (
              <div className="detail-container d-flex align-items-center">
                <span className="detail-but-god ml-2">{t("label_all")}</span>
              </div>
            )}

          </button> */}
        </div>
      </div>
      <div class="popup-bg" style={{ display: isGodModalVisible ? 'flex' : 'none' }}>
        <div class="popup-main-filter animate__animated animate__fadeInUp ">
          <div class="popup-main-header-filter">
            <span class="popup-main-header-text"> {t("label_god_categ")}</span>
            <div class="popup-close" onClick={handleFilterGodCancel}><i class="fa-solid fa-xmark"></i></div>
            <div class="popup-back" onClick={handleFilterGodCancel}><i class="fa-solid fa-chevron-left"></i></div>
          </div>
          <div class="popup-main-container">
            <div class="d-block htinner-popup-main-containe">
              <div className=" god-category-list1">
                <div class="all-gods-sec">
                  {t("label_all")}
                </div>
                <div class="god_select">
                  <div class="god_pic">
                    <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                      onClick={() => {
                        setFilterId(0);
                        setDid(0);
                        setIsGodModalVisible(false);
                      }}
                    />
                    {/* {categoryButtons.map((category, index) => (
                      <div class="god_pic" key={index}>
                        <button style={getStyle(0)} className='dietyBtn' alt={category}
                          onClick={() => {
                            handleButtonClick(category);
                            setIsGodModalVisible(false);
                          }}
                        >
                          {t('label_'+category)}
                        </button>
                      </div>
                    ))} */}
                  </div>
                </div>
                <div class="all-gods-sec">
                  {t('label_filter_by_god')}
                </div>
                <Row gutter={[16, 16]}>
                  {godCtgryList &&
                    godCtgryList.map((godCtgry, index) => (
                      <Col key={godCtgry.avatar}>
                        <div class="god_select">
                          <div class="god_pic">
                            <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                              onClick={() => {
                                filterTemplesList(godCtgry.avatar);
                                setIsGodModalVisible(false);
                              }}
                            />
                            <p>{godCtgry.deity}</p>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
          <div class="popup-main-footer">
            <a className="button_move" onClick={handleFilterGodCancel}>{t('label_cancel')}</a>
          </div>
        </div>
      </div>
      {/* <Modal visible={isGodModalVisible} footer={null}>
        <div className="diag-header">
          {t("label_god_categ")}
          <span class="diag-close">
            <i class="fa-solid fa-xmark" onClick={handleFilterGodCancel}></i>
          </span>
        </div>
        <div className="dialog-content god-category-list1">


          <div class="all-gods-sec">
            All
          </div>
          <div class="god_select">
            <div class="god_pic">
              <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                onClick={() => {
                  setFilterId(0);
                  setDid(0);

                }}
              />

              {categoryButtons.map((category, index) => (

                <div class="god_pic" key={index}>
                  <button style={getStyle(0)} className='dietyBtn' alt={category}
                    onClick={() => {
                      handleButtonClick(category);
                      setIsGodModalVisible(false);
                    }}
                  >
                    {t('label_'+category)}
                  </button>

                </div>
              ))}
            </div>
          </div>


          <div class="all-gods-sec">
            Filter By God
          </div>

          <Row gutter={[16, 16]}>
            {godCtgryList &&
              godCtgryList.map((godCtgry, index) => (
                <Col key={godCtgry.avatar}>
                  <div class="god_select">
                    <div class="god_pic">
                      <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                        onClick={() => {
                          filterTemplesList(godCtgry.avatar);
                          setIsGodModalVisible(false);
                        }}
                      />
                      <p>{godCtgry.deity}</p>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
        <div className="dialog-buttons">
          <Button className="login-form-button" onClick={handleFilterGodCancel}>{t('label_cancel')}</Button>
        </div>
      </Modal> */}
      <div class="details-icons-main"><div class="container details-icons">
        <button
          className={`details-icons-list ${selectedItem === null ? 'selected' : ''}`}
          onClick={() => handleButtonClick("All")}>{t('label_all')}</button>
        {categoryButtons.map((category, index) => (
          <button
            key={index}
            type="button"
            className={`details-icons-list ${selectedItem === category ? 'selected' : ''}`}
            onClick={() => handleButtonClick(category)}
          >
            {t('label_' + category)}
          </button>
        ))}
      </div>
      </div>
      <div class="mainsec-block-addtemple-detailspage2">

        {/* <div class="container cat-txt ">
          <div class="cat-detail">
            <span class="f3 d-flex justify-content-between">
              <div><b>{t("About")} {t("label_livetv")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
            </span>
            <p class="f1">{description}
            </p>
          </div></div> */}
        {/* <div className='container '>

          <Input
            className='resource-search mb-3 mt-3'
            name="query"
            placeholder="Enter search keyword"
            value={searchText}
            onChange={handleInputChange}
            allowClear
            prefix={<SearchOutlined />}

          />

        </div> */}



        <div className="container search">
          <Row>
            {renderContent()}
          </Row>



          <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} />
        </div>
        <div className="next-row">
          {lDetails && lDetails.length > 0 ? (
            <Col>
              <a
                className="button_move"
                onClick={currentPage === 0 ? null : prevTemplesList}
                disabled={currentPage === 0}
              >
                Prev
              </a>
            </Col>
          ) : (
            ''
          )}
          {lDetails && lDetails.length > 0 ? (
            <Col>
              <a
                className="button_move"
                onClick={lDetails.length < records_per_page ? null : nextTemplesList}
                disabled={lDetails.length < records_per_page}
              >
                Next
              </a>
            </Col>
          ) : (
            ''
          )}
        </div>
        <Footer />
      </div>

    </div>
  );
}

export default LiveTv;
