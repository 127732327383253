import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Form, Input, Button, Space, DatePicker, Result } from 'antd';
import { getDailyHoroscopeDetailsList, getDailyHoroscopeDetailsLoading, getHoroDetailsByDtError, getHoroDetailsByDtList, getHoroDetailsByDtLoading, getWeeklyHoroscopeDetailsList, getWeeklyHoroscopeDetailsLoading, getWeeklyHoroscopeList, getWeeklyHoroscopeLoading } from '../../../../redux/selectors/selectors';
import { editHoroDetails, editWeeklyHoroDetails, fetchDailyHoroscopeDetails, fetchHoroDetailsByDate, fetchWeeklyHoroscope, fetchWeeklyHoroscopeDetails, updateHoroTitle, updateHoroTxt } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import moment from 'moment';
import nodata from '../../../../assets/images/nodata5.jpg';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';
import Error from '../error';

const DailyRaasiDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng');
    const { hid } = useParams();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [title, setTitle] = useState(null);
    const [txt, setTxt] = useState(null);
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const today = moment(); // Current date
    const initialWeek = `${today.year()}-W${today.isoWeek().toString().padStart(2, '0')}`;

    const [selectedWeek, setSelectedWeek] = useState(initialWeek); // Initialize with the current week


    const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // For monthly
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const userid = localStorage.getItem('urole');
    const [isDateChanged, setIsDateChanged] = useState(false);

    
    const shareResLink = (title, txt, url) => {
        if (navigator.share) {
            navigator.share({
                title: title,
                text: txt,
                url: 'https://www.templeswiki.com/share/'+url
            })
            .then(() => console.warn("Share successful."))
            .catch((error) => console.error("Error sharing:", error));
        } else {
            // Fallback for browsers that do not support the Web Share API
            console.error("Web Share API not supported.");
            window.flutter_inappwebview.callHandler('shareResLink', title, txt, url);
        }
    };

    function getCurrentWeek() {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDays = Math.floor((today - startOfYear) / (24 * 60 * 60 * 1000));
        return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7); // ISO week number
    }

    function formatBoldText(txt) {
        if (txt) {
            return <div dangerouslySetInnerHTML={
                { __html: formatPlainText(txt) }
            } />
        } else {
            return ''
        }
    }

    function formatPlainText(txt) {
        if (txt) {
            let finalTxt = ''
            let allLines = txt.split("<BR>");
            for (let i = 0; i < allLines.length; i++) {
                if (allLines[i].includes('<B>')) {
                    finalTxt = finalTxt + (allLines[i] + '</B>') + '\n';
                } else {
                    finalTxt = finalTxt + allLines[i] + '\n';
                }
            }
            return finalTxt;
        } else {
            return ''
        }
    }

    function getWeekNumberAndStartDate(date) {
        // Clone the date to avoid mutating the original
        const currentDate = new Date(date);

        // Set the current date to the nearest Thursday (currentDate.getDay() is 0-6)
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));

        // Get the first day of the year
        const yearStart = new Date(currentDate.getFullYear(), 0, 1);

        // Calculate the number of days between the year start and the current date
        const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);

        // Get the start date of the week (Monday)
        const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1));

        // Format the start date to YYYY-MM-DD
        const startDateFormatted = startOfWeek.toISOString().split('T')[0];

        return {
            weekNumber: weekNumber,
            weekStartDate: startDateFormatted
        };
    }

    const today1 = new Date();
    const result = getWeekNumberAndStartDate(today1);

    console.log(`Week Number: ${result.weekNumber}`);
    console.log(`Week Start Date: ${result.weekStartDate}`);

    const { horoDet, horLoading, horoError } = useSelector((state) => ({
        horoDet: getWeeklyHoroscopeList(state),
        horLoading: getWeeklyHoroscopeLoading(state),
        horoError: getHoroDetailsByDtError(state),
    }));


    const fetchHoroscopeForDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('dhoro', storedLanguage, formattedDate, failure));
    };

    const fetchHoroscopeForWeeklyDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('whoro', storedLanguage, formattedDate, failure));
    };


    const fetchHoroscopeForMonthlyDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('mhoro', storedLanguage, formattedDate, failure));
    };

    const fetchHoroscopeForYearlyDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('yhoro', storedLanguage, formattedDate, failure));
    };

    useEffect(() => {
        if (selectedItem === "weekly") {
            const startOfWeek = moment(selectedWeek, "YYYY-WW").startOf("isoWeek").format("YYYY-MM-DD");
            fetchHoroscopeForWeeklyDate(startOfWeek);
        } else if (selectedItem === "monthly") {
            const startOfMonth = moment(selectedMonth, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
            fetchHoroscopeForMonthlyDate(startOfMonth);
        } else if (selectedItem === "yearly") {
            const startOfYear = `${selectedYear}-01-01`;
            fetchHoroscopeForYearlyDate(startOfYear);
        } else {
            fetchHoroscopeForDate(selectedDate);
        }
    }, [storedLanguage, dispatch, selectedItem, selectedWeek, selectedMonth, selectedYear, selectedDate]);




    // const onChange = (event) => {
    //     const date = event.target.value;
    //     setSelectedDate(date);
    //     setIsDateChanged(true);
    //     fetchHoroscopeForDate(date);
    // };

    function getStartDateOfISOWeek(week, year) {
        const firstDayOfYear = new Date(year, 0, 1);
        const daysOffset = (week - 1) * 7;
        const firstWeekStart = new Date(
            firstDayOfYear.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1)
        ); // Adjust to Monday
        const startDate = new Date(firstWeekStart.setDate(firstWeekStart.getDate() + daysOffset));
        return startDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    }



    const onChange = (event) => {
        const date = event.target.value;

        if (selectedItem === "weekly") {
            const weekValue = date; // Format: "2024-W49"

            if (weekValue) {
                const year = parseInt(weekValue.split("-W")[0], 10);
                const week = parseInt(weekValue.split("-W")[1], 10);

                // Calculate the start date of the week
                const firstDayOfYear = new Date(year, 0, 1);
                const selectedDate = new Date(
                    firstDayOfYear.setDate(firstDayOfYear.getDate() + (week - 1) * 7)
                );

                const { weekStartDate } = getWeekNumberAndStartDate(selectedDate);

                setSelectedWeek(weekValue); // Update state for the input value
                fetchHoroscopeForWeeklyDate(weekStartDate); // Fetch horoscope with formatted date

                // Reset other selections
                setSelectedDate(new Date().toISOString().split('T')[0]); // Reset daily
                setSelectedMonth(new Date().toISOString().slice(0, 7)); // Reset monthly
                setSelectedYear(new Date().getFullYear()); // Reset yearly
            }
        }
        // Handle other categories (monthly, yearly, daily) as before
        else if (selectedItem === "monthly") {
            const [year, month] = date.split("-");
            const startDate = `${year}-${month}-01`;

            setSelectedMonth(startDate);
            fetchHoroscopeForMonthlyDate(startDate);
            setSelectedWeek(null);
            setSelectedDate(new Date().toISOString().split('T')[0]); // Reset daily
            setSelectedYear(new Date().getFullYear()); // Reset yearly
        } else if (selectedItem === "yearly") {
            setSelectedYear(date);
            const startDate = `${date}-01-01`;
            fetchHoroscopeForYearlyDate(startDate);
            setSelectedWeek(null);
            setSelectedDate(new Date().toISOString().split('T')[0]); // Reset daily
            setSelectedMonth(new Date().toISOString().slice(0, 7)); // Reset monthly
        } else {
            setSelectedDate(date);
            fetchHoroscopeForDate(date);
            setSelectedWeek(null);
            setSelectedMonth(new Date().toISOString().slice(0, 7)); // Reset monthly
            setSelectedYear(new Date().getFullYear()); // Reset yearly
        }

        setIsDateChanged(true);
    };







    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getWeeklyHoroscopeDetailsList(state),
        hLoading: getWeeklyHoroscopeDetailsLoading(state),
    }));

    // useEffect(() => {
    //     dispatch(fetchWeeklyHoroscopeDetails(hid, storedLanguage));
    // }, [storedLanguage, dispatch]);

    useEffect(() => {
        if (hDetails && hDetails.publish_date) {
            setSelectedDate(hDetails.publish_date);
        }
    }, [hDetails]);

    const success = () => {
        showToast('success', t('label_horoedit_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', msg);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        setButtonText(t('edit'));
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setTitle(hDetails && hDetails.title);
            setTxt(hDetails && hDetails.txt);
        } else {
            setButtonText(t('edit'));
        }
        setEditable(!editable);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const dataToSend = {
                    nakshatra: values.nakshatra || hDetails.nakshatra,
                    tithi: values.tithi || hDetails.tithi,
                    yg_time: values.yg_time || hDetails.yg_time,
                    rh_time: values.rh_time || hDetails.rh_time,
                    "mesha": values.mesha || hDetails.mesha,
                    "vrushab": values.vrushab || hDetails.vrushab,
                    "midhuna": values.midhuna || hDetails.midhuna,
                    "karkataka": values.karkataka || hDetails.karkataka,
                    "simha": values.simha || hDetails.simha,
                    "kanya": values.kanya || hDetails.kanya,
                    "thula": values.thula || hDetails.thula,
                    "vruchika": values.vruchika || hDetails.vruchika,
                    "dhanassu": values.dhanassu || hDetails.dhanassu,
                    "makara": values.makara || hDetails.makara,
                    "kumbha": values.kumbha || hDetails.kumbha,
                    "meena": values.meena || hDetails.meena,
                    "title": values.title || hDetails.title,
                    "txt": values.txt || hDetails.txt,
                };
                dispatch(editWeeklyHoroDetails(hid, storedLanguage, dataToSend, success, failure));
            })
    };

    useEffect(() => {
        if (hid !== 'weekly' && hid !== 'monthly') {
            dispatch(fetchWeeklyHoroscopeDetails(hid, storedLanguage));
        }
    }, [hid, storedLanguage, dispatch]);


    const updateTitle = (e) => {
        dispatch(updateHoroTitle(hid, storedLanguage, { title: `${e.target.value}` }, () => showToast('success', ' Updated Title Successfully', 'failure', 'Error occured')))
    };

    const updateTxt = (e) => {
        dispatch(updateHoroTxt(hid, storedLanguage, { txt: `${e.target.value}` }, () => showToast('success', ' Updated Text Successfully', 'failure', 'Error occured')))
    };

    const dataToDisplay =
        isDateChanged
            ? horoDet
            : (hid === 'weekly' || hid === 'monthly' || hid === 'yearly')
                ? horoDet
                : typeof hid === 'number'
                    ? hDetails
                    : horoDet;


    const handleLinkClick = () => {
        // setOpen(false);
        message.info('User does not have permission !');
    };

    const handlePrevious = () => {
        if (selectedItem === "monthly") {
            handleMonthlyPrevious();
        } else if (selectedItem === "yearly") {
            handleYearlyPrevious();
        } else {
            const newDate = new Date(selectedDate);
            newDate.setDate(newDate.getDate() - 1);
            const formattedDate = newDate.toISOString().split("T")[0];
            setSelectedDate(formattedDate);
            fetchHoroscopeForDate(formattedDate);
            setIsDateChanged(true);
        }
    };

    const handleNext = () => {
        if (selectedItem === "monthly") {
            handleMonthlyNext();
        } else if (selectedItem === "yearly") {
            handleYearlyNext();
        } else {
            const newDate = new Date(selectedDate);
            newDate.setDate(newDate.getDate() + 1);
            const formattedDate = newDate.toISOString().split("T")[0];
            setSelectedDate(formattedDate);
            fetchHoroscopeForDate(formattedDate);
            setIsDateChanged(true);
        }
    };

    const handleMonthlyPrevious = () => {
        const newMonth = moment(selectedMonth, "YYYY-MM").subtract(1, "month").format("YYYY-MM");
        setSelectedMonth(newMonth);
        fetchHoroscopeForMonthlyDate(newMonth);
        setIsDateChanged(true);
    };

    const handleMonthlyNext = () => {
        const newMonth = moment(selectedMonth, "YYYY-MM").add(1, "month").format("YYYY-MM");
        setSelectedMonth(newMonth);
        fetchHoroscopeForMonthlyDate(newMonth);
        setIsDateChanged(true);
    };

    const handleYearlyPrevious = () => {
        const newYear = parseInt(selectedYear, 10) - 1;
        setSelectedYear(newYear);
        fetchHoroscopeForYearlyDate(newYear);
        setIsDateChanged(true);
    };

    const handleYearlyNext = () => {
        const newYear = parseInt(selectedYear, 10) + 1;
        setSelectedYear(newYear);
        fetchHoroscopeForYearlyDate(newYear);
        setIsDateChanged(true);
    };


    const getISOWeek = (date) => {
        const newDate = new Date(date.getTime());
        newDate.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year
        newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));
        // January 4 is always in week 1
        const week1 = new Date(newDate.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week 1
        return (
            1 +
            Math.round(
                ((newDate.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) /
                7
            )
        );
    };

    const updateWeekAndFetch = (weekValue) => {
        const [year, week] = weekValue.split('-W'); // Parse year and week

        const startOfWeek = moment()
            .year(year)
            .isoWeek(week)
            .startOf('isoWeek')
            .format('YYYY-MM-DD'); // Calculate the start date of the week

        if (moment(startOfWeek, 'YYYY-MM-DD', true).isValid()) {
            setSelectedWeek(weekValue); // Update the week input field
            fetchHoroscopeForWeeklyDate(startOfWeek); // Fetch horoscope data for the selected week
        } else {
            console.error('Invalid week value:', weekValue);
        }

        setIsDateChanged(true);
    };

    const handleWeeklyPrevious = () => {
        const [year, week] = selectedWeek.split('-W'); // Parse the current year and week

        // Create a moment object for the current week
        const currentWeekMoment = moment()
            .year(parseInt(year, 10)) // Set the year
            .isoWeek(parseInt(week, 10)); // Set the ISO week

        const previousWeek = currentWeekMoment.subtract(1, 'week'); // Subtract one week
        const previousWeekValue = `${previousWeek.year()}-W${previousWeek.isoWeek().toString().padStart(2, '0')}`; // Format as YYYY-WWW

        updateWeekAndFetch(previousWeekValue); // Update the week and fetch data
    };

    const handleWeeklyNext = () => {
        const [year, week] = selectedWeek.split('-W'); // Parse the current year and week

        // Create a moment object for the current week
        const currentWeekMoment = moment()
            .year(parseInt(year, 10)) // Set the year
            .isoWeek(parseInt(week, 10)); // Set the ISO week

        const nextWeek = currentWeekMoment.add(1, 'week'); // Add one week
        const nextWeekValue = `${nextWeek.year()}-W${nextWeek.isoWeek().toString().padStart(2, '0')}`; // Format as YYYY-WWW

        updateWeekAndFetch(nextWeekValue); // Update the week and fetch data
    };



    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const meshaRef = useRef(null);
    const vrushabaRef = useRef(null);
    const midhunaRef = useRef(null);
    const karkatakaRef = useRef(null);
    const simhaRef = useRef(null);
    const kanyaRef = useRef(null);
    const tulaRef = useRef(null);
    const vruschikaRef = useRef(null);
    const dhanasuRef = useRef(null);
    const makaraRef = useRef(null);
    const kumbaRef = useRef(null);
    const meenaRef = useRef(null);
    const categoryButtons = ['weekly', 'monthly', 'yearly'];
    const [selectedItem, setSelectedItem] = useState(null);
    const resetStates = (category) => {
        const currentDate = new Date();
        const isoDate = currentDate.toISOString().split('T')[0];
        const year = currentDate.getFullYear();
        const month = currentDate.toISOString().slice(0, 7);
        const week = `${year}-W${getCurrentWeek()}`;

        switch (category) {
            case 'daily':
                setSelectedDate(isoDate);
                setSelectedWeek(week);
                setSelectedMonth(month);
                setSelectedYear(year);
                break;
            case 'weekly':
                setSelectedWeek(week);
                setSelectedDate(isoDate);
                setSelectedMonth(month);
                setSelectedYear(year);
                break;
            case 'monthly':
                setSelectedMonth(month);
                setSelectedDate(isoDate);
                setSelectedWeek(week);
                setSelectedYear(year);
                break;
            case 'yearly':
                setSelectedYear(year);
                setSelectedDate(isoDate);
                setSelectedWeek(week);
                setSelectedMonth(month);
                break;
            default:
                break;
        }
    };

    const handleButtonClick = (category) => {
        setSelectedItem(category); // Set the selected category
        resetStates(category); // Reset common states

        const currentDate = new Date();
        const isoDate = currentDate.toISOString().split('T')[0];
        const { weekStartDate } = getWeekNumberAndStartDate(currentDate); // Get the current week's start date
        const month = currentDate.toISOString().slice(0, 7);
        const year = currentDate.getFullYear();

        switch (category) {
            case 'daily':
                fetchHoroscopeForDate(isoDate); // Fetch daily
                break;
            case 'weekly':
                fetchHoroscopeForWeeklyDate(weekStartDate); // Fetch weekly
                break;
            case 'monthly':
                fetchHoroscopeForMonthlyDate(month); // Fetch monthly
                break;
            case 'yearly':
                fetchHoroscopeForYearlyDate(year); // Fetch yearly
                break;
            default:
                break;
        }
    };




    useEffect(() => {
        if (!selectedItem) {
            setSelectedItem("daily"); // Set default to "daily"
        }

        switch (hid) {
            case "weekly": {
                const today = new Date(); // Get today's date
                const year = today.getFullYear();
                const week = Math.ceil(
                    (today - new Date(year, 0, 1)) / (7 * 24 * 60 * 60 * 1000)
                );

                // Construct initial week value
                const initialWeekValue = `${year}-W${String(week).padStart(2, '0')}`;
                setSelectedItem("weekly"); // Set weekly selection
                setSelectedWeek(initialWeekValue); // Set the initial week value

                // Fetch the data for the initial week
                fetchHoroscopeForWeeklyDate(
                    moment(initialWeekValue, "YYYY-WWW")
                        .startOf("isoWeek")
                        .format("YYYY-MM-DD")
                );

                // Reset other fields
                setSelectedDate(new Date().toISOString().split('T')[0]); // Reset daily
                setSelectedMonth(new Date().toISOString().slice(0, 7)); // Reset monthly
                setSelectedYear(new Date().getFullYear()); // Reset yearly
                break;
            }
            case "monthly":
                setSelectedItem("monthly");
                break;
            case "yearly":
                setSelectedItem("yearly");
                break;
            default:
                break;
        }
    }, [hid]);


    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{hid ? t("label_daily_horoscope") : t("label_daily_horoscope")}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link horo-heading'>
                <div>
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> - <span className="bc-active-link" onClick={handleGoBack}> {t('label_horo')}</span> -&nbsp;<span className="bc-link">{hid ? t("label_daily_horoscope") : t("label_daily_horoscope")}</span>
                </div>



            </div>
            <div className="bg-details-highlight" style={{ overflowX: 'auto', whiteSpace: "nowrap" }}>
                <div className="details-headertxt-singleLine container">

                    {/* {dataToDisplay && dataToDisplay.title} */}

                    <div class="d-flex justify-content-between details-headertxt-singleLine-btns" >

                        {/* Temples button */}
                        <button
                            type="button"
                            className={`declineButton ${selectedItem === "daily" ? 'selected' : ''}`}
                            onClick={() => handleButtonClick("daily")}
                        >
                            {t("label_daily")}
                        </button>


                        {categoryButtons.map((category, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`declineButton ${selectedItem === category ? 'selected' : ''}`}
                                onClick={() => handleButtonClick(category)}
                            >
                                {t('label_' + category)}

                            </button>
                        ))}
                    </div>

                    <div className="details-headertxt-singleLine-btns">

                        <div className="button-row">

                            <button className="button_move"
                                onClick={
                                    selectedItem === "weekly"
                                        ? handleWeeklyPrevious
                                        : selectedItem === "monthly"
                                            ? handleMonthlyPrevious
                                            : selectedItem === "yearly"
                                                ? handleYearlyPrevious
                                                : handlePrevious
                                }
                            >
                                <span class="web-display">{t("label_previous")}</span>
                                <i class="mob-display fa-solid fa-backward-step"></i>
                            </button>
                            {selectedItem === "weekly" ? (
                                <input
                                    id="week-input"
                                    type="week"
                                    value={selectedWeek}
                                    onChange={onChange}
                                    className="date-input"
                                />
                            ) : selectedItem === "monthly" ? (
                                <input
                                    type="month"
                                    value={selectedMonth}
                                    onChange={onChange}
                                    className="date-input"
                                />
                            ) : selectedItem === "yearly" ? (
                                <select
                                    value={selectedYear}
                                    onChange={(e) => onChange(e)}
                                    className="date-input horoscope-year-input"
                                >
                                    {Array.from({ length: 6 }, (_, index) => 2020 + index).map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>

                            ) : (
                                <input
                                    type="date"
                                    value={selectedDate}
                                    onChange={onChange}
                                    className="date-input"
                                />
                            )}

                            <button className="button_move"
                                onClick={
                                    selectedItem === "weekly"
                                        ? handleWeeklyNext
                                        : selectedItem === "monthly"
                                            ? handleMonthlyNext
                                            : selectedItem === "yearly"
                                                ? handleYearlyNext
                                                : handleNext
                                }
                            >
                                <span class="web-display">{t("label_next")}</span>
                                <i class="mob-display fa-solid fa-forward-step"></i>
                            </button>
                            {(userid === "AD" || userid === "AS") && editable && (
                                <button className="button_move"
                                    onClick={handleSaveClick}

                                ><span class="web-display"> {t("label_save")}</span>
                                    <i class="mob-display fa-solid fa-floppy-disk"></i></button>
                            )}
                            {(userid === "AD" || userid === "AS") ? (

                                <button className="button_move"
                                    onClick={editable ? handleCancelClick : handleEditClick} >
                                    <span class="web-display">{buttonText}</span>
                                    <i class="mob-display fa-solid fa-pen-to-square"></i>
                                </button>
                            ) : ('')}
                            <a
                                className="button_move "
                                type="link"

                                onClick={() => shareResLink(dataToDisplay && dataToDisplay.title,
                                                "Check out this horoscope!",
                                                `horoscopeDetails/${hid}`
                                )}
                            >
                                <i title={t('label_share')} className="fa-solid fa-share-nodes"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="d-flex details-icons-main horo-icons-main">
                <div className="details-icons-main-horoscope ">
                    <div class="zodaic-logo-main zs-bg1" onClick={() => scrollToSection(meshaRef)}>
                        <div class="zs-icon-top zs-aries-icon"></div>
                        <span className='names'>{t("label_mesha")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg2" onClick={() => scrollToSection(vrushabaRef)}>
                        <div class="zs-icon-top zs-tarus-icon"></div>
                        <span className='names'>{t('label_vrushab')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg3" onClick={() => scrollToSection(midhunaRef)}>
                        <div class="zs-icon-top zs-gemini-icon"></div>
                        <span className='names'>{t('label_midhuna')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg4" onClick={() => scrollToSection(karkatakaRef)}>
                        <div class="zs-icon-top zs-cancer-icon"></div>
                        <span className='names'>{t("label_karktaka")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg5" onClick={() => scrollToSection(simhaRef)}>
                        <div class="zs-icon-top zs-leo-icon"></div>
                        <span className='names'>{t('label_simha')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg6" onClick={() => scrollToSection(kanyaRef)}>
                        <div class="zs-icon-top zs-virgo-icon"></div>
                        <span className='names'>{t('label_kanya')}</span>
                    </div>
                </div>
                <div className="details-icons-main-horoscope ">
                    <div class="zodaic-logo-main zs-bg7" onClick={() => scrollToSection(tulaRef)}>
                        <div class="zs-icon-top zs-libra-icon"></div>
                        <span className='names'>{t("label_thula")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg8" onClick={() => scrollToSection(vruschikaRef)}>
                        <div class="zs-icon-top zs-scorpio-icon"></div>
                        <span className='names'>{t('label_vruchika')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg9" onClick={() => scrollToSection(dhanasuRef)}>
                        <div class="zs-icon-top zs-sagittarius-icon"></div>
                        <span className='names'>{t('label_dhanassu')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg10" onClick={() => scrollToSection(makaraRef)}>
                        <div class="zs-icon-top zs-aquarius-icon"></div>
                        <span className='names'>{t("label_makara")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg11" onClick={() => scrollToSection(kumbaRef)}>
                        <div class="zs-icon-top zs-capricorn-icon"></div>
                        <span className='names'>{t('label_kumbha')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg12" onClick={() => scrollToSection(meenaRef)}>
                        <div class="zs-icon-top zs-pisces-icon"></div>
                        <span className='names'>{t('label_meena')}</span>
                    </div>
                </div>
            </div>

            <div class="mainsec-block-horo-detailspage1">
                <div className='container'>
                    <div className="float-left w-100 "><h5 className="mt-4 mb-2">

                    </h5></div>


                    {horLoading ? (
                        <Error />
                    ) : (
                        dataToDisplay ? (

                            <div className='horoscope-item'>


                                <Form form={form}>
                                    <div className="active-details">
                                        <div className="tiles-designs">
                                            <Form.Item name="title">
                                                {editable ? (
                                                    <>
                                                        <Typography.Title level={4}>
                                                            {t("label_title")}</Typography.Title>
                                                        <Input defaultValue={formatBoldText(dataToDisplay.title)} />
                                                    </>
                                                ) : (
                                                    <Typography.Title level={3} className='typ'>{formatBoldText(dataToDisplay.title)}</Typography.Title>
                                                )}
                                            </Form.Item>
                                        </div>
                                        {/* <div className="tiles-designs" ref={dhanasuRef}>
                                            <Form.Item label={<Typography.Title level={4}>{t("label_dhanassu")}</Typography.Title>} name="dhanassu">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.dhanassu} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.dhanassu}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div> */}

                                        <div className="tiles-designs" ref={meshaRef}>

                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_mesha")}</span>
                                                <div class="zs-aries-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="mesha">
                                                {editable ? (

                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.mesha)} style={{ marginTop: '6px' }} className="bold-text-line-popup" >

                                                    </TextArea>
                                                ) : (
                                                    <Typography.Text className='typ'>

                                                        {formatBoldText(dataToDisplay.mesha)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={vrushabaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_vrushab")}</span>
                                                <div class="zs-tarus-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="vrushab">
                                                {editable ? (

                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.vrushab)} style={{ marginTop: '6px' }} className="bold-text-line-popup"/>
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.vrushab)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={midhunaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_midhuna")}</span>
                                                <div class="zs-gemini-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="midhuna">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.midhuna)} style={{ marginTop: '6px' }} className="bold-text-line-popup" />
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.midhuna)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={karkatakaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_karktaka")}</span>
                                                <div class="zs-cancer-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="karkataka">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.karkataka)} style={{ marginTop: '6px' }} className="bold-text-line-popup" />
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.karkataka)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={simhaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_simha")}</span>
                                                <div class="zs-leo-icon-main zs-icon-main"></div></Typography.Title>} name="simha">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.simha)} style={{ marginTop: '6px' }} className="bold-text-line-popup"  />
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.simha)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={kanyaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_kanya")}</span>
                                                <div class="zs-virgo-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="kanya">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.kanya)} style={{ marginTop: '6px' }} className="bold-text-line-popup"  />
                                                ) : (
                                                    <Typography.Text className='typ'>

                                                        {formatBoldText(dataToDisplay.kanya)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>


                                        <div className="tiles-designs" ref={tulaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_thula")}</span>
                                                <div class="zs-libra-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="thula">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.thula)} style={{ marginTop: '6px' }} className="bold-text-line-popup" />
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.thula)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={vruschikaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_vruchika")}</span>
                                                <div class="zs-scorpio-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="vruchika">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.vruchika)} style={{ marginTop: '6px' }} className="bold-text-line-popup" />
                                                ) : (
                                                    <Typography.Text className='typ'>

                                                        {formatBoldText(dataToDisplay.vruchika)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={dhanasuRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_dhanassu")}</span>
                                                <div class="zs-sagittarius-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="dhanassu">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.dhanassu)} style={{ marginTop: '6px' }} className="bold-text-line-popup"  />
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.dhanassu)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={makaraRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_makara")}</span>
                                                <div class="zs-capricorn-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="makara">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.makara)} style={{ marginTop: '6px' }} className="bold-text-line-popup" />
                                                ) : (
                                                    <Typography.Text className='typ'>

                                                        {formatBoldText(dataToDisplay.makara)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>


                                        <div className="tiles-designs" ref={kumbaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_kumbha")}</span>
                                                <div class="zs-aquarius-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="kumbha">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.kumbha)} style={{ marginTop: '6px' }} className="bold-text-line-popup" />
                                                ) : (
                                                    <Typography.Text className='typ'>


                                                        {formatBoldText(dataToDisplay.kumbha)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>

                                        <div className="tiles-designs" ref={meenaRef}>
                                            <Form.Item label={<Typography.Title level={4}>
                                                <span>{t("label_meena")}</span>
                                                <div class="zs-pisces-icon-main zs-icon-main"></div>
                                            </Typography.Title>} name="meena">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={formatBoldText(dataToDisplay.meena)} style={{ marginTop: '6px' }} className="bold-text-line-popup"  />
                                                ) : (
                                                    <Typography.Text className='typ'>

                                                        {formatBoldText(dataToDisplay.meena)}
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item
                                                label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                                name="txt"
                                            >
                                                {editable ? (
                                                    <TextArea
                                                        rows={4}
                                                        defaultValue={formatBoldText(dataToDisplay.txt)}
                                                        style={{ marginTop: '6px' }}
                                                        className="bold-text-line-popup"
                                                    />
                                                ) : (
                                                    <Typography.Text className="typ">
                                                        {formatBoldText(dataToDisplay.txt)}
                                                        <ShowMoreText
                                                            charLimit={100}
                                                            text={formatBoldText(dataToDisplay.txt)}
                                                        />
                                                    </Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item label={<Typography.Title level={4}>

                                                {t("label_author")}</Typography.Title>} name="title">
                                                {editable ? (
                                                    <Input defaultValue={dataToDisplay.author} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.author}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        ) : (
                            <Result
                                status="404"
                                title={t("label_nodata_found")}
                                subTitle={t("label_no_horo_msg")}
                            />
                        ))
                    }
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default DailyRaasiDetails;
