import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Modal, Select, Typography, message } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchServicesByUser } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import cholaTemples from '../../../../assets/images/darshan.jpg';
import { getServicesByUserList, getServicesByUserListError, getServicesByUserListLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';

const { Meta } = Card;

const TempleService = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
const storedLanguage = localStorage.getItem('lng');
    
    const { tid, tname } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);

    const handleChange = (value) => {
        setSelectedLanguage(value);
    };

    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getServicesByUserList(state),
        nLoading: getServicesByUserListLoading(state),
        nError: getServicesByUserListError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchServicesByUser(tid, 0));
    }, [tid, dispatch]);

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    
    const handleLinkClick = () => {
        setOpen(false);
        message.info('Please Login to use additional features');
    };

    const handleGoBack = (event) => {
        event.preventDefault(); // Prevent the default behavior of the link
        navigate(-1); // Navigate to the previous screen
    };

    return (
        <div className="mainsec">
                                                <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_temple_services')}</span>
                </div>
                <div></div>
            </div>
            <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt">
                <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">{t('Home')}</span></Link> - 
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link" onClick={(event) => handleGoBack(event)}>{t('label_tpl_det')}</span>
                </Link> - 
                <span className="bc-link">{t('label_temple_services')}</span>
            </div>
            <div className="mainsec-block">
                <div className="container">
                    <div className="container">
                        <h5 className="card-text-title">{t("label_services")} <span>| {t("label_services_like_msg")}</span></h5>
                    </div>
                    <div className="tiles-main">
                        {nList && nList.length > 0 ? (
                            nList.map((temple, index) => (
                                // Updated link to pass both `servid` and `tname` to ServiceDetails
                                <Link to={`/ServiceDetails/${temple.servid}/${tname}`} key={index}>
                                    <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                        <div className="title-img-bg">
                                            <img className="card-img-temple-cat" alt="temples" src={cholaTemples} />
                                            <div className="hover-links">
                                                <a href="/freedarshan">{temple.servtype}</a>
                                            </div>
                                        </div>
                                        <div className="tile-text-temple-cat">
                                            <span>{temple.ctgry}</span> <span>{("label_temples_list")}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>{t("label_no_service")}</p>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default TempleService;
