import React, { useEffect, useRef, useState } from 'react';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getFilterByStDtTxtError, getFilterByStDtTxtList, getFilterByStDtTxtLoading, getGodNames, getGodNamesError, getGodNamesLoading, getTempleTxtError, getTempleTxtList, getTempleTxtLoading } from '../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByStDtText, fetchFavourites, fetchGodNames, fetchNearest, fetchTempleFavourites, fetchTempleTxt, postFavourites, removeFavourites, retrieveGodNames } from '../../../redux/actions/acions';
import { Link, useParams } from 'react-router-dom';
import { getImageAPI } from '../../../http-common';
import deftemples from '../../../assets/images/templeicon.jpg';
import { Button, Col, Result, Row, message } from 'antd';
import Error from './error';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import TemplesPage from '../../common/TemplesPage';
import Footer from './footer/footer';


let last_rec = 0;
function SearchByTxt() {
    const { t } = useTranslation();
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const records_per_page = 12;
    const [filterId, setFilterId] = useState(null);
    const { txt } = useParams();
    const { st } = useParams();
    const { dt } = useParams();
    const dispatch = useDispatch();
const storedLanguage = localStorage.getItem('lng');
    const [isLoading, setIsLoading] = useState(false);
    const localEnv = false;

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };

    const { txList, txLoading, txError, godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({
        txList: getFilterByStDtTxtList(state),
        txLoading: getFilterByStDtTxtLoading(state),
        txError: getFilterByStDtTxtError(state),
        godCtgryList: getGodNames(state) || [],
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }));

    useEffect(() => {
        dispatch(fetchByStDtText(storedLanguage, st, dt, txt, 0))
    }, [storedLanguage, st, dt, txt]);

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])


    const [messageApi, contextHolder] = message.useMessage();

    const getToken = Boolean(localStorage.getItem('token'));



    function nextTemplesList() {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchByStDtText(storedLanguage, st, dt, txt, last_rec))
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        // god_last_rec = god_last_rec - records_per_page;
        // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
        dispatch(fetchByStDtText(storedLanguage, st, dt, txt, last_rec))
    };

    return (
        <>
            {contextHolder}
            
                {txLoading ? (
                    <p>Loading...</p>
                ) : txError ? (
                    <p>Error: {txError}</p>
                ) : txList && txList.length > 0 ? (
                    <TemplesPage
                        title={txt}
                        description={'This is temple you have searched'}
                        tLoading={txLoading}
                        tList={txList}
                        pageTitle={txt}
                        godCtgryList={godCtgryList}
                        godCtgryListLoading={godCtgryListLoading}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={last_rec}
                    />
                ) : (
                    <Result
                        status="404"
                        title={t("label_nodata_found")}
                        subTitle={t("label_no_fav_temples_msg")}
                    />
                )}
            
        </>
    )



}

export default SearchByTxt;