import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, removeBooksFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import {  message } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import showToast from '../../../../utils/showToast';
import CommonResource from './commonResource';


function Articles() {
const storedLanguage = localStorage.getItem('lng') || 5;
  // const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const records_per_page = 12;
  // const [isLoading, setIsLoading] = useState(false);
  const localEnv = false;
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [messageApi, conarticlesHolder] = message.useMessage();
  const navigate = useNavigate();
  const { uid } = useParams()


  const { aDetails } = useSelector(state => ({
    aDetails: getBooksList(state),
    aLoading: getBooksLoading(state),
    aError: getBooksError(state),
  }))

  useEffect(() => {
    dispatch(fetchBooks(articles, storedLanguage, did, 0))
  }, [storedLanguage, did])

  const { tDetails } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure()) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  const [isGodModalVisible, setIsGodModalVisible] = useState(false);

  const closeModal = () => {
    setIsGodModalVisible(false);
};

useEffect(() => {
  const handlePopState = (event) => {
      if (isGodModalVisible) {
        closeModal();
          event.preventDefault();
      }
  };
  const handleKeyDown = (event) => {
      if (isGodModalVisible && event.key === 'Escape') {
        closeModal();
          event.preventDefault();
      }
  };
  if (isGodModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
  }
  return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
  };
}, [isGodModalVisible, closeModal]);

  const [did, setDid] = useState(0); // Initialize 'did' to 0
  const [filterId, setFilterId] = useState(0);

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // dispatch(fetchBooks(articles, storedLanguage, 0, 0));
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // dispatch(fetchBooks(articles, storedLanguage, gDid, 0));
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }




  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (uid) {
      setSearchText(uid);
      if (uid.length >= 5) {
        dispatch(fetchResourceByTxt(uid)); // Fetch results on load or when uid changes
      }
    }
  }, [uid, dispatch]);


  const success = (title) => {
    showToast('success', `${t('label_added')} ${title} ${t('label_to_favourites')}`);
};
const warning = (title) => {
    showToast('info', `${t('label_removed')} ${title} ${t('label_from_favourites')}`);
};
  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };



  const [filledTemples, setFilledTemples] = useState({});

  const [selectedItem] = useState(null);
  const [articles] = useState('articles');

  const { sList } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtLoading(state),
    sError: getResourceByTxtError(state),
  }));


  // useEffect(() => {
  //   dispatch(fetchResourceByTxt(articles, storedLanguage, searchText, 0));
  // }, [storedLanguage, searchText]);

  const [isLoading, setIsLoading] = useState(true);

  const [getFavouriteTemples] = useState(false);

  const [refresh] = useState(false);
  const { fList } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    {
      getToken && (
        dispatch(fetchBooksFavourites())
      )
    }
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));


  const [currentPage, setCurrentPage] = useState(0);
  const mainSecBlockRef = useRef(null);
  const nextTemplesList = async () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;

      if (filterId !== null && selectedItem === null) {
        const newLastRec = newPage * records_per_page;
        dispatch(fetchBooks(articles, storedLanguage, did, newLastRec));
      }
      else if (selectedItem !== null) {
        const newSelectedLastRec = newPage * records_per_page;
        dispatch(fetchBooksByTag(articles, storedLanguage, filterId, selectedItem, newSelectedLastRec));
      }
      else if (searchText !== null) {
        const newSearchLastRec = newPage * records_per_page;
        dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
      }
      if (mainSecBlockRef.current) {
        mainSecBlockRef.current.scrollTop = 0; // Scroll to the top of the container
      }
      return newPage;
    });
    setIsLoading(false);
  };

  // Previous button handler
  const prevTemplesList = () => {
    setIsLoading(true);
    setCurrentPage(prevPage => {
      if (prevPage > 0) { // Ensure we don't go below the first page
        const newPage = prevPage - 1; // Decrement page

        if (filterId !== null && selectedItem === null) {
          const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
          dispatch(fetchBooks(articles, storedLanguage, did, newLastRec));
        }
        else if (selectedItem !== null) {
          const newSelectedLastRec = newPage * records_per_page; // Previous offset for selected items
          dispatch(fetchBooksByTag(articles, storedLanguage, filterId, selectedItem, newSelectedLastRec));
        }
        else if (searchText !== null) {
          const newSearchLastRec = newPage * records_per_page; // Previous offset for search books
          dispatch(fetchResourceByTxt(storedLanguage, searchText, newSearchLastRec));
        }
        if (mainSecBlockRef.current) {
          mainSecBlockRef.current.scrollTop = 0; // Scroll to the top of the container
        }
        return newPage; // Return updated page number
      }

      return prevPage; // If we're on the first page, don't decrement
    });
    setIsLoading(false);
  };

  return (
    <>
      {conarticlesHolder}
      
      <CommonResource
        title={t('label_articles')}
        description={t('label_about_articles')}
        bDetails={aDetails}
        sList={sList}
        tDetails={tDetails}
        fetchResource={fetchBooks}
        articles={articles}
        text={articles}
        filterTemplesList={filterTemplesList}
        nextTemplesList={nextTemplesList}
        prevTemplesList={prevTemplesList}
        currentPage={currentPage}
        mainSecBlockRef={mainSecBlockRef}
      />


     

    </>
  )
}


export default Articles;