import React, { Component, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { getByStDtError, getByStDtList, getByStDtLoading, getFilterByStDtError, getFilterByStDtList, getFilterByStDtLoading, getGodNames, getGodNamesError, getGodNamesLoading, getLatestTempleDetails, getLatestTempleDetailsError, getLatestTempleDetailsLoading, getNearestError, getNearestList, getNearestLoading, getTempleBooksError, getTempleBooksList, getTempleBooksLoading, getTempleDetails, getTempleDetailsError, getTempleDetailsLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading, getTemplePreviewImgError, getTemplePreviewImgList, getTemplePreviewImgLoading, getTemplesList, getTemplesListError, getTemplesListLoading, gettempleMinDetailsError, gettempleMinDetailsList, gettempleMinDetailsLoading } from "../../../../redux/selectors/selectors";
import { getMediumImageAPI } from "../../../../http-common";
import { retrieveTempleDetails, fetchTempleDetails, postUploadImageRequest, editAvatar, editTempleStatus, editTempleImageStatus, postImage, editTempleFields, fetchNearest, postFavourites, removeFavourites, fetchTempleBooks, fetchTempleFavourites, fetchLatestTempleDetails, deleteTempleImage, editTempleLocation, editTempleHist, editTempleDescr, editArchitecture, editAddInfo, updateTempleImage, fetchByStDt, fetchGodNames, retrieveGodNames, postPriestReq, retrieveTemples, postUserActivity, fetchTempleMinDetails, editTimings, editPujas, editTravel, editGovFlag, editNavagrahasFlag, editTempleSplPujas, editTempleFestivals, editTempleTravel, editAccomodation, fetchTempleReviewImg, updateInactiveTempleImage, editOnHillFlag, editSwayambhuFlag } from '../../../../redux/actions/acions';
import { Form, Carousel, Typography, Row, Col, Upload, Button, message, FloatButton, Modal, Image, Switch, Select, Breadcrumb, Card, Input, Popconfirm, Checkbox, Radio } from 'antd';
import { Link } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.jpg'
import TempleMap from './templeMap';
import { CaretLeftOutlined, CaretRightOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, LeftOutlined, RightOutlined, RocketTwoTone, ShareAltOutlined, UploadOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import deityImages from '../../../common/godImages';
import aum from '../../../../assets/icons/om.jpg'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import Slider from 'react-slick';
import Error from '../error';
import spin from '../../../../assets/images/Spinner.gif'
import Footer from '../footer/footer';
import statesData from '../../states.json';
import { GoogleMap, Marker } from '@react-google-maps/api';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import ResponsiveAd from '../Advertisements/responsiveAd';
import logo from '../../../../assets/images/img.png';

const { Title } = Typography;
var last_rec = 0;
function EditTemples() {
  const [editableStr, setEditableStr] = useState('');
  //const [selectedDeityImage, setSelectedDeityImage] = useState(deityImages[templeDetails.maindeity]);
  const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const [did, setDid] = useState(0);
  const [showMaps, setShowMaps] = useState(false)
  const { t } = useTranslation();
  const [enableModal, setEnableModal] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 1;
  const videoRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem('token');
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDeity, setSelectedDeity] = useState(null);

  const startCamera = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  };

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageSrc = canvas.toDataURL('image/jpeg');
    setPreviewImage(imageSrc);
    video.srcObject.getTracks().forEach((track) => track.stop());
  };

  const deleteImage = () => {
    setPreviewImage(null);
    startCamera();
  };

  const [messageApi, contextHolder] = message.useMessage();
  // const failure = (msg) => {
  //     messageApi.open({
  //         type: 'error',
  //         content: msg,
  //     });
  // };

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const [newImagePath, setNewImagePath] = useState('');

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    newImagePath = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const success = (tname) => {
    showToast('success', () => `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`, { duration: 5 });
  };


  const warning = (tname) => {
    showToast('info', () => `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`, { duration: 5 });
  };

  const failure = () => {
    showToast('error', t(`label_fav_error`))

  };

  const saveSuccess = () => {
    showToast('success', t('label_submitted_data_corr'));
  };


  const saveWarning = (msg) => {
    showToast('error', msg)
  };


  const dispatch = useDispatch()

  const { tid } = useParams();

  const {
    tDetails,
    tLoading,
    tError,
    godCtgryList,
    godCtgryListLoading,
    godCtgryListError, templesList
  } = useSelector(state => ({
    tDetails: getTempleDetails(state),
    tLoading: getTempleDetailsLoading(state),
    tError: getTempleDetailsError(state),
    godCtgryList: getGodNames(state),
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
    templesList: getTemplesList(state) || [],
    templesListLoading: getTemplesListLoading(state),
    templesListError: getTemplesListError(state),
  }));

  // Log tDetails to verify tname is fetched
  ;

  useEffect(() => {
    dispatch(fetchTempleDetails(storedLanguage, tid));
  }, [storedLanguage, tid]);
  const localEnv = false;

  useEffect(() => {
    dispatch(retrieveTemples(storedLanguage, last_rec));
  }, [storedLanguage, last_rec]);

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const { latestDetails, latestLoading, latestError } = useSelector(state => ({
    latestDetails: getLatestTempleDetails(state),
    latestLoading: getLatestTempleDetailsLoading(state),
    latestError: getLatestTempleDetailsError(state)
  }))


  const { nDetails, nLoading, nError } = useSelector((state) => ({
    // nDetails: getNearestList(state),
    // nLoading: getNearestLoading(state),
    // nError: getNearestError(state),
    nDetails: getFilterByStDtList(state),
    nLoading: getFilterByStDtLoading(state),
    nError: getFilterByStDtError(state),
  }));

  const { templeMinDetails1, templeMinLoading, templeMinError } = useSelector(state => ({
    templeMinDetails1: gettempleMinDetailsList(state),
    templeMinLoading: gettempleMinDetailsLoading(state),
    templeMinError: gettempleMinDetailsError(state)
  }))

  const pincodeRegex = /^[0-9]{6}$/;

  const validatePincode = (_, value) => {
    if (value && (!/^\d*$/.test(value) || value.length > 6)) {
      return Promise.reject(new Error('Please enter a valid pincode! It must be exactly 6 digits.'));
    }
    if (value && !pincodeRegex.test(value)) {
      return Promise.reject(new Error('Pincode must be exactly 6 digits.'));
    }
    return Promise.resolve();
  };


  const phoneRegex = /^[0-9]{1,10}$/;

  const validatePhoneNumber = (_, value) => {
    if (value && !phoneRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
    }
    return Promise.resolve();
  };

  const floatRegex = /^-?\d+(\.\d+)?$/;

  const validateLatitudeLongitude = (_, value) => {
    if (value && !floatRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid float value for latitude or longitude.'));
    }
    return Promise.resolve();
  };


  // useEffect(() => {

  //     // Check if tDetails is available and has latit and longi properties
  //     if (tDetails && tDetails.latit && tDetails.longi) {

  //         dispatch(fetchNearest(storedLanguage, tDetails.latit, tDetails.longi, 0));
  //     }

  // }, [activeSection, storedLanguage, tDetails]);

  useEffect(() => {
    if (tDetails && tDetails.st && tDetails.city) {
      dispatch(fetchByStDt(storedLanguage, tDetails && tDetails.st, tDetails && tDetails.city, 0));
    }
  }, [storedLanguage, tDetails && tDetails.st, tDetails && tDetails.city, 0]);

  const { rDetails, rLoading, rError } = useSelector(state => ({
    rDetails: getTempleBooksList(state),
    rLoading: getTempleBooksLoading(state),
    rError: getTempleBooksError(state)
  }))


  useEffect(() => {
    dispatch(fetchTempleBooks(storedLanguage, tid, did))
  }, [activeLib, storedLanguage])

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getTempleFavouritesList(state),
    fLoading: getTempleFavouritesLoading(state),
    fError: getTempleFavouritesError(state),
  }));


  useEffect(() => {
    if (getToken) {
      dispatch(fetchTempleFavourites());
    }
  }, [getToken]);


  function addFavourites(id) {
    dispatch(postFavourites(id))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeFavourites(deleteId))
  }


  const [filled, setFilled] = useState({});
  let id = templeDetails && templeDetails.tid


  const [favList, setFavList] = useState([]);

  // useEffect(() => {
  //     if (fList) {
  //         setFavList(fList.fav_list);
  //     }
  // }, [fList]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);


  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(templeDetails) {
    if (getToken) {
      const templeId = templeDetails.tid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, templeDetails.tname);
        warning(templeDetails.tname);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, templeDetails.tname);
        success(templeDetails.tname);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }

  function toggleFilled(id) {
    setFilled(prevFilled => ({
      ...prevFilled,
      [id]: !prevFilled[id]
    }));
  }

  const templeDetails = (tLoading || tDetails == null) ? {} : tDetails
  const latestTempleDetails = (latestLoading || latestDetails == null) ? {} : latestDetails
  const templeMinDetails = (templeMinLoading || templeMinDetails1 == null) ? {} : templeMinDetails1


  function showMapView() {
    setShowMaps(true)
  }

  function submitForm(values) {
    setPreviewImage(false);
  }

  const uploadNewImage = () => {
    console.warn('inside UploadNewImage:' + fileList.length);
    if (fileList.length > 0) {
      console.warn('filelist' + fileList[0])
      dispatch(postImage(tid, fileList[0]))
    }
  };


  const [showAllImages, setShowAllImages] = useState(false);

  const handleImageClick = () => {
    // if (enableModal) {
    setShowAllImages(true);
    // }
  };


  const avatarSuccess = () => {
    showToast('success', t('label_edit_avatar_sucess'))

  };

  const handleDeityImageClick = (deity) => {
    const updatedTempleDetails = { ...templeDetails, deityname: deity };
    dispatch(editAvatar(tid, { avatar: deity }, avatarSuccess()));
    setShowAllImages(false);
    setSelectedDeity(deity);
  };


  const handleCloseModal = () => {
    setShowAllImages(false);
  };

  const [currentImage, setCurrentImage] = useState(
    selectedDeity ? deityImages[selectedDeity] : (templeDetails && deityImages[templeDetails.avatar])
  );


  useEffect(() => {
    if (selectedDeity) {
      setCurrentImage(deityImages[selectedDeity]);
    } else if (templeDetails && templeDetails.avatar) {
      setCurrentImage(deityImages[templeDetails.avatar]);
    }
  }, [selectedDeity, templeDetails]);


  const userid = localStorage.getItem('urole')
  const [isTempleDeactivated, setIsTempleDeactivated] = useState(templeDetails.status === 0);



  const templeStatusSuccess = (tname) => {
    if (templeDetails.status === 1) {
      showToast('success', t(`Deactivated ${tname}`));
      setIsTempleDeactivated(true);  // Set deactivated status
    } else {
      showToast('success', t(`Activated ${tname}`));
      setIsTempleDeactivated(false); // Set activated status
    }

  };

  const templeStatusFailure = (msg) => {
    showToast('error', t(msg))

  };

  const handleSelectChange = () => {
    // Toggle between 0 and 1 for activating and deactivating
    const value = templeDetails.status === 1 ? 0 : 1;
    dispatch(editTempleStatus(storedLanguage, tid, { status: value }, templeStatusSuccess, templeStatusFailure));
    //window.location.reload();   
  };


  const handleChange = (checked) => {
    const value = checked ? true : false;
    dispatch(editTempleImageStatus(tid, { status: `${value}` }));
  };

  const [showCameraModal, setShowCameraModal] = useState(false);

  const openCameraModal = () => {
    setShowCameraModal(true);
    // startCamera();
  };

  const handleCameraModalOk = () => {
    setShowCameraModal(false);
    captureImage();
  };

  const handleCameraModalCancel = () => {
    setShowCameraModal(false);
    deleteImage();
  };

  const props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.warn(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  const onFinish = (imgid) => {
    const data = {
      'active': false
    };
    dispatch(deleteTempleImage(templeDetails.tid, imgid, data));
    showToast('success', t(`Image Deleted Successfully`));
  };

  const [selectedImgPath, setSelectedImgPath] = useState(templeDetails.imgpath);

  useEffect(() => {
    setSelectedImgPath(templeDetails.imgpath);
  }, [templeDetails.imgpath]);

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedImgPath(templeDetails.imgpath);
    } else {
      setSelectedImgPath(''); // You can choose to reset or keep the last selected image path
    }
  };

  const handleActivateClick = (imgid) => {
    const data = {
      'active': true
    };
    dispatch(updateInactiveTempleImage(templeDetails.tid, imgid, data));
    showToast('success', t(`Successfully Activated, Please Check in Temple Details!`));
  };

  // const handleActivateClick = (imgid) => {
  //   // Ensure imgid is a primitive value (string/number)
  //   if (typeof imgid !== 'string' && typeof imgid !== 'number') {
  //     console.error('Invalid imgid:', imgid);
  //     return; // Exit if imgid is invalid
  //   }

  //   const newState = !activatedImages[imgid];
  //   setActivatedImages({
  //     ...activatedImages,
  //     [imgid]: newState,
  //   });

  //   if (newState) {
  //     updateImageStatus(imgid); // Pass imgid correctly
  //   }
  // };

  // const updateImageStatus = (imgid) => {
  //   // Ensure imgid is still valid before sending it in the API call
  //   if (typeof imgid !== 'string' && typeof imgid !== 'number') {
  //     console.error('Invalid imgid in updateImageStatus:', imgid);
  //     return;
  //   }

  //   const data = {
  //     active: true,
  //   };

  //   // Call the API with the correct imgid
  //   dispatch(updateInactiveTempleImage(tid, { imgid, ...data }, () => {
  //     console.log('Image activated successfully');
  //   }));
  // };


  const updateSucess = () => {
    showToast('sucess', t(`label_image_update`))

  };

  const updateImageFinish = (imgid) => {
    const data = {
      'imgpath': imgid
    };
    dispatch(updateTempleImage(tid, data, updateSucess));
  };

  const [activeButton, setActiveButton] = useState('Details'); // Initialize with the default active button
  const [activeSection, setActiveSection] = useState('Details');
  const [activeLibrary, setActiveLibrary] = useState('Video');
  const [activeLib, setActiveLib] = useState('Video');
  const [formData, setFormData] = useState({
    "festivals": templeDetails.festivals || '',
    "splpujas": templeDetails.splpujas || '',
    "timings": templeDetails.timings || '',
    "howtoreach": templeDetails.howtoreach || '',
    "priestname": templeDetails.priestname || '',
    "priestphoneno": templeDetails.priestphoneno || '',
    "website": templeDetails.website || '',
    "email": templeDetails.email || '',
    "phoneno": templeDetails.phoneno || '',
    "inagurated": templeDetails.inagurated || '',
    'deityname': templeDetails.deityname || '',
    'otherdeities': templeDetails.otherdeities || '',
    'dailypujas': templeDetails.dailypujas || '',
    'accomodation': templeDetails.accomodation || '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   form.setFieldsValue(tDetails);
  // }, [tDetails]);

  const handleButtonClick = (buttonName) => {
    if (activeButton === buttonName) {
      setActiveButton(null); // Deactivate the button if it's already active
    } else {
      setActiveButton(buttonName); // Activate the button if it's not already active
    }
  };

  const [currentLocation, setCurrentLocation] = useState(null);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [lat, setLat] = useState(null); // Latitude state variable
  const [lon, setLon] = useState(null); // Longitude state variable
  const [showMap, setShowMap] = useState(false);
  const [error, setError] = useState(false);
  const [mapKey, setMapKey] = useState(1);
  const [isRightClick, setIsRightClick] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const [markerVisible, setMarkerVisible] = useState(false);
  const indiaCenter = { lat: 20.5937, lng: 78.9629 };
  const [descr, setDescr] = useState(null);
  const [addtl_info, setAddInfo] = useState(null);
  const [architecture, setArchitecture] = useState(null);
  // const [timings, setTimigs] = useState(null);
  const [dailypujas, setDailypujas] = useState(null);
  const [festivals, setFestivalDetails] = useState(null);
  const [accommodation, setAccomodationDetails] = useState(null);
  const [splpujas, setSplpujas] = useState(null);
  const [navagrahas, setNavagrahas] = useState(templeDetails.navagrahas || false);
  const [gov, setGov] = useState(templeDetails.gov || false);
  const [onhill, setonHill] = useState(templeDetails.onhill || false);
  const [swayambu, setSwayambu] = useState(templeDetails.swayambu || false);
  const [markerPosition, setMarkerPosition] = useState(null);

  const customButtonStyle = {
    marginBottom: '10px',
    marginRight: '20px'
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          setCurrentLocation({
            lat: latitude,
            lng: longitude,
          });
          setLat(latitude);
          setLon(longitude);

          // Update the form fields for latitude and longitude
          formRef.current.setFieldsValue({
            lat: latitude.toFixed(6),
            lon: longitude.toFixed(6),
          });

          setShowMap(true);
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  };

  const handleGetLatLonFromMap = () => {
    if (currentLocation) {
      // Get and format latitude and longitude
      const latitude = currentLocation.lat.toFixed(6);
      const longitude = currentLocation.lng.toFixed(6);

      // Update lat and lon state
      setLat(latitude);
      setLon(longitude);

      // Update the form fields for latitude and longitude
      formRef.current.setFieldsValue({
        lat: latitude,
        lon: longitude,
      });

      // Prepare data for API call
      const updatedTempleDetails = {
        latit: latitude,
        longi: longitude
      };

      // Call the API to save location
      dispatch(editTempleLocation(templeDetails.tid, updatedTempleDetails, locationSuccess, locationFailure));

      // Close the map popup
      setShowMap(false);
      // setCurrentLocation(null);
    }
  };

  useEffect(() => {
    if (templeDetails.latit && templeDetails.longi) {
      const location = { lat: templeDetails.latit, lng: templeDetails.longi };
      setCurrentLocation(location);
      setMarkerPosition(location); // Ensure this state exists
    }
  }, [templeDetails.latit, templeDetails.longi]);


  const handleMarkerDragEnd = (e) => {
    const updatedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setCurrentLocation(updatedLocation); // Update map center
    setMarkerPosition(updatedLocation); // Update marker position

    setLat(updatedLocation.lat.toFixed(6));
    setLon(updatedLocation.lng.toFixed(6));
  };



  const handleLocationButtonClick = () => {
    setShowMap(!showMap);
    setMarkerVisible(true);
    setLocationSelected(false);
  };

  //   useEffect(() => {
  //     const handlePopState = (event) => {
  //         if (locationSelected) {
  //           handleLocationButtonClick();
  //             event.preventDefault();
  //         }
  //     };
  //     const handleKeyDown = (event) => {
  //         if (locationSelected && event.key === 'Escape') {
  //           handleLocationButtonClick();
  //             event.preventDefault();
  //         }
  //     };
  //     if (locationSelected) {
  //         // Add history entry and listeners
  //         window.history.pushState({ popupOpen: true }, '');
  //         window.addEventListener('popstate', handlePopState);
  //         window.addEventListener('keydown', handleKeyDown);
  //     }
  //     return () => {
  //         // Cleanup listeners when popup closes or component unmounts
  //         window.removeEventListener('popstate', handlePopState);
  //         window.removeEventListener('keydown', handleKeyDown);
  //     };
  // }, [locationSelected, handleLocationButtonClick]);

  const locationSuccess = () => {
    showToast('success', t('error_msg_61'))

  };

  const locationFailure = (msg) => {
    showToast('error', t(msg))

  };

  const previousLat = useRef(templeDetails.lat);
  const previousLon = useRef(templeDetails.lon);

  const handleSaveLocation = () => {
    const values = formRef.current.getFieldsValue();

    const currentLat = parseFloat(values.lat);
    const currentLon = parseFloat(values.lon);

    // Check if lat or lon has changed
    const latChanged = previousLat.current !== currentLat;
    const lonChanged = previousLon.current !== currentLon;

    if (latChanged || lonChanged) {
      const dataToSend = {
        "latit": currentLat,
        "longi": currentLon,
      };

      dispatch(editTempleLocation(templeDetails.tid, dataToSend, locationSuccess, locationFailure));

      // Update previous values to the current ones after the API call
      previousLat.current = currentLat;
      previousLon.current = currentLon;
    }
  };

  const { templePreviewImg, templePreviewImgLoading, templePreviewImgError } = useSelector(state => ({
    templePreviewImg: getTemplePreviewImgList(state),
    templePreviewImgLoading: getTemplePreviewImgLoading(state),
    templePreviewImgError: getTemplePreviewImgError(state)
  }))

  useEffect(() => {
    if (userid === 'AD' || userid === 'AS') {
      dispatch(fetchTempleReviewImg(tid))
    }
  }, [tid])


  const hasData = (templeDetails) => {
    return Object.keys(templeDetails).some(key => templeDetails[key]);
  };


  const histSuccess = () => {
    showToast('success', t('label_contribute_hist1'))

  };

  const histFailure = (msg) => {
    showToast('error', t(msg))

  };

  const splPujasSuccess = () => {
    showToast('success', t('label_splpujas_success'))

  };

  const splPujasFailure = (msg) => {
    showToast('error', t(msg))

  };

  const festivalSuccess = () => {
    showToast('success', t('label_festivaldetails_success'))

  };

  const festivalFailure = (msg) => {
    showToast('error', t(msg))

  };

  const travelSuccess = () => {
    showToast('success', t('label_travel_success'))

  };

  const travelFailure = (msg) => {
    showToast('error', t(msg))

  };

  const dailypujasSuccess = () => {
    showToast('success', t('label_dailypujas_success'))

  };

  const dailypujasFailure = (msg) => {
    showToast('error', t(msg))

  };

  const architechtureSuccess = () => {
    showToast('success', t('label_architecture_success'))

  };

  const architechtureFailure = (msg) => {
    showToast('error', t(msg))

  };

  const accomodationSuccess = () => {
    showToast('success', t('label_accomodation_success'))

  };

  const accomodationFailure = (msg) => {
    showToast('error', t(msg))

  };

  const descrSuccess = () => {
    showToast('success', t('label_contribute_descr1'))

  };

  const descrFailure = (msg) => {
    showToast('error', t(msg))

  };

  const archSuccess = () => {
    showToast('success', t('label_edit_descr_sucess'))

  };

  const archFailure = (msg) => {
    showToast('error', t(msg))

  };

  const addInfoSuccess = () => {
    showToast('success', t('label_edit_descr_sucess'))

  };

  const addInfoFailure = (msg) => {
    showToast('error', t(msg))

  };

  const handleDescr = (e) => {
    setDescr(e.target.value)
    //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
  };

  const updateDescr = () => {
    if (userid === 'AD' || userid === 'AS') {
      dispatch(editTempleDescr(storedLanguage, templeDetails.tid, { descr: descr }, descrSuccess, descrFailure));
    }
    else {
      dispatch(editTempleDescr(storedLanguage, templeDetails.tid, { draft_descr: descr }, descrSuccess, descrFailure));
    }
  };


  const handleHist = (e) => {
    setHistory(e.target.value)
    //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
  };

  const handleTimings = (e) => {
    setTimings(e.target.value)
    //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
  };

  const updateTimings = () => {
    dispatch(editTimings(storedLanguage, templeDetails.tid, { timings: timings }, archSuccess, archFailure));
    setIsTimingsChanged(false);
  };

  const updateHist = () => {
    if (userid === 'AD' || userid === 'AS') {
      dispatch(editTempleHist(storedLanguage, templeDetails.tid, { history: history }, histSuccess, histFailure));
    }
    else {
      dispatch(editTempleHist(storedLanguage, templeDetails.tid, { draft_history: history }, histSuccess, histFailure));
    }
  };
  const [timings, setTimings] = useState(templeDetails.timings || '');
  const [history, setHistory] = useState(templeDetails.history || '');
  const [draftHistory, setDraftHistory] = useState(templeDetails.draft_history || '');

  const [description, setDescription] = useState(templeDetails.descr || '');
  const [draftDescription, setDraftDescription] = useState(templeDetails.draft_descr || '');

  const handleToggleHistory = () => {
    setHistory(prevHistory => {
      if (draftHistory) {
        // Append draftHistory if prevHistory has text, otherwise replace it
        return prevHistory ? `${prevHistory}\n${draftHistory}` : draftHistory;
      }
      return prevHistory; // No change if no draftHistory
    });
    setDraftHistory(''); // Clear draft history after appending/replacing
  };


  const handleToggleDescription = () => {
    setDescription(prevDescription => {
      if (draftDescription) {
        // Append or replace based on whether prevDescription has content
        return prevDescription ? `${prevDescription}\n${draftDescription}` : draftDescription;
      }
      return prevDescription; // No change if draftDescription is empty
    });
    setDraftDescription(''); // Clear draft description after applying it
  };


  const clearHistory = () => {
    setHistory('');
  };

  const clearDescription = () => {
    setDescription('');
  };

  const isHistoryUpdated = history !== '' || draftHistory !== '';
  const isDescriptionUpdated = description !== '' || draftDescription !== '';



  const [selectedCorrection, setSelectedCorrection] = useState(null);

  const correctionOptions = [
    { label: t('label_data_incorrect'), value: 'incorrect' },
    { label: t('label_duplicate_data'), value: 'duplicate' },
    { label: t('label_missing_info'), value: 'missing' },
    { label: t('label_other'), value: 'other' }
  ];

  const handleRadioChange = (e) => {
    setSelectedCorrection(selectedCorrection === e.target.value ? null : e.target.value);
  };


  const dataSuccess = () => {
    showToast('success', t('label_submitted_data_corr'));
    handleCorrectionCancel();  // Close popup after successful submission
  };


  const dataFailure = (msg) => {
    showToast('error', msg)
  };

  const handleCorrection = () => {
    const values = form.getFieldsValue();  // Bypass form validation temporarily
    const data = {
      "pid": tid,
      "ctgry": 'temple',
      "reqtype": selectedCorrection || '',
      "query": values.query || ''
    };
    dispatch(postPriestReq(data, dataSuccess, dataFailure));
  };


  const location = useLocation();
  const pageName = location.pathname;

  const openMap = () => {
    const data = {
      page: pageName,
      action: 'Click',
      message: `Visited ${pageName}`,
    };

    dispatch(postUserActivity(data));
  };

  const shareResLink = (title, txt, url) => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: txt,
        url: 'https://www.templeswiki.com/share/' + url
      })
        .then(() => console.warn("Share successful."))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      console.error("Web Share API not supported.");
      window.flutter_inappwebview.callHandler('shareResLink', title, txt, url);
    }
  };

  const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
  const [currentTempleIndex, setCurrentTempleIndex] = useState(0);
  const storedTemplesList = JSON.parse(localStorage.getItem('templesList')) || [];
  const storedTid = localStorage.getItem('currentTempleTid') || tid; // Fallback to `tid` if not in storage

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search);
    if (queryParam.get('showPopup') === 'true') {
      setModalPreviewVisible(true);
    }
  }, [window.location.search]);

  const openPreviewModal = () => {
    const currentTid = localStorage.getItem('currentTempleTid') || (storedTemplesList.length > 0 && currentTempleIndex < storedTemplesList.length ? storedTemplesList[currentTempleIndex].tid : null);

    if (currentTid) {
      const newIndex = storedTemplesList.findIndex(temple => temple.tid === parseInt(currentTid));
      if (newIndex !== -1) {
        setCurrentTempleIndex(newIndex); // Set the index based on currentTid
      }
      setModalPreviewVisible(true);
      dispatch(fetchTempleMinDetails(storedLanguage, currentTid));
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (modalPreviewVisible) {
        closePreviewModal();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (modalPreviewVisible && event.key === 'Escape') {
        closePreviewModal();
        event.preventDefault();
      }
    };
    if (modalPreviewVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalPreviewVisible, closePreviewModal]);



  const closePreviewModal = () => {
    setModalPreviewVisible(false);
    const queryParam = new URLSearchParams(window.location.search);
    queryParam.delete('showPopup');
    window.history.replaceState(null, '', `${window.location.pathname}?${queryParam.toString()}`);
  };

  // Ensure templesList is set in local storage when it changes
  useEffect(() => {
    if (templesList.length > 0) {
      localStorage.setItem('templesList', JSON.stringify(templesList));
    }
  }, [templesList]);


  // Fetch temple details whenever tid changes
  useEffect(() => {
    if (tid) {
      dispatch(fetchTempleMinDetails(storedLanguage, tid));
      localStorage.setItem('currentTempleTid', tid, success);
    }
  }, [tid, storedLanguage]);

  // Update current temple index based on tid
  useEffect(() => {
    if (tid && storedTemplesList.length > 0) {
      const newIndex = storedTemplesList.findIndex((temple) => temple.tid === parseInt(tid));
      if (newIndex !== -1) {
        setCurrentTempleIndex(newIndex);
      }
    }
  }, [tid, storedTemplesList]);

  const currentTid = localStorage.getItem('currentTempleTid') || (storedTemplesList.length > 0 ? storedTemplesList[0].tid : null);

  const handleNext = () => {
    if (storedTemplesList.length > 0) {
      setLoading(true);
      const currentIndex = storedTemplesList.findIndex(temple => temple.tid === parseInt(currentTid));
      const newIndex = (currentIndex + 1) % storedTemplesList.length;
      const nextTemple = storedTemplesList[newIndex];

      setCurrentTempleIndex(newIndex);
      localStorage.setItem('currentTempleTid', nextTemple.tid);

      // Update the URL and fetch details for the next temple
      window.history.replaceState(null, '', `/templeDetails/${nextTemple.tid}`);
      dispatch(fetchTempleMinDetails(storedLanguage, nextTemple.tid));
      setLoading(true);
      setLoading(false);
      // Keep the popup open
      setModalPreviewVisible(true);
    }
  };

  // Handle Previous Temple
  const handlePrevious = () => {
    if (storedTemplesList.length > 0) {
      setLoading(true);
      const currentIndex = storedTemplesList.findIndex(temple => temple.tid === parseInt(currentTid));
      const newIndex = (currentIndex - 1 + storedTemplesList.length) % storedTemplesList.length; // Wrap around if needed
      const prevTemple = storedTemplesList[newIndex];

      setCurrentTempleIndex(newIndex);
      localStorage.setItem('currentTempleTid', prevTemple.tid);

      // Update the URL and fetch details for the previous temple
      window.history.replaceState(null, '', `/templeDetails/${prevTemple.tid}`);
      dispatch(fetchTempleMinDetails(storedLanguage, prevTemple.tid));
      setLoading(false);
      // Keep the popup open
      setModalPreviewVisible(true);
    }
  };

  const handleShowDetails = () => {
    const currentTid = localStorage.getItem('currentTempleTid') || storedTid;
    const currentTemple = storedTemplesList[currentTempleIndex];

    if (currentTemple) {
      window.history.replaceState(null, '', `/templeDetails/${currentTid}`);
      dispatch(fetchTempleDetails(storedLanguage, currentTid));
      setModalPreviewVisible(false);
    }
  };

  const currentPath = window.location.pathname;
  const mainSecBlockRef = useRef(null);
  const handleScrollToTop = () => {
    if (mainSecBlockRef.current) {
      // Scroll the referenced container to the top
      mainSecBlockRef.current.scrollTo({
        top: 0,
        behavior: 'smooth' // Adds smooth scrolling effect
      });
    }
  };


  const reset = () => {
    form.resetFields()
  };



  const [isArchitectureChanged, setIsArchitectureChanged] = useState(false);
  const [isAddInfoChanged, setIsAddInfoChanged] = useState(false);
  const [isTimingsChanged, setIsTimingsChanged] = useState(false);
  const [isPujasChanged, setIsPujasChanged] = useState(false);
  const [isSplPujasChanged, setIsSplPujasChanged] = useState(false);
  const [isTravelChanged, setIsTravelChanged] = useState(false);
  const [isGovChanged, setIsGovChanged] = useState(false);
  const [isNavagrahasChanged, setIsNavagrahasChanged] = useState(false);
  const [isOnHillChanged, setIsonHillChanged] = useState(false);
  const [isSwayambhuChanged, setIsSwayambhuChanged] = useState(false);

  // Handle input c
  const handleArchitectureChange = (e) => {
    setArchitecture(e.target.value);
  };

  const handleAddInfoChange = (e) => {
    setAddInfo(e.target.value);
    setIsAddInfoChanged(true); // Set change flag
  };

  const updateFields = () => {
    // Only update architecture if it has changed
    if (isArchitectureChanged && architecture !== templeDetails.architecture) {
      dispatch(editArchitecture(storedLanguage, templeDetails.tid, { architecture: architecture }, archSuccess, archFailure));
      setIsArchitectureChanged(false); // Reset change flag
    }

    // Only update additional information if it has changed
    if (isAddInfoChanged && addtl_info !== templeDetails.addtl_info) {
      dispatch(editAddInfo(storedLanguage, templeDetails.tid, { addtl_info: addtl_info }, addInfoSuccess, addInfoFailure));
      setIsAddInfoChanged(false); // Reset change flag
    }
  };

  const handleTimingsChange = (e) => {
    setTimings(e.target.value);
    setIsTimingsChanged(true); // Set change flag
  };

  const handleSpltyPujasChange = (e) => {
    setSplpujas(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };

  const handleDailyPujasChange = (e) => {
    setDailypujas(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };


  const handleFestivalDetailsChange = (e) => {
    setFestivalDetails(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };

  const handleHowToReachChange = (e) => {
    setHowToReach(e.target.value);
  };


  const handleDescrChange = (e) => {
    setDescr(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };

  const handleHistoryChange = (e) => {
    setHistory(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };

  const handleAddtnlInfoChange = (e) => {
    setAddInfo(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };


  const handleAccomodationChange = (e) => {
    setAccomodationDetails(e.target.value);
    // setIsPujasChanged(true); // Set change flag
  };

  const updateDailyPujas = () => {
    dispatch(editPujas(storedLanguage, templeDetails.tid, { dailypujas: dailypujas }, dailypujasSuccess, dailypujasFailure));
  };

  const updateSplPujas = () => {
    dispatch(editTempleSplPujas(storedLanguage, templeDetails.tid, { splpujas: splpujas }, splPujasSuccess, splPujasFailure));
  };

  const updateFestivals = () => {
    dispatch(editTempleFestivals(storedLanguage, templeDetails.tid, { festivals: festivals }, festivalSuccess, festivalFailure));
  };

  const updateHowToReach = () => {
    dispatch(editTravel(storedLanguage, templeDetails.tid, { howtoreach: howtoreach }, travelSuccess, travelFailure));
    setHowToReach(howToReach);
    setIsHowToReachModalVisible(false)
  };

  const updateAccomodation = () => {
    dispatch(editAccomodation(storedLanguage, templeDetails.tid, { accommodation: accommodation }, accomodationSuccess, accomodationFailure));
  };

  const updateArchitecture = () => {
    dispatch(editArchitecture(storedLanguage, templeDetails.tid, { architecture: architecture }, architechtureSuccess, architechtureFailure));
  };

  const updateAddtnlDetails = () => {
    dispatch(editAddInfo(storedLanguage, templeDetails.tid, { addtl_info: addtl_info }, architechtureSuccess, architechtureFailure));
  };

  const updateHistory = () => {
    dispatch(editTempleHist(storedLanguage, templeDetails.tid, { history: history }, histSuccess, histFailure));
  };



  const handleGovFlagChange = (e) => {
    setGov(e.target.value);
    setIsGovChanged(true); // Set change flag
  };

  const handleNavagrahasFlagChange = (e) => {
    setNavagrahas(e.target.value);
    setIsNavagrahasChanged(true); // Set change flag
  };

  const updateOtherFields = () => {
    if (isTimingsChanged && timings !== templeDetails.timings) {
      dispatch(editTimings(storedLanguage, templeDetails.tid, { timings }, archSuccess, archFailure));
      setIsTimingsChanged(false);
    }
    if (isPujasChanged && (dailypujas !== templeDetails.dailypujas || splpujas !== templeDetails.splpujas)) {
      dispatch(editPujas(storedLanguage, templeDetails.tid, { dailypujas, splpujas }, addInfoSuccess, addInfoFailure));
      setIsPujasChanged(false);
    }
    if (isTravelChanged && howtoreach !== templeDetails.howtoreach) {
      dispatch(editTravel(storedLanguage, templeDetails.tid, { howtoreach }, archSuccess, archFailure));
      setIsTravelChanged(false);
    }
    if (isGovChanged && gov !== templeDetails.gov) {
      dispatch(editGovFlag(storedLanguage, templeDetails.tid, { gov: gov }, archSuccess, archFailure));
      setIsGovChanged(false);
    }
    if (isNavagrahasChanged && navagrahas !== templeDetails.navagrahas) {
      dispatch(editNavagrahasFlag(storedLanguage, templeDetails.tid, { navagrahas: navagrahas }, archSuccess, archFailure));
      setIsNavagrahasChanged(false);
    }
    if (isOnHillChanged && onhill !== templeDetails.onhill) {
      dispatch(editOnHillFlag(storedLanguage, templeDetails.tid, { onhill: onhill }, archSuccess, archFailure));
      setIsonHillChanged(false);
    }
    if (isSwayambhuChanged && swayambu !== templeDetails.swayambu) {
      dispatch(editSwayambhuFlag(storedLanguage, templeDetails.tid, { swayambu: swayambu }, archSuccess, archFailure));
      setIsSwayambhuChanged(false);
    }
  };

  const handleRadioChange1 = (field, value) => {
    switch (field) {
      case 'navagrahas':
        setNavagrahas(value);
        setIsNavagrahasChanged(true);
        break;
      case 'gov':
        setGov(value);
        setIsGovChanged(true);
        break;
      case 'onhill':
        setonHill(value);
        setIsonHillChanged(true);
        break;
      case 'swayambu':
        setSwayambu(value);
        setIsSwayambhuChanged(true);
        break;
      default:
        break;

    }

    // Trigger API call after updating the state
    setTimeout(updateOtherFields, 0); // Ensure state update completes before calling the function
  };




  // const [activeButton, setActiveButton] = useState('Details');
  const [form] = Form.useForm();
  const [editable, setEditable] = useState(false);
  const [buttonText, setButtonText] = useState(t("edit"));

  useEffect(() => {
    setButtonText(editable ? t("label_cancel") : t("edit"));
  }, [storedLanguage, editable]);

  const handleCancelClick = () => {
    // Reset the form to its initial values
    // form.resetFields();
    // setButtonText(t("edit"));
    // setEditable(false);
    navigate(-1)
  };

  const handleEditClick = () => {
    if (!editable) {
      setButtonText(t("label_cancel"));
      // Populate the input field with the current value when entering edit mode
      setFormData({ ...formData, deityname: templeDetails.deityname });
      setFormData({ ...formData, otherdeities: templeDetails.otherdeities });
    } else {
      setButtonText(t("edit"));
    }
    setEditable(!editable);
  };

  // Function to handle the "Save" button click
  // const handleSaveClick = () => {
  //     form.validateFields().then((values) => {
  //         dispatch(editTempleFields(storedLanguage, tid, templeDetails.ludt, values, saveSuccess, saveWarning))
  //         // dispatch(fetchLatestTempleDetails(storedLanguage, tid))
  //         setEditable(false);
  //         setButtonText(t("edit"));
  //     });
  // };

  const formRef = useRef(null);

  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleFormSubmit = (values) => {
    // Handle the form submission logic
    const { lat, lon, addtl_info, architecture, ...restValues } = values;
    dispatch(editTempleFields(storedLanguage, tid, templeDetails.ludt, restValues, saveSuccess, saveWarning));
    setEditable(false);
    setButtonText(t("edit"));
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleLibraryChange = (library) => {
    setActiveLibrary(library);
    setActiveSection('Library');
  };

  const navigate = useNavigate();
  // Extract the current pathname
  const [previousPageName, setPreviousPageName] = useState('');


  useEffect(() => {
    const previousLocation = new URLSearchParams(window.location.search).get('prevPage');
    setPreviousPageName(getPreviousPageName(previousLocation));
  }, []);


  const getPreviousPageName = (previousLocation) => {
    if (!previousLocation) {
      return 'Unknown Page';
    }

    const paths = previousLocation.split('/').filter((path) => path !== '');
    const lastPath = paths[paths.length - 1] || '/';

    switch (lastPath) {
      case 'nearby':
        return t('label_nearby_descr');
      case '/':
        return t('label_homepage');
      case 'searchFavourite':
        return t('page_title_fav');
      case 'recentlyViewed':
        return t('label_recently_visited');
      case 'contribution':
        return t('label_my_contributions');
      case 'templesList':
        return t("label_fam_temples");
      case 'templeGroup/101':
        return t('label_temple_group_name');
      case '108 Divya Desams':
        return t('label_div_desam');
      case '51 Shakthi Peethas':
        return t('label_shakti_peethas');
      case 'Jyotir Lingas':
        return t('label_jyo');
      case 'templeDashboard':
        return t('label_my_temple_dashboard');
      default:
        return t('unknownPage');
    }
  };

  const filterId = location.state ? location.state.filterId : null;

  function navigateBackToTemplesList() {
    navigate(-1);
  }



  // Use navigate to go back to the previous page
  // const handleGoBack = () => {
  //     navigate(-1);
  // };

  let link = <a href="" target="_blank" rel="noopener noreferrer">More Info</a>
  const formattedTimings = templeDetails && templeDetails.timings ? templeDetails.timings.replace(/<br>/g, '\n') : '';
  const formattedFestivals = templeDetails && templeDetails.festivals ? templeDetails.festivals.replace(/<br>/g, '\n') : '';
  const formattedHistory = templeDetails && templeDetails.history ? templeDetails.history.replace(/<br>/g, '\n') : '';
  const formattedDescr = templeDetails && templeDetails.descr ? templeDetails.descr.replace(/<br>/g, '\n') : '';

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRef = React.createRef();

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const handleCameraClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'environment'; // Access the device camera

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result); // Display image in modal for cropping
          setIsModalVisible(true); // Open the cropping modal
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    document.body.appendChild(fileInput);
    fileInput.click();

    // Clean up the file input to prevent memory leaks
    fileInput.remove();
  };

  // const handleGalleryClick = () => {
  //     const fileInput = document.createElement('input');
  //     fileInput.type = 'file';
  //     fileInput.accept = 'image/*';

  //     // Attach the event listener to the file input
  //     fileInput.addEventListener('change', (event) => {
  //         const selectedFile = event.target.files[0];
  //         if (selectedFile) {
  //             const formData = new FormData();
  //             formData.append('file', selectedFile);
  //             dispatch(postUploadImageRequest(tid, formData, success, failure));
  //         }
  //         event.target.value = null; // Clear the input to allow selecting the same file again
  //     });

  //     // Append the input element to the body
  //     document.body.appendChild(fileInput);

  //     // Trigger the file input click event
  //     fileInput.click();
  // };

  // const handleGalleryClick = () => {
  //     const fileInput = document.createElement('input');
  //     fileInput.type = 'file';
  //     fileInput.accept = 'image/*';

  //     fileInput.addEventListener('change', (event) => {
  //         const selectedFile = event.target.files[0];
  //         if (selectedFile) {
  //             const formData = new FormData();
  //             formData.append('file', selectedFile);
  //             dispatch(postUploadImageRequest(tid, formData, () => {
  //                 const uploadedImagePath = URL.createObjectURL(selectedFile);
  //                 setNewImagePath(uploadedImagePath);
  //             }, (errorMsg) => {
  //                 console.error('Image upload failed:', errorMsg);
  //             }));
  //         }
  //         document.body.removeChild(fileInput); // Clean up the file input
  //     });

  //     document.body.appendChild(fileInput);
  //     fileInput.click();
  // };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const imageRef = useRef(null);

  const handleGalleryClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result); // Show image in modal
          setIsModalVisible(true); // Open modal
        };
        reader.readAsDataURL(selectedFile);
      }
    });

    fileInput.click();
  };

  const handleImageCloseModal = () => {
    setIsModalVisible(false);
    if (cropper) {
      cropper.destroy(); // Destroy cropper instance when modal is closed
    }
  };
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const handleCropAndUpload = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const formData = new FormData();
        formData.append('file', blob);
        setLoading(true);
        setHasError(false);
        // Replace the following line with your image upload logic
        dispatch(postUploadImageRequest(tid, formData, () => {
          const uploadedImagePath = URL.createObjectURL(blob);
          setNewImagePath(uploadedImagePath);
          handleImageCloseModal();
          setLoading(false);
          showToast('success', 'Image sent for review successfully!');
        }, (errorMsg) => {
          console.error('Image upload failed:', errorMsg);
          setLoading(false);
          setHasError(true);
          showToast('error', 'Failed to upload image. Please try again.');
        }));
      });
    }
  };

  useEffect(() => {
    if (isModalVisible && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
        viewMode: 1,
        autoCropArea: 1, // Ensure the crop area covers the whole image
        responsive: true, // Ensure responsiveness
        scalable: false,
        zoomable: true,
        cropBoxResizable: true,
        minCropBoxHeight: 200, // Min height as per your style
        maxCropBoxHeight: 200, // Max height as per your style
        minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
        maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
      });
      setCropper(cropperInstance);
    }

    return () => {
      if (cropper) {
        cropper.destroy(); // Cleanup when the modal is closed or component unmounts
      }
    };
  }, [isModalVisible]);





  const [modalVisible, setModalVisible] = useState(false);

  const closeMapModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (modalVisible) {
        closeMapModal();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (modalVisible && event.key === 'Escape') {
        closeMapModal();
        event.preventDefault();
      }
    };
    if (modalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalVisible, closeMapModal]);

  // Function to open the modal
  const openModal = () => {
    setModalVisible(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalVisible(false);
  };

  const closeIconStyle = {
    // Add styles to make the close icon visible
    color: '#ffc107', // You can customize the color
    fontSize: '20px', // You can adjust the font size
  };

  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 2000); // Hide the image after 2 seconds

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const detailsRef = useRef(null);
  const galleryRef = useRef(null);
  const historyRef = useRef(null);
  const libraryRef = useRef(null);
  const notificationsRef = useRef(null);
  const serviceRef = useRef(null);

  const [highlightedSection, setHighlightedSection] = useState(null);

  // useEffect(() => {
  //     const handleScroll = () => {
  //         const scrollPosition = window.scrollY;

  //         // Calculate the offsetTop of each section
  //         const detailsOffset = detailsRef.current.offsetTop;
  //         const galleryOffset = galleryRef.current.offsetTop;
  //         const historyOffset = historyRef.current.offsetTop;
  //         const libraryOffset = libraryRef.current.offsetTop;
  //         const notificationsOffset = notificationsRef.current.offsetTop;

  //         // Determine which section is in view based on the scroll position
  //         if (scrollPosition < galleryOffset) {
  //             setHighlightedSection('details');
  //         } else if (scrollPosition >= galleryOffset && scrollPosition < historyOffset) {
  //             setHighlightedSection('gallery');
  //         } else if (scrollPosition >= historyOffset && scrollPosition < libraryOffset) {
  //             setHighlightedSection('history');
  //         } else if (scrollPosition >= libraryOffset && scrollPosition < notificationsOffset) {
  //             setHighlightedSection('library');
  //         } else {
  //             setHighlightedSection('notifications');
  //         }
  //     };

  //     // Add event listener for scroll
  //     window.addEventListener('scroll', handleScroll);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLinkClick = () => {
    // setOpen(false);
    showToast('error', t(`Please login to edit details.`))

  };

  const handleImageLinkClick = () => {
    showToast('error', t(`Please login to upload image.`))
  };

  useEffect(() => {
    if (tDetails && tDetails.st) {
      const initialState = statesData.states.find(
        (state) => capitalizeFirstLetters(state.name) === capitalizeFirstLetters(tDetails.st)
      );

      setSelectedState(initialState);
      setCities(initialState ? initialState.cities.map(capitalizeFirstLetters) : []);

      if (formRef.current) {
        formRef.current.setFieldsValue({
          st: capitalizeFirstLetters(tDetails.st),
          city: capitalizeFirstLetters(tDetails.city) || (initialState ? capitalizeFirstLetters(initialState.cities[0]) : undefined),
        });
      }
    }
  }, [tDetails, statesData]);

  const handleStateChange = (value) => {
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities.map(capitalizeFirstLetters) : []);

    if (formRef.current) {
      formRef.current.setFieldsValue({
        city: newState && newState.cities ? capitalizeFirstLetters(newState.cities[0]) : undefined,
      });
    }
  };

  const handleLangPage = () => {
    navigate(`/langTrans/${templeDetails.tid}`)
  };

  const getDomain = (url) => {
    try {
      const hostname = new URL(url).hostname;
      return hostname.replace('www.', '');
    } catch (e) {
      // Handle case where URL is not valid
      return url.replace('https://www.templeswiki.com/templeDetails/', '');
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const capitalizeFirstLetters = (string) => {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };


  const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
  const [isTimingsModalVisible, setIsTimingsModalVisible] = useState(false);
  const [isDailyPujasModalVisible, setIsDailyPujasModalVisible] = useState(false);
  const [isArchitectureModalVisible, setIsArchitectureModalVisible] = useState(false);
  const [isFestivalDetailsModalVisible, setIsFestivalDetailsModalVisible] = useState(false);
  const [isHowToReachModalVisible, setIsHowToReachModalVisible] = useState(false);
  const [isAccomodationModalVisible, setIsAccomodationModalVisible] = useState(false);
  const [isAddtnlInfoModalVisible, setIsAddtnlInfoModalVisible] = useState(false);
  const [isSplPujasModalVisible, setIsSplPujasModalVisible] = useState(false);
  const [isDescrModalVisible, setIsDescrModalVisible] = useState(false);
  const [contributeValue, setContributeValue] = useState("");
  const [isCorrectionModalVisible, setIsCorrectionModalVisible] = useState(false);

  const [howToReach, setHowToReach] = useState(templeDetails.howtoreach || '');

  // Show Modals
  const showHistoryModal = () => {
    setContributeValue(""); // Reset value when modal opens 
    setIsHistoryModalVisible(true);
  };

  const showTimingsModal = () => {
    setContributeValue(""); // Reset value when modal opens
    setIsTimingsModalVisible(true);
  };

  const showDailyPujasModal = () => {
    setContributeValue("");
    setIsDailyPujasModalVisible(true);
  };

  const showFestivalDetailsModal = () => {
    setContributeValue("");
    setIsFestivalDetailsModalVisible(true);
  };

  const showHowToreachModal = () => {
    setHowtoreach(
      templeDetails.howtoreach ? capitalizeFirstLetter(templeDetails.howtoreach.split("<BR>").join("\n")) : ''
    );
    setIsHowToReachModalVisible(true);
  };

  useEffect(() => {
    setHowtoreach(
      templeDetails.howtoreach ? capitalizeFirstLetter(templeDetails.howtoreach.split("<BR>").join("\n")) : ''
    );
  }, [templeDetails.howtoreach]);


  const [howtoreach, setHowtoreach] = useState(
    templeDetails.howtoreach ? capitalizeFirstLetter(templeDetails.howtoreach.split("<BR>").join("\n")) : ''
  );

  const showAccommodationModal = () => {
    setContributeValue("");
    setIsAccomodationModalVisible(true);
  };

  const showAddtnlInfoModal = () => {
    setContributeValue("");
    setIsAddtnlInfoModalVisible(true);
  };

  const showSplPujasModal = () => {
    setContributeValue(""); // Reset value when modal opens
    setIsSplPujasModalVisible(true);
  };

  const showDescrModal = () => {
    setContributeValue(""); // Reset value when modal opens
    setIsDescrModalVisible(true);
  };

  const showCorrectionModal = () => {
    setIsCorrectionModalVisible(true);
  };

  // Handle OK (Submit)
  const handleHistoryOk = () => {
    updateHist({ target: { value: contributeValue } });
    setIsHistoryModalVisible(false);
    setContributeValue(""); // Clear the textarea after saving
  };

  const handleDescrOk = () => {
    handleDescr({ target: { value: contributeValue } });
    setIsDescrModalVisible(false);
    setContributeValue(""); // Clear the textarea after saving
  };

  // Handle {t("label_cancel")}
  const handleHistoryCancel = () => {
    setIsHistoryModalVisible(false);
  };
  const handleTimingsCancel = () => {
    setIsTimingsModalVisible(false);
  };

  const handleDailyPujasCancel = () => {
    setIsDailyPujasModalVisible(false);
  };

  const handleArchitectureCancel = () => {
    setIsArchitectureModalVisible(false);
  };

  const handleFestivalDetailsCancel = () => {
    setIsFestivalDetailsModalVisible(false);
  };

  const handleAddtnlInfoCancel = () => {
    setIsAddtnlInfoModalVisible(false);
    setAddInfo(false);
  };


  const handleAccomodationCancel = () => {
    setIsAccomodationModalVisible(false);
  };

  const handleHowToReachCancel = () => {
    setIsHowToReachModalVisible(false);
  };

  const handleSplPujasCancel = () => {
    setIsSplPujasModalVisible(false);
  };

  const handleDescrCancel = () => {
    setIsDescrModalVisible(false);
  };

  const handleCorrectionCancel = () => {
    setIsCorrectionModalVisible(false);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (isCorrectionModalVisible) {
        handleCorrectionCancel();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (isCorrectionModalVisible && event.key === 'Escape') {
        handleCorrectionCancel();
        event.preventDefault();
      }
    };
    if (isCorrectionModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCorrectionModalVisible, handleCorrectionCancel]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isSplPujasModalVisible) {
        handleSplPujasCancel();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (isSplPujasModalVisible && event.key === 'Escape') {
        handleSplPujasCancel();
        event.preventDefault();
      }
    };
    if (isSplPujasModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSplPujasModalVisible, handleSplPujasCancel]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isDailyPujasModalVisible) {
        handleDailyPujasCancel();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (isDailyPujasModalVisible && event.key === 'Escape') {
        handleDailyPujasCancel();
        event.preventDefault();
      }
    };
    if (isDailyPujasModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDailyPujasModalVisible, handleDailyPujasCancel]);

  useEffect(() => {
    const handlePopState = (event) => {
      if (isTimingsModalVisible) {
        handleTimingsCancel();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (isTimingsModalVisible && event.key === 'Escape') {
        handleTimingsCancel();
        event.preventDefault();
      }
    };
    if (isTimingsModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTimingsModalVisible, handleTimingsCancel]);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);


  const handleGoBack = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //     if (sliderRef.current) {
  //         sliderRef.current.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
  //             setShowImage(true); // Show loading spinner before changing to the next image
  //         });
  //     }
  // }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      if (modalPreviewVisible) {
        closePreviewModal();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (modalPreviewVisible && event.key === 'Escape') {
        closePreviewModal();
        event.preventDefault();
      }
    };
    if (modalPreviewVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalPreviewVisible, closePreviewModal]);

  return (
    <>
      {contextHolder}

      <div className="popup-bg" style={{ display: modalPreviewVisible ? 'flex' : 'none' }}>
        <CustomToastContainer />
        <div class="popup-main animate__animated animate__fadeInUp ">
          <div class="popup-main-header">
            <span className="popup-main-header-text">{templeMinDetails ? templeMinDetails && templeMinDetails.tname : 'Temple Name'} &nbsp;</span>
            <div className="popup-close" onClick={closePreviewModal}>
              <i className="fa-solid fa-xmark"></i>
            </div>
            <div className="popup-back" onClick={closePreviewModal}>
              <i className="fa-solid fa-chevron-left"></i>
            </div>
          </div>
          <div className="popup-main-container">
            <div className="d-block htinner-popup-main-container">


              <img
                className="grp-descr-img"
                alt={templeMinDetails.tname}
                src={templeMinDetails.imgpath ? getMediumImageAPI() + templeMinDetails.imgpath : deftemples}
              />
              <div className='dialog-content-text' style={{ float: "left" }}>
                <p><strong>{t('address_label')} : </strong> {capitalizeFirstLetter(templeMinDetails.addr1) || 'N/A'}, {templeMinDetails.addr2 || ''}</p>
                <p>
                  <strong>{t('label_city_state')} :</strong> {`${capitalizeFirstLetter(templeMinDetails.city)}, ${capitalizeFirstLetter(templeMinDetails.st)}`}
                </p>
                <p><strong>{t('label_deity_name')} :</strong> {templeMinDetails.deityname || 'N/A'}</p>
                <p><strong>{t('label_history')} : </strong>
                  {templeMinDetails.history ? templeMinDetails.history.split("<BR>").join("\n") : 'No history available'}
                </p>
                <p><strong>{t('label_descr')} : </strong>
                  {templeMinDetails.descr ? templeMinDetails.descr.split("<BR>").join("\n") : 'No description available'}
                </p>
              </div>


            </div>
          </div>
          <div className="popup-main-footer">
            <div className="tile-quicklink" style={{ marginRight: "60%", bottom: "20px" }}>
              <a
                className="share-button"
                onClick={() => shareResLink("Know " + Title,
                  "Know " + Title,
                  `templeDetails/${id}?prevPage=${window.location.pathname}`
                )}
              >
                <i title="Share" className="fa-solid fa-share-nodes" style={{ fontSize: '24px' }}></i>
              </a>

              {favList && (
                <a
                  className="share-button"
                  onClick={() => toggleFavorite(temple, Title, success)}
                >
                  {favList.some(t => t === id) ? (
                    <i className="fa-solid fa-heart" title={t('Favourites')} style={{ fontSize: '24px' }}></i>
                  ) : (
                    <i className="fa-regular fa-heart" style={{ fontSize: '24px' }}></i>
                  )}
                </a>
              )}
            </div>
            <a
              onClick={handleShowDetails}
              className="button_move"
              style={{ float: 'right', marginRight: '10px' }}
              disabled={loading}
            >
              {t('label_show_details')}
            </a>
            <button
              onClick={handlePrevious}
              className="button_move"
              style={{ float: 'left', marginRight: "5px" }}
              disabled={loading || currentTempleIndex <= 0 || storedTemplesList.length === 0} // Also disable if loading
            >
              {loading && currentTempleIndex > 0 ? <Error /> : t('label_previous')}
            </button>

            <button
              onClick={handleNext}
              className="button_move"
              style={{ float: 'right' }}
              disabled={loading || currentTempleIndex >= storedTemplesList.length - 1 || storedTemplesList.length === 0} // Also disable if loading
            >
              {loading && currentTempleIndex < storedTemplesList.length - 1 ? <Error /> : t('label_next')}
            </button>
          </div>
        </div>
      </div>
      <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
        <div class="popup-main animate__animated animate__fadeInUp ">
          <div class="popup-main-header">
            <span class="popup-main-header-text">{templeDetails.tname}</span>
            <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
            <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
          </div>
          <div class="popup-main-container">
            <div class="d-block htinner-popup-main-container">
              <TempleMap mapContainerStyle={{ width: '100%', height: '55vh' }} tname={templeDetails.tname} latit={templeDetails && templeDetails.latit} longi={templeDetails && templeDetails.longi} city={templeDetails && templeDetails.city} state={templeDetails && templeDetails.st} />
            </div>
          </div>
          <div class="popup-main-footer">

            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
              {t("label_cancel")}
            </a>
          </div>
        </div>
      </div>
      {showMap && (
        <>
          <div class="popup-bg" style={{ display: showMap ? 'flex' : 'none' }}>
            <div class="popup-main animate__animated animate__fadeInUp ">
              <div class="popup-main-header">
                <span class="popup-main-header-text">{t('label_sel_loc_on_map')}</span>
                <div class="popup-close" onClick={() => setShowMap(false)}><i class="fa-solid fa-xmark"></i></div>
                <div class="popup-back" onClick={() => setShowMap(false)}><i class="fa-solid fa-chevron-left"></i></div>
              </div>
              <div class="popup-main-container">
                <div class="d-block htinner-popup-main-container">
                  <GoogleMap
                    center={currentLocation || indiaCenter}
                    zoom={currentLocation ? 14 : 5}
                    mapContainerStyle={{ width: '100%', height: isMobile ? '71vh' : '64vh' }}
                  >
                    {currentLocation && markerVisible && markerPosition && (
                      <Marker
                        position={markerPosition || currentLocation}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                        icon={{
                          url: logo,
                          scaledSize: new window.google.maps.Size(40, 40),
                        }}
                      />
                    )}
                  </GoogleMap>
                </div>
              </div>
              <div class="popup-main-footer">
                <button key="back" class="button_move mr-3 " type="primary" onClick={() => setShowMap(false)} style={customButtonStyle} >
                  {t("label_cancel")}
                </button>
                <button key="button" class="button_move mr-3" type="primary" onClick={handleGetCurrentLocation} style={customButtonStyle}>
                  {t("label_use_curr_loc")}
                </button>
                <button key="submit" class="button_move" type="primary" onClick={handleGetLatLonFromMap} style={customButtonStyle}>
                  {t("label_use_this_loc")}
                </button>

              </div>
              {error && (
                <div className="popup-error" style={{ color: "red", marginTop: "10px" }}>
                  Error: You must click "Use Current Location" first.
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <div className='mainsec' ref={mainSecBlockRef}>

        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span className="bc-link">{t("label_tpl_det")}</span>
          </div>
        </div>
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
          <span >
            <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">{t('Home')}</span></Link> - <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-active-link">{t("label_temples_list")}</span></Link> - </span>
          <span className="bc-link">{t("label_tpl_det")}</span>
        </div>

        <div class={`bg-details-highlight `} >
          <div class="container">
            <div class=" details-headertxt  flex-column">

              <div class="header_txt mb-2"> {templeDetails.tname}</div>
              <div class="d-flex justify-content-between temp-details-secheader-btnsec">
                <div class="d-flex flex-row header_star ">
                  <div class="text-warning temple-details-ratingsec">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fas fa-star-half-alt"></i>
                    <span class="ms-1">
                      4.5
                    </span>
                  </div>
                  <span class="text-white view-count temple-details-ratingsec">{t('label_viewed')}</span>

                  <div class="mr-5 ml-4 temple-details-ratingsec" >
                    <i
                      className="fa-solid fa-location-dot"
                      onClick={() => {
                        openModal();
                        openMap();
                      }}
                    ></i>

                    {/* <Modal
                                    
                                    visible={modalVisible}
                                    onCancel={closeModal}
                                    footer={null}
                                    className='temple-details-map'
                                    width={750}
                                >
                                    <div class="diag-header">{templeDetails.tname}
                                        <span class="diag-close"><i class="fa-solid fa-xmark" onClick={closeModal}></i></span></div>
                                    <div className='map-details'>
                                        <TempleMap tname={templeDetails.tname} latit={templeDetails && templeDetails.latit} longi={templeDetails && templeDetails.longi} city={templeDetails && templeDetails.city} state={templeDetails && templeDetails.st} />
                                    </div>

                                    
                                </Modal> */}
                    &nbsp; &nbsp; &nbsp;
                    <i class="fa-solid fa-share-nodes"
                      onClick={() => shareResLink(templeDetails.tname,
                        "Check out this temple!",
                        `templeDetails/${tid}`
                      )}></i> &nbsp; &nbsp;
                    <Button
                      type="link"
                      style={{ color: "white" }}
                      onClick={() => {
                        toggleFavorite(templeDetails, templeDetails.tname);
                      }}
                      icon={
                        favList.some(t => t === templeDetails.tid) ? (
                          <i className="fa-solid fa-heart" title={t('Favourites')} onClick={() => toggleFilled(templeDetails.tid)} ></i>
                        ) : (
                          <i className="fa-regular fa-heart " onClick={() => toggleFilled(templeDetails.tid)}></i>
                        )
                      }
                    />

                    <Button type="link" style={{ color: "white" }} className="icon-button" onClick={() => openPreviewModal(id)}>
                      <i className="fa-solid fa-eye"></i>
                    </Button>


                  </div>



                </div>

                <div class="" >

                  {/* {editable && ( */}
                  <a class="button_move"
                    onClick={handleSaveClick}
                    style={{ marginRight: '5px' }}
                  >
                    {t("label_save")}
                  </a>
                  {/* )} */}
                  {/* {token ? ( */}
                  <>
                    <a

                      className="button_move"
                      onClick={handleCancelClick}
                    >
                      {t("label_cancel")}
                    </a>
                  </>
                  {/* ) : (
                    <>
                      <a   className="button_move" onClick={handleLinkClick}>
                        {t("edit")}
                      </a>
                    </>
                  )} */}
                  {/* {token && (userid === "AD" || userid === "TA") ? (
                    <>
                      <Popconfirm
                        title={`${t('label_are_you')} ${templeDetails.status === 1 ? t('label_deactive') : t('label_active')}?`}
                        onConfirm={handleSelectChange}
                        ok={<a className="button_move" style={{ float: 'right', marginRight: "5px" }} onClick={handleSelectChange}>
                          {t('label_save')}
                        </a>}
                        cancelText="No"
                      >
                        <a className={`button_move-copy ${templeDetails.status === 1 ? 'btn-danger ' : 'btn-success'}`} style={{ marginLeft: '4px' }}>
                          {templeDetails.status === 1 ? t('label_deactive') : t('label_active')}
                        </a>
                      </Popconfirm>
                      {userid === 'AD' || 'AS' ? (
                        <button onClick={() => { handleLangPage(); }} className="button_move" style={{ marginLeft: '4px' }}>
                          {t('label_lang_trans')}
                        </button>
                      ) : (null)}
                    </>
                  ) : null}
                  {token && <a class="button_move" style={{ marginLeft: "5px" }}
                    onClick={showCorrectionModal}> {t('label_data_correction')} </a>} */}
                  <div className="popup-bg" style={{ display: isCorrectionModalVisible ? 'flex' : 'none' }}>
                    <div className="popup-bg" style={{ display: isCorrectionModalVisible ? 'flex' : 'none' }}>
                      <div className="popup-main animate__animated animate__fadeInUp">
                        <div className="popup-main-header">
                          <span className="popup-main-header-text">{templeDetails && templeDetails.tname}</span>
                          <div className="popup-close" onClick={handleCorrectionCancel}><i className="fa-solid fa-xmark"></i></div>
                          <div className="popup-back" onClick={handleCorrectionCancel}><i className="fa-solid fa-chevron-left"></i></div>
                        </div>
                        <div className="popup-main-container">
                          <div className="d-block htinner-popup-main-container">
                            <div className="radio-group">
                              <Radio.Group
                                onChange={handleRadioChange}
                                value={selectedCorrection}
                              >
                                <div className="radio-columns">
                                  {correctionOptions.map(option => (
                                    <Radio key={option.value} value={option.value}>
                                      {option.label}
                                    </Radio>
                                  ))}
                                </div>
                              </Radio.Group>
                            </div>



                            <Form
                              form={form}
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24 }}
                            >
                              <Form.Item
                                label={t("label_additi_det")}
                                name="query"
                                rules={[{ required: true }]}
                              >
                                <TextArea
                                  className="dialog-content-text-only"

                                  placeholder={t("label_enter_additional_det")}
                                />
                              </Form.Item>
                            </Form>

                          </div>
                        </div>
                        <div className="popup-main-footer">
                          <a className="button_move " style={{ float: 'right', marginRight: "5px" }} onClick={handleCorrection}>
                            {t('label_save')}
                          </a>

                          <a className="button_move" style={{ float: 'left' }} onClick={handleCorrectionCancel}>
                            {t('label_cancel')}
                          </a>
                        </div>
                      </div>
                      {/* <div class="popup-main-footer">
                                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                            Reset
                                        </a>
                                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleCorrection(); setIsCorrectionModalVisible(false); }}>
                                            Save
                                        </a>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="details-icons-main">
            <div className="container details-icons">
              <div className={`details-icons-list `} onClick={() => scrollToSection(detailsRef)}>
                <i className="fa-regular fa-newspaper btn-c1"></i>
                <span className='names'>{t("label_temple_info")}</span>
              </div>
              <div className={`details-icons-list `} onClick={() => scrollToSection(galleryRef)}>
                <i className="fa-regular fa-image btn-c2"></i>
                <span>{t('label_galary')}</span>
              </div>
              <div className={`details-icons-list `} onClick={() => scrollToSection(historyRef)}>
                <i className="fa-solid fa-clock-rotate-left btn-c3"></i>
                <span>{t('label_descr_history')}</span>
              </div>
              {!editable && (
                <>
                  <Link to={`/templeService/${tid}/${templeDetails.tname}`} className='serv'>
                    <div className="details-icons-list">
                      <i class="fa fa-ticket btn-c5" aria-hidden="true"></i>
                      <span>{t('label_services')}</span>
                    </div>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>

        <div class="mainsec-block-addtemple-detailspage ">
          <div className='container'>

            {
              tLoading ? <Error /> :
                <div class="details-page float-left w-100"  >

                  <section class="py-5 d-none">
                    <div class="container">
                      <main class="col-lg-6 ">
                        <div >
                          <div className="slick-carousel-container">
                            <Slider {...settings} ref={sliderRef}>
                              {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                templeDetails.images.map((image, index) => {
                                  return (
                                    <div key={index}>
                                      {showImage ? (
                                        <img className='det-image' src={spin} alt="loading" />
                                      ) : (

                                        <img className='det-image' src={getMediumImageAPI() + image.imgpath} alt="images" />
                                      )}
                                    </div>
                                  );
                                })
                              ) : (
                                <div>
                                  <img className='det-image' alt="temples" src={deftemples} />
                                </div>
                              )}
                            </Slider>

                            {/* Conditionally render the left and right outlines */}
                            {templeDetails.images != null && templeDetails.images.length > 1 && (
                              <>
                                <LeftOutlined className='leftoutlined' onClick={handlePrevClick}>t('label_previous')</LeftOutlined>
                                <RightOutlined className='rightoutlined' onClick={handleNextClick}>t('label_next')</RightOutlined>
                              </>
                            )}
                          </div>
                        </div>
                      </main>

                      <main class="col-lg-12">
                        <div class="ps-lg-3">

                          <div class="row">

                            <dt class="col-2"></dt>


                            <dt class="col-2"></dt>





                          </div>





                        </div>
                      </main>
                    </div>
                  </section>

                  <div class="d-none">
                    <Row justify='space-between' >
                      <Col span={11} order={2} style={{ top: '27px' }}>
                        <div className='tmple-name'>


                          <Button
                            className='edit-button'
                            data-mdb-ripple-color="dark"
                            onClick={editable ? handleCancelClick : handleEditClick}
                          >
                            {buttonText}
                          </Button>

                          {editable && (
                            <Button
                              className='btn btn-outline-secondary'
                              data-mdb-ripple-color="dark"
                              onClick={handleSaveClick}
                              style={{ marginLeft: '5px' }}
                            >
                              {t("label_save")}
                            </Button>
                          )}
                          {/* <Link to='/templeMap'><i title="Location" class="fa-solid fa-location-dot"></i></Link> */}

                          <div class="tiles-quicklink" style={{ display: 'inline-block' }}>
                            <i title="Location" class="fa-solid fa-location-dot" onClick={openModal}></i>



                          </div>
                          <Button
                            className="share-button"
                            type="link"
                            onClick={() => shareResLink(templeDetails.tname,
                              "Check out this temple!",
                              `templeDetails/${tid}`
                            )}
                          >
                            <i title="Share" class="fa-solid fa-share-nodes"></i>
                          </Button>
                          <Button
                            className="temple-fav-icon"
                            type="link"
                            onClick={() => {
                              toggleFavorite(templeDetails, templeDetails.tname);
                            }}
                            icon={
                              favList.some(t => t === id) ? (
                                <i className="fa-regular fa-heart" onClick={() => toggleFilled(id)} ></i>
                              ) : (
                                <i className="fa-solid fa-heart " title={t('Favourites')} onClick={() => toggleFilled(id)}></i>
                              )
                            }
                          />

                        </div>

                        <div style={{ marginTop: '5px' }}>
                          <Image
                            src={deityImages[templeDetails.avatar]}
                            alt="Selected Deity Image"
                            onClick={handleImageClick}
                            style={{
                              // cursor: enableModal ? 'pointer' : 'not-allowed',
                              width: '15%',
                              marginRight: '13%',
                              marginTop: '-18%',
                              height: '10%'
                            }}
                          />

                          {/* <Modal
                                                        title="Select Deity Image"
                                                        visible={showAllImages}
                                                        onCancel={handleCloseModal}
                                                        footer={null}
                                                    >
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            {Object.keys(deityImages).map((deity) => (
                                                                <Image
                                                                    key={deity}
                                                                    src={deityImages[deity]}
                                                                    alt="Deity Image"
                                                                    style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                                                                    onClick={() => handleDeityImageClick(deity)}
                                                                // disabled={!showAllImages}
                                                                />
                                                            ))}
                                                        </div>
                                                    </Modal> */}
                        </div>

                        {/* <ImgCrop rotationSlider>
                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={onChange}
                                            onPreview={onPreview}
                                            onClick={submitForm}
                                        >
                                            {fileList.length < 5 && '+Upload image'}
                                        </Upload>

                                    </ImgCrop> */}

                        {/* <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                    <button onClick={() => uploadNewImage()}>Save Temple Image <i class="fa-solid fa-circle-play"></i></button><br />
                                    <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onclick={uploadNewImage}>{t('Save Image')}</button><br />
                                    <button style={{ marginTop: '5px', marginRight: '5px' }} className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={openCameraModal}>Open Camera</button><br />

                                    <Modal
                                        visible={showCameraModal}
                                        onCancel={handleCameraModalCancel}
                                        footer={[
                                            <Button key="cancel" onClick={handleCameraModalCancel}>{t("label_cancel")}</Button>,
                                            <Button key="submit" type="primary" onClick={handleCameraModalOk}>Submit</Button>
                                        ]}
                                    >
                                        {!previewImage ? (
                                            <>
                                                <video ref={videoRef} />
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={startCamera}>{t('Start Camera')}</button>
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginLeft: '5px' }} onClick={captureImage}>Capture Image</button>
                                            </>
                                        ) : (
                                            <div>
                                                <img style={{ width: '100%' }} src={previewImage} alt="Preview" />
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={deleteImage}>Delete Image</button>
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" >Submit </button>
                                            </div>
                                        )}
                                    </Modal>
                                    <Link to={`/details/${templeDetails.tid}`} className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginTop: '5px', marginRight: '3px' }}>Blog</Link>
                                    <Link className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginTop: '5px' }} to={`/gallery/${templeDetails.tid}`}>Gallery</Link><br />
                                    {userid === "RU" || userid === "AD" ? (
                                        <>
                                            <Typography.Title level={4}>Change Temple status</Typography.Title>
                                            <Switch defaultChecked onChange={handleSelectChange} />
                                            <Typography.Title level={4}>Diable Temple Images</Typography.Title>
                                            <Switch defaultChecked onChange={handleChange} />
                                        </>
                                    ) : null}
                                    <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={() => setEnableModal(true)} style={{ marginTop: '5px' }}>
                                        <EditOutlined />
                                        Edit
                                    </button>*/}
                      </Col>
                      {/* <Col span={13} order={1} style={{ top: '27px' }}>
                                            <div className="slick-carousel-container">
                                                <Slider {...settings} ref={sliderRef}>
                                                    {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                                        templeDetails.images.map((image, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <img className='det-image' src={getMediumImageAPI() + image.imgpath} alt="images" />
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div>
                                                            <img className='det-image' alt="temples" src={deftemples} />
                                                        </div>
                                                    )}
                                                </Slider>
                                                <LeftOutlined className='leftoutlined' onClick={handlePrevClick}>t('label_previous')</LeftOutlined>
                                                <RightOutlined className='rightoutlined' onClick={handleNextClick}>t('label_next')</RightOutlined>
                                            </div>
                                        </Col> */}

                    </Row>

                  </div>
                  {/* <div class="temp-detail-demo-sec">
                                        <h2>BLOG LINK</h2>
                                    </div> */}
                  {/* <div class="float-left w-100" ref={detailsRef}>
                                        <h5 class="card-text-title">{t("label_details")}
                                    </h5>
                                    </div> */}
                  <Form
                    style={{
                      float: 'left',
                      width: '100%'
                    }}
                    form={formRef.current}
                    onFinish={handleFormSubmit}
                    ref={formRef} // Assign ref to the form
                  >
                    <div class="float-left w-100" ref={detailsRef}><h5 class="card-text-title">{t('label_temple_info')}
                      {/* <span>| {t('label_gallery_head')}</span> */}
                    </h5>
                    </div>

                    <div className="active-details">
                      <div className="tiles-designs">


                        {/* <p class="mainsec-block-addtemple-detailspage-title" ref={detailsRef}>{templeDetails && templeDetails.tname}</p> */}

                        {/* <div class="templedetails-add-block d-flex">

                                                    <div class="d-flex templedetails-add-sec">
                                                        <div class="d-flex tempDetails-w">
                                                            <span class="d-flex align-items-center justify-content-center">
                                                                <img
                                                                    src={deityImages[templeDetails.avatar]}
                                                                    alt="Selected Deity Image"
                                                                    onClick={handleImageClick}
                                                                    className='details-img-god'
                                                                   
                                                                />
                                                            </span>
                                                            <div class="d-flex flex-column templedetails-add-block-main mt-2 mb-2 add-w-control">
                                                                <p class=" deityname mainsec-block-addtemple-detailspage-title-text-main">{templeDetails && templeDetails.deityname}</p>
                                                                <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                    <span class="details-info-txt-label">{t('label_other_deities')}:</span> 
                                                                    <span class="details-info-txt-value" title="templeDetails && templeDetails.otherdeities">{templeDetails && templeDetails.otherdeities}</span>
                                                                </p>
                                                                <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                    <span class="details-info-txt-label">Address:</span> 
                                                                    <span class="details-info-txt-value">{templeDetails && templeDetails.addr1}, {templeDetails.addr2}</span>
                                                                </p>
                                                            
                                                                    <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                    <span class="details-info-txt-label details-info-txt-label-mob-view">&nbsp;</span> 
                                                                    <span class="details-info-txt-value">{templeDetails.city}, {templeDetails.st} </span></p>                                                              
                                                                    <p class="mainsec-block-addtemple-detailspage-title-text1">
                                                                    <span class="details-info-txt-label">Pincode</span> 
                                                                    <span class="details-info-txt-value">{templeDetails && templeDetails.pincode}</span></p>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-column templedetails-add-block-main mt-2 mb-2 details-info2 tempDetails-w">
                                                           
                                                            <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_email')}:</span> <span >{templeDetails && templeDetails.email}</span></p>
                                                            <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">Contact:</span> <span>{templeDetails && templeDetails.phoneno}</span></p>
                                                            <p className="mainsec-block-addtemple-detailspage-title-text">
                                                                <span className="details-info-txt">{t('label_website')}:</span>
                                                                <span >
                                                                    {templeDetails && templeDetails.website ? (
                                                                        <a
                                                                            href={
                                                                                templeDetails.website.startsWith("http")
                                                                                    ? templeDetails.website
                                                                                    : `http://${templeDetails.website}`
                                                                            }
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ height: '35px' }}
                                                                        >
                                                                            {templeDetails.website}
                                                                        </a>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </span>
                                                            </p>
                                                            <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_priest')}:</span> <span>{templeDetails && templeDetails.priestname}</span></p>
                                                            <p class="mainsec-block-addtemple-detailspage-title-text"><span class="details-info-txt">{t('label_priest_phone_no')}:</span> <span >{templeDetails && templeDetails.priestphoneno}</span></p>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="float-left w-100" >
                                                    <div class="card-text-title">{t('label_timings')}
                                                    </div>
                                                </div>

                                                <table align="center" class="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('label_time')}</th>
                                                            <th>{t('label_puja')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {timingsArray.map((timing, index) => (
                                                            <tr key={index}>
                                                                <td align="left" className="gridbg1">{timing.time}</td>
                                                                <td align="left" className="gridbg2">{timing.puja}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div class="float-left w-100" >
                                                    <div class="card-text-title">{t('label_daily_pujas')}
                                                    </div>
                                                </div>

                                                <table align="center" class="timming-grid" border="0" cellPadding="0" cellSpacing="0" rules="all">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('label_time')}</th>
                                                            <th>{t('label_puja')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pujasArray.map((timing, index) => (
                                                            <tr key={index}>
                                                                <td align="left" className="gridbg1">{timing.time}</td>
                                                                <td align="left" className="gridbg2">{timing.puja}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table> */}
                        {/* {editable ? ( */}
                        <Form.Item


                          label={<Typography.Title level={4}>{t('label_avatar')}</Typography.Title>}
                          rules={[{ required: true }]}
                        >
                          <>

                            <img
                              src={currentImage || deityImages[0]}
                              alt="Selected Deity Image"
                              onClick={handleImageClick}
                              class="add-temple-img"
                            />

                            <p class="nmt-2">{t("label_click_on_img")}</p>


                            <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                              <div class="popup-main-filter animate__animated animate__fadeInUp ">
                                <div class="popup-main-header-filter">
                                  <span class="popup-main-header-text">{t('label_god_categ')}</span>
                                  <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                  <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                </div>
                                <div class="popup-main-container">
                                  <div class="d-block htinner-popup-main-container">
                                    <div className=" god-category-list">
                                      <div className="all-gods-sec">{t('label_all')}</div>
                                      <div className="god_select">
                                        <div className="god_pic">
                                          <img
                                            className="dietyImg"
                                            src={aum}
                                            alt="All"
                                            onClick={() => handleDeityImageClick(null)}
                                          />
                                        </div>
                                      </div>
                                      <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                      <Row gutter={[16, 16]}>
                                        {godCtgryList && godCtgryList.map((godCtgry) => (
                                          <Col key={godCtgry.avatar}>
                                            <div className="god_select">
                                              <div className="god_pic">
                                                <img
                                                  src={deityImages[godCtgry.avatar]}
                                                  alt="Deity Image"
                                                  style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    margin: '5px',
                                                    cursor: 'pointer',
                                                    border: '1px solid #ddd'
                                                  }}
                                                  onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                />
                                              </div>
                                              <p>{godCtgry.deity}</p>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                                <div class="popup-main-footer">
                                  <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>

                                </div>
                              </div>
                            </div>
                          </>
                        </Form.Item>
                        {/* ) : (

                          <Form.Item
                            label={<Typography.Title level={4}>{t('label_avatar')}</Typography.Title>}
                          >
                            <Typography.Text level={5} className="typ">
                              <img src={deityImages[tDetails && tDetails.avatar]} alt="Deity Image" class="add-temple-img" />
                            </Typography.Text>
                          </Form.Item>
                        )} */}
                      </div>
                    </div>
                    <div className="active-details">
                      {/* {templeDetails.tname ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_temple_name')}</Typography.Title>}
                          name="tname"
                          rules={[{ required: editable, message: t("error_msg_07") }]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_temple_name') })}
                            defaultValue={templeDetails.tname || ''}
                          />
                          {/* ) : (
                              templeDetails.tname && (
                                <Typography.Text className="typ">{templeDetails.tname}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.deityname ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_main_deity')}</Typography.Title>}
                          name="deityname"
                          rules={[{ required: editable, message: t("error_msg_07") }]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_main_deity') })}
                            defaultValue={templeDetails.deityname || ''}
                          />
                          {/* ) : (
                              templeDetails.deityname && (
                                <Typography.Text className="typ">{templeDetails.deityname}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.deityname ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_other_deities')}</Typography.Title>}
                          name="otherdeities"
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_other_deities') })}
                            defaultValue={templeDetails.otherdeities || ''}
                          />
                          {/* ) : (
                              templeDetails.otherdeities && (
                                <Typography.Text className="typ">{templeDetails.otherdeities}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.addr1 ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_village_area')}</Typography.Title>}
                          name="addr1"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_village_area') })}
                            defaultValue={templeDetails.addr1 || ''}
                          />
                          {/* ) : (
                              templeDetails.addr1 && (
                                <Typography.Text className="typ">{templeDetails.addr1}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.addr2 ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_addr')}</Typography.Title>}
                          name="addr2"
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_addr') })}
                            defaultValue={templeDetails.addr2 || ''}
                          />
                          {/* ) : (
                              templeDetails.addr2 && (
                                <Typography.Text className="typ">{templeDetails.addr2}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.st ? ( */}
                      <div className="tiles-designs">
                        {/* <Form.Item
                            label={editable ? null : <Typography.Title level={4}>{t('label_city_state')}</Typography.Title>}
                            name="state_and_city"
                          > */}
                        {/* {editable ? ( */}
                        <>
                          <div className="tiles-designs">
                            <Form.Item
                              label={<Typography.Title level={4}>{t('label_state')}</Typography.Title>}
                              name="st"
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                value={selectedState ? capitalizeFirstLetters(selectedState.name) : undefined}
                                onChange={handleStateChange}
                              >
                                {states.map((state) => (
                                  <Select.Option key={state} value={state}>
                                    {capitalizeFirstLetters(state)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className="tiles-designs">
                            <Form.Item
                              label={<Typography.Title level={4}>{t('label_city')}</Typography.Title>}
                              name="city"
                            >
                              <Select
                                showSearch
                                value={capitalizeFirstLetters(tDetails.city)}
                                optionFilterProp="children"
                              >
                                {cities.map((city) => (
                                  <Select.Option key={city} value={city}>
                                    {capitalizeFirstLetters(city)}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </>
                        {/* ) : (
                              templeDetails.st && (
                                <Typography.Text className="typ"> {capitalizeFirstLetter(templeDetails.city)}, {capitalizeFirstLetters(templeDetails.st)}
                                </Typography.Text>
                              )
                            )} */}
                        {/* </Form.Item> */}
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.pincode ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_pin')}</Typography.Title>}
                          name="pincode"
                          rules={[
                            {
                              validator: validatePincode,
                            },
                          ]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_pin') })}
                            defaultValue={templeDetails.pincode || ''}
                            maxLength={6}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
                            }}
                          />

                          {/* ) : (
                              templeDetails.pincode && (
                                <Typography.Text className="typ">{templeDetails.pincode}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.phoneno ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_phone_no')}</Typography.Title>}
                          name="phoneno"
                          rules={[
                            {
                              validator: validatePhoneNumber,
                            },
                          ]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_phone_no') })}
                            defaultValue={templeDetails.phoneno || ''}
                            maxLength={10}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                            }}
                          />

                          {/* ) : (
                              templeDetails.phoneno && (
                                <Typography.Text className="typ">{templeDetails.phoneno}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.email ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_email')}</Typography.Title>}
                          name="email"
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_email') })}
                            defaultValue={templeDetails.email || ''}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z0-9@.]/g, '');
                            }}
                          />
                          {/* ) : (
                              templeDetails.email && (
                                <Typography.Text className="typ">{templeDetails.email}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.website ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_website')}</Typography.Title>}
                          name="website"
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_website') })}
                            defaultValue={templeDetails.website || ''}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z.]/g, '');
                            }}
                          />
                          {/* ) : (
                              templeDetails.website && (
                                <a
                                  href={templeDetails.website.startsWith("http") ? templeDetails.website : `http://${templeDetails.website}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {templeDetails.website}
                                </a>

                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.priestname ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_priest_nam')}</Typography.Title>}
                          name="priestname"
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_priest_nam') })}
                            defaultValue={templeDetails.priestname || ''}
                          />
                          {/* ) : (
                              templeDetails.priestname && (
                                <Typography.Text className="typ">{templeDetails.priestname}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.priestphoneno ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_priest_phone_no')}</Typography.Title>}
                          name="priestphoneno"
                          rules={[
                            {
                              validator: validatePhoneNumber,
                            },
                          ]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_priest_phone_no') })}
                            defaultValue={templeDetails.priestphoneno || ''}
                            maxLength={10}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                            }}
                          />
                          {/* ) : (
                              templeDetails.priestphoneno && (
                                <Typography.Text className="typ">{templeDetails.priestphoneno}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}
                      {/* {editable || templeDetails.timings ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_timings')}</Typography.Title>}
                          name="timings"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_timings') })}
                            defaultValue={capitalizeFirstLetter(templeDetails.timings && templeDetails.timings.split("<BR>").join("\n") || '')}
                            onChange={handleTimingsChange}
                            onBlur={updateOtherFields}
                            onClick={showTimingsModal}
                          />
                          {/* ) : (
                              templeDetails.timings && (
                                <Typography.Text className="typ"><div
                                  dangerouslySetInnerHTML={{
                                    __html: typeof templeDetails.timings === "string"
                                      ? capitalizeFirstLetter(
                                        templeDetails.timings.replace(/(?:\r\n|\r|\n)/g, "<br>\n")
                                      )
                                      : templeDetails.timings,
                                  }}
                                />
                                </Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isTimingsModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_timings')}</span>
                            <div class="popup-close" onClick={handleTimingsCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleTimingsCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="timings"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={capitalizeFirstLetter(templeDetails.timings && templeDetails.timings.split("<BR>").join("\n") || '')}
                                    onChange={handleTimings} // Use updated onChange handler
                                    placeholder={t("error_msg_76")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateTimings(); setIsTimingsModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.dailypujas ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_daily_pujas')}</Typography.Title>}
                          name="dailypujas"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_daily_pujas') })}
                            defaultValue={capitalizeFirstLetter(templeDetails.dailypujas && templeDetails.dailypujas.split("<BR>").join("\n") || '')}
                            onChange={handleDailyPujasChange}
                            // onBlur={updateOtherFields}
                            onClick={showDailyPujasModal}
                          />

                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isDailyPujasModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_daily_pujas')}</span>
                            <div class="popup-close" onClick={handleDailyPujasCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleDailyPujasCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="dailypujas"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={templeDetails.dailypujas ? capitalizeFirstLetter(templeDetails.dailypujas.split("<BR>").join("\n")) : ''}
                                    onChange={handleDailyPujasChange} // Use updated onChange handler
                                    placeholder={t("error_msg_77")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateDailyPujas(); setIsDailyPujasModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.splpujas ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_special_pujas')}</Typography.Title>}
                          name="splpujas"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_special_pujas') })}
                            defaultValue={capitalizeFirstLetter(templeDetails.splpujas && templeDetails.splpujas.split("<BR>").join("\n") || '')}
                            onChange={handleSpltyPujasChange}
                            // onBlur={updateOtherFields}
                            onClick={showSplPujasModal}
                          />
                          {/* ) : (
                              templeDetails.splpujas && (
                                <Typography.Text className="typ">{templeDetails.splpujas}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null}
                      {editable || templeDetails.festivals ? ( */}
                      <div class="popup-bg" style={{ display: isSplPujasModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_special_pujas')}</span>
                            <div class="popup-close" onClick={handleSplPujasCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleSplPujasCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="Special Pujas"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={capitalizeFirstLetter(templeDetails.splpujas && templeDetails.splpujas.split("<BR>").join("\n") || '')}
                                    onChange={handleSpltyPujasChange} // Use updated onChange handler
                                    placeholder={t("error_msg_78")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateSplPujas(); setIsSplPujasModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_festival_details')}</Typography.Title>}
                          name="festival details"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_festival_details') })}
                            defaultValue={capitalizeFirstLetter(templeDetails.festivals && templeDetails.festivals.split("<BR>").join("\n") || '')}
                            onClick={showFestivalDetailsModal}

                          />
                          {/* ) : (
                              templeDetails.festivals && (
                                <Typography.Text className="typ">{templeDetails.festivals}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isFestivalDetailsModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_festival_details')}</span>
                            <div class="popup-close" onClick={handleFestivalDetailsCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleFestivalDetailsCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="festival details"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={templeDetails.festivals ? capitalizeFirstLetter(templeDetails.festivals.split("<BR>").join("\n")) : ''}
                                    onChange={handleFestivalDetailsChange} // Use updated onChange handler
                                    placeholder={t("error_msg_79")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateFestivals(); setIsFestivalDetailsModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null} */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_navagrahalu')}</Typography.Title>}
                          name="navagrahas"
                        >
                          <Radio.Group
                            value={navagrahas}
                            onChange={(e) => handleRadioChange1('navagrahas', e.target.value)}
                          >
                            <Radio value={true}>{t('Yes')}</Radio>
                            <Radio value={false}>{t('No')}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_gov_related')}</Typography.Title>}
                          name="gov"
                        >
                          <Radio.Group
                            value={gov}
                            onChange={(e) => handleRadioChange1('gov', e.target.value)}
                          >
                            <Radio value={true}>{t('Yes')}</Radio>
                            <Radio value={false}>{t('No')}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_onhill')}</Typography.Title>}
                          name="onhill"
                        >
                          <Radio.Group
                            value={onhill}
                            onChange={(e) => handleRadioChange1('onhill', e.target.value)}
                          >
                            <Radio value={true}>{t('Yes')}</Radio>
                            <Radio value={false}>{t('No')}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_swayambu')}</Typography.Title>}
                          name="swayambu"
                        >
                          <Radio.Group
                            value={swayambu}
                            onChange={(e) => handleRadioChange1('swayambu', e.target.value)}
                          >
                            <Radio value={true}>{t('Yes')}</Radio>
                            <Radio value={false}>{t('No')}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>

                      {/* {editable || templeDetails.howtoreach ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_how_to_reach')}</Typography.Title>}
                          name="howtoreach"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_how_to_reach') })}
                            defaultValue={templeDetails.howtoreach ? capitalizeFirstLetter(templeDetails.howtoreach.split("<BR>").join("\n")) : ''}
                            onChange={handleHowToReachChange}
                            onClick={showHowToreachModal}
                          />
                          {/* ) : (
                              templeDetails.howtoreach && (
                                <Typography.Text className="typ">{templeDetails && templeDetails.howtoreach.split("<BR>").join("\n")}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isHowToReachModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_how_to_reach')}</span>
                            <div class="popup-close" onClick={handleHowToReachCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleHowToReachCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="howtoreach"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea
                                    defaultValue={templeDetails.howtoreach ? capitalizeFirstLetter(templeDetails.howtoreach.split("<BR>").join("\n")) : ''}
                                    onChange={handleHowToReachChange}
                                    placeholder={t("error_msg_80")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateHowToReach(); setIsHowToReachModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null} */}

                      {/* {editable || templeDetails.accomodation ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_accommodation')}</Typography.Title>}
                          name="accomodation"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_accommodation') })}
                            defaultValue={templeDetails.accomodation ? capitalizeFirstLetter(templeDetails.accomodation.split("<BR>").join("\n")) : ''}
                            onChange={handleAccomodationChange}
                            onClick={showAccommodationModal}
                          />
                          {/* ) : (
                              templeDetails.accomodation && (
                                <Typography.Text className="typ">{templeDetails.accomodation}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isAccomodationModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_accommodation')}</span>
                            <div class="popup-close" onClick={handleAccomodationCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleAccomodationCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="accomodation"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={templeDetails.accomodation ? capitalizeFirstLetter(templeDetails.accomodation.split("<BR>").join("\n")) : ''}
                                    onChange={handleAccomodationChange} // Use updated onChange handler
                                    placeholder={t("error_msg_81")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateAccomodation(); setIsAccomodationModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null} */}

                      {/* {editable || templeDetails.architecture ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_architecture')}</Typography.Title>}
                          name="architecture"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_architecture') })}
                            defaultValue={capitalizeFirstLetter(templeDetails.architecture && templeDetails.architecture.split("<BR>").join("\n") || '')}
                            onChange={handleArchitectureChange}
                            onBlur={updateArchitecture}
                          />
                          {/* ) : (
                              templeDetails.architecture && (
                                <Typography.Text className="typ">{templeDetails.architecture}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isArchitectureModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_architecture')}</span>
                            <div class="popup-close" onClick={handleArchitectureCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleArchitectureCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="architecture"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={templeDetails.architecture ? capitalizeFirstLetter(templeDetails.architecture.split("<BR>").join("\n")) : ''}
                                    onChange={handleArchitectureChange} // Use updated onChange handler
                                    placeholder={t("error_msg_77")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateArchitecture(); setIsArchitectureModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null}

                      {editable || templeDetails.addtl_info ? ( */}
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_addtl_info')}</Typography.Title>}
                          name="addtl_info"
                        >
                          {/* {editable ? ( */}
                          <Input.TextArea
                            rows={3}
                            placeholder={t('enter_placeholder', { field: t('label_addtl_info') })}
                            defaultValue={capitalizeFirstLetter(templeDetails.addtl_info && templeDetails.addtl_info.split("<BR>").join("\n") || '')}
                            onChange={handleAddInfoChange}
                            onClick={showAddtnlInfoModal}
                          />
                          {/* ) : (
                              templeDetails.addtl_info && (
                                <Typography.Text className="typ">{templeDetails.addtl_info}</Typography.Text>
                              )
                            )} */}
                        </Form.Item>
                      </div>
                      <div class="popup-bg" style={{ display: isAddtnlInfoModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_addtl_info')}</span>
                            <div class="popup-close" onClick={handleAddtnlInfoCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleAddtnlInfoCancel}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <Form form={form}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                              >
                                <Form.Item
                                  name="Additional Info"
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <TextArea class="dialog-content-text-only"


                                    defaultValue={templeDetails.addtl_info ? capitalizeFirstLetter(templeDetails.addtl_info.split("<BR>").join("\n")) : ''}
                                    onChange={handleAddtnlInfoChange} // Use updated onChange handler
                                    placeholder={t("error_msg_82")}
                                  />
                                </Form.Item>
                              </Form>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                              {t("label_reset")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateAddtnlDetails(); setIsAddtnlInfoModalVisible(false); }}>
                              {t("label_save")}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* ) : null}


                      {editable && ( */}
                      <button
                        type="button"
                        class="button_move"
                        style={{ marginBottom: "5px" }}
                        onClick={handleLocationButtonClick}
                      >
                        {t('label_show_map')}
                      </button>
                      {/* )}
                      {editable ? ( */}
                      <div className='tiles-designs'>
                        {/* Latitude Field */}
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_latit')}</Typography.Title>}
                          name="lat"
                          rules={[
                            {
                              validator: validateLatitudeLongitude,
                            },
                          ]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_latit') })}
                            defaultValue={templeDetails.latit || ''}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9.-]/g, '')
                                .replace(/(?!^)-/g, '')
                                .replace(/(\..*)\./g, '$1');
                            }}
                            onBlur={handleSaveLocation}
                          />
                          {/* ) : (
                              null
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null}

                      {editable ? ( */}
                      <div className='tiles-designs'>
                        {/* Longitude Field */}
                        <Form.Item
                          label={<Typography.Title level={4}>{t('label_longi')}</Typography.Title>}
                          name="lon"
                          rules={[
                            {
                              validator: validateLatitudeLongitude,
                            },
                          ]}
                        >
                          {/* {editable ? ( */}
                          <Input
                            style={{ height: '35px' }}
                            placeholder={t('enter_placeholder', { field: t('label_longi') })}
                            defaultValue={templeDetails.longi || ''}
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9.-]/g, '')
                                .replace(/(?!^)-/g, '')
                                .replace(/(\..*)\./g, '$1');
                            }}
                            onBlur={handleSaveLocation}
                          />
                          {/* ) : (
                              null
                            )} */}
                        </Form.Item>
                      </div>
                      {/* ) : null} */}

                      {/* <div class="buttons-left-right-jumping mt-4">
                                                {editable && (

                                                    <a
                                                        type="primary"
                                                        className="button_move buttons-left-right-jumping"

                                                        onClick={updateFields}
                                                    >
                                                        {t('Update')}
                                                    </a>

                                                )}
                                            </div> */}

                    </div>
                  </Form>



                  <ResponsiveAd />

                  <div class="float-left w-100" ref={galleryRef}><h5 class="card-text-title">{t('label_galary')}
                    {/* <span>| {t('label_gallery_head')}</span> */}
                  </h5>
                  </div>
                  <div class="active-details">
                    <div class="tiles-designs" >
                      {token ? (
                        <div class="float-left w-100 d-block" text="Add Image" >
                          {previewImage ? (
                            <img src={previewImage} alt="Captured Image" />
                          ) : (
                            <>
                              {isMobile && (
                                <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                  {t('label_camera')}
                                </button>
                              )}
                            </>
                          )}
                          <button className="button_move" onClick={handleGalleryClick} style={{ marginLeft: '8px', marginBottom: '8px' }}>{t('label_gallery')}
                          </button>
                        </div>
                      ) : (
                        <div class="float-left w-100 d-block" text="Add Image" >
                          {previewImage ? (
                            <img src={previewImage} alt="Captured Image" />
                          ) : (
                            <>
                              {isMobile && (
                                <button className="button_move float-left" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                  {t('label_camera')}
                                </button>
                              )}
                            </>
                          )}
                          <button className="button_move float-left" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>


                        </div>)}

                      <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_crop_image')}</span>
                            <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              {/* <span className="close" onClick={handleImageCloseModal}>&times;</span> */}
                              {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <button class="button_move" onClick={handleCropAndUpload}>{t('label_crop_upload')}</button>

                          </div>
                          {loading && <Error />}
                          {hasError && <div className="error-message">Upload failed. Please try again.</div>}
                        </div>
                      </div>

                      <div className='detail-images ' style={{ marginTop: '2px', clear: 'both' }}>
                        {templeDetails.images != null && templeDetails.images.length > 0 ? (
                          <>
                            <div className="tiles-grid">
                              <Image.PreviewGroup allowClose={true} maskClosable={true}
                                mask={false}>
                                {templeDetails.images.map((image, index) => (
                                  <div key={index}>

                                    <div className="detail-container detail-container-image">
                                      <Image
                                        className="card-img"
                                        src={getMediumImageAPI() + image.imgpath}
                                        alt="images"
                                        style={{

                                        }}
                                        preview={{
                                          mask: (
                                            <div className="customize-close-icon" style={closeIconStyle}>
                                              {/* Add your custom close icon here */}
                                              {t('label_see_them')}
                                            </div>
                                          ),
                                        }}

                                      />
                                      {(userid === 'AD' || userid === 'AS') && (
                                        <>


                                          <Popconfirm
                                            title="Are you sure you want to delete?"
                                            onConfirm={() => onFinish(image.imgid)}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <span style={{ fontSize: '24px', display: 'inline-block' }}>
                                              <CloseCircleOutlined className='close-icon' />
                                            </span>
                                          </Popconfirm>

                                          <Popconfirm
                                            title="Are you sure you want to update?"
                                            onConfirm={() => updateImageFinish(selectedImgPath)}
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Checkbox
                                              className="close-icon1"
                                              checked={selectedImgPath === image.imgpath}
                                              onChange={() => handleCheckboxChange(image.imgid)}
                                            />
                                          </Popconfirm>



                                        </>
                                      )}
                                      {/* ) : (null)} */}

                                    </div>
                                  </div>
                                ))}
                                <div className="detail-container detail-container-image">
                                  {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                  {newImagePath ? (
                                    <CloseCircleOutlined
                                      className="close-icon"
                                      onClick={onFinish}
                                    />
                                  ) : (null)}
                                </div>
                              </Image.PreviewGroup>
                            </div>
                            {(userid === 'AD' || userid === 'AS') && (
                              <p style={{ marginTop: "10px" }}>
                                <strong>Note :</strong> Select checkbox to make it the default image. Click the delete icon to remove an image.
                              </p>
                            )}
                          </>
                        ) : (
                          <div className="detail-container detail-container-image">
                            <img
                              className="card-img"
                              alt="temples"
                              src={deftemples}
                              style={{
                                width: '20%',
                                height: '20%',
                                marginBottom: '2px',
                              }}
                            />

                          </div>

                        )}

                      </div>
                      {(userid === 'AD' || userid === 'AS') && (
                        <>
                          <div className="float-left w-100" ref={galleryRef}>
                            <h5 className="card-text-title">{t('label_inactive_images')}</h5>
                          </div>
                          <div className="tiles-designs">

                            {/* <div className="float-left w-100 d-block">
                              {previewImage ? (
                                <img src={previewImage} alt="Captured Image" />
                              ) : (
                                isMobile && (
                                  <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                    {t('label_camera')}
                                  </button>
                                )
                              )}
                              <button className="button_move" onClick={handleGalleryClick} style={{ marginLeft: '8px', marginBottom: '8px' }}>
                                {t('label_gallery')}
                              </button>
                            </div> */}



                            <div className="detail-images" style={{ marginTop: '2px', clear: 'both' }}>
                              {templePreviewImg && templePreviewImg.length > 0 ? (
                                <div className="tiles-grid">
                                  <Image.PreviewGroup allowClose={true} maskClosable={true} mask={false}>
                                    {templePreviewImg.map((image, index) => (
                                      <div key={image.imgid} className="detail-container detail-container-image" style={{ width: '57%' }}>
                                        {/* Activate Button Above the Image */}
                                        <Popconfirm
                                          title={`Are you sure you want to activate this image?`}
                                          onConfirm={() => handleActivateClick(image.imgid)}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <button className="activate-btn">
                                            Activate
                                          </button>
                                        </Popconfirm>

                                        {/* Image Below the Button */}
                                        <Image
                                          className="card-img"
                                          src={getMediumImageAPI() + image.imgpath}
                                          alt="images"
                                          preview={{
                                            mask: (
                                              <div className="customize-close-icon" style={closeIconStyle}>
                                                {t('label_see_them')}
                                              </div>
                                            ),
                                          }}
                                        />
                                        {/* Close Icon */}
                                        <Popconfirm title="Are you sure you want to delete?" onConfirm={() => onFinish(image.imgid)} okText="Yes" cancelText="No">
                                          <CloseCircleOutlined className="close-icon2" />
                                        </Popconfirm>
                                      </div>
                                    ))}
                                    {newImagePath && (
                                      <div className="detail-container detail-container-image">
                                        <Image className="card-img" src={newImagePath} alt="Selected Image" />
                                        {editable && <CloseCircleOutlined className="close-icon" onClick={onFinish} />}
                                      </div>
                                    )}
                                  </Image.PreviewGroup>
                                </div>
                              ) : (
                                <div className="detail-container detail-container-image">
                                  <h5 className="card-text-title">Inactive Images</h5>
                                  <img className="card-img" alt="temples" src={deftemples} style={{ width: '20%', height: '20%', marginBottom: '2px' }} />
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}


                    </div>

                  </div>


                  <div class="float-left w-100" ref={historyRef}><h5 class="card-text-title">{t('label_descr_history')}
                    {/* <span>| {t('label_gallery_hist')}</span> */}
                  </h5>
                  </div>



                  {userid === 'AD' || userid === 'AS' ? (
                    <>
                      <div className="active-details">
                        <div className="tiles-designs">
                          <Form.Item
                            label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                            name="history"
                            onClick={showHistoryModal}
                          >
                            {editable ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextArea
                                  placeholder={t("label_enter_history")}
                                  rows={4}
                                  value={history ? history : templeDetails.history}
                                  onChange={(e) => setHistory(e.target.value)} // Update actual history
                                  style={{ flex: 1 }}
                                />
                                <CaretLeftOutlined
                                  onClick={handleToggleHistory}
                                  style={{ cursor: 'pointer', margin: '0 10px' }}
                                />
                                <TextArea
                                  placeholder={t("label_enter_draft_history")}
                                  rows={4}
                                  value={draftHistory ? draftHistory : templeDetails.draft_history}
                                  onChange={(e) => setDraftHistory(e.target.value)} // Update draft history
                                  style={{ flex: 1 }}
                                />
                              </div>
                            ) : (
                              <div>
                                {templeDetails.history ? (
                                  <TextArea
                                    rows={4}
                                    value={templeDetails.history.split("<BR>").join("\n")}
                                    readOnly
                                    style={{ width: "100%", resize: "none" }}
                                  />
                                ) : (
                                  <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                                    {isMobile ? (
                                      <ShowMoreText charLimit={200} text={templeDetails.history ? templeDetails.history.split("<BR>").join("\n") : ''} />
                                    ) : (
                                      <ShowMoreText charLimit={500} text={templeDetails.history ? templeDetails.history.split("<BR>").join("\n") : ''} />
                                    )}
                                  </Typography.Text>
                                )}
                              </div>
                            )}
                          </Form.Item>

                          {!templeDetails.history && (
                            <div className="contribute">
                              <Typography.Text>{t("label_contribute")}</Typography.Text>
                              <div className="buttons-left-right-jumping mt-4 w-100">
                                <a className="button_move mt-2 mb-2 float-left" type="primary" onClick={showHistoryModal}>
                                  {t("label_contribute_hist")}
                                </a>
                              </div>
                            </div>
                          )}

                          <div className="buttons-left-right-jumping mt-3">
                            {editable && isHistoryUpdated ? (
                              <>
                                <a className="button_move" style={{ marginRight: "5px" }} onClick={clearHistory}>
                                  {t("label_clear")}
                                </a>
                                <a className="button_move">
                                  {t("label_update")}
                                </a>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div class="popup-bg" style={{ display: isHistoryModalVisible ? 'flex' : 'none' }}>
                          <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                              <span class="popup-main-header-text">{t('label_history')}</span>
                              <div class="popup-close" onClick={handleHistoryCancel}><i class="fa-solid fa-xmark"></i></div>
                              <div class="popup-back" onClick={handleHistoryCancel}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                              <div class="d-block htinner-popup-main-container">
                                <Form form={form}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                >
                                  <Form.Item
                                    name="History"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <TextArea class="dialog-content-text-only"
                                      defaultValue={templeDetails.history ? capitalizeFirstLetter(templeDetails.history.split("<BR>").join("\n")) : ''}
                                      onChange={handleHistoryChange}
                                      placeholder={t("error_msg_04")}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            </div>
                            <div class="popup-main-footer">
                              <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                {t("label_reset")}
                              </a>
                              <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateHistory(); setIsHistoryModalVisible(false); }}>
                                {t("label_save")}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="tiles-designs">
                          <Form.Item
                            label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                            name="descr"
                            onClick={showDescrModal}
                          >
                            {editable ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextArea
                                  placeholder={t("label_enter_description")}
                                  rows={4}
                                  value={description ? description : templeDetails.descr}
                                  onChange={(e) => setDescription(e.target.value)} // Update actual description
                                  style={{ flex: 1 }}
                                  onClick={showDescrModal}
                                />
                                <CaretLeftOutlined
                                  onClick={handleToggleDescription}
                                  style={{ cursor: 'pointer', margin: '0 10px' }}
                                />
                                <TextArea
                                  placeholder={t("label_enter_draft_description")}
                                  rows={4}
                                  value={draftDescription ? draftDescription : templeDetails.draft_descr}
                                  onChange={(e) => setDraftDescription(e.target.value)} // Update draft description
                                  style={{ flex: 1 }}
                                />
                              </div>
                            ) : (
                              <div>
                                {templeDetails.descr ? (
                                  <TextArea
                                    rows={4}
                                    value={templeDetails.descr.split("<BR>").join("\n")}
                                    readOnly
                                    style={{ width: "100%", resize: "none" }}
                                  />
                                ) : (
                                  <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                                    {isMobile ? (
                                      <ShowMoreText charLimit={200} text={templeDetails.descr ? templeDetails.descr.split("<BR>").join("\n") : ''} />
                                    ) : (
                                      <ShowMoreText charLimit={500} text={templeDetails.descr ? templeDetails.descr.split("<BR>").join("\n") : ''} />
                                    )}
                                  </Typography.Text>
                                )}
                              </div>
                            )}
                          </Form.Item>

                          {!templeDetails.descr && (
                            <div className="contribute">
                              <Typography.Text>{t("label_contribute_det")}</Typography.Text>
                              <div className="buttons-left-right-jumping mt-2 w-100">
                                <a
                                  className="button_move mt-3 mb-2 float-left"
                                  type="primary"
                                  onClick={showDescrModal}
                                >
                                  {t("label_contribute_descr")}
                                </a>
                              </div>
                            </div>
                          )}

                          <div className="buttons-left-right-jumping mt-3">
                            {editable && isDescriptionUpdated ? (
                              <>
                                <a
                                  className="button_move"
                                  style={{ marginRight: "5px" }}
                                  onClick={clearDescription}
                                >
                                  {t("label_clear")}
                                </a>
                                <a
                                  className="button_move"
                                  onBlur={() => updateDescr(description)}
                                >
                                  {t("label_update")}
                                </a>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div class="popup-bg" style={{ display: isDescrModalVisible ? 'flex' : 'none' }}>
                          <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                              <span class="popup-main-header-text">{t('label_details')}</span>
                              <div class="popup-close" onClick={handleDescrCancel}><i class="fa-solid fa-xmark"></i></div>
                              <div class="popup-back" onClick={handleDescrCancel}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                              <div class="d-block htinner-popup-main-container">
                                <Form form={form}
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24 }}
                                >
                                  <Form.Item
                                    name="Details"
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                  >
                                    <TextArea class="dialog-content-text-only"
                                      defaultValue={templeDetails.descr ? capitalizeFirstLetter(templeDetails.descr.split("<BR>").join("\n")) : ''}
                                      onChange={handleDescrChange} // Use updated onChange handler
                                      placeholder={t("error_msg_83")}
                                    />
                                  </Form.Item>
                                </Form>
                              </div>
                            </div>
                            <div class="popup-main-footer">
                              <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                {t("label_reset")}
                              </a>
                              <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateDescr(); setIsDescrModalVisible(false); }}>
                                {t("label_save")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="active-details">
                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                          name="history"
                        >

                          {editable ? (
                            <TextArea
                              placeholder={t("label_enter_history")}
                              rows={4}
                              defaultValue={templeDetails.history}
                              onChange={handleHist}
                            />
                          ) : (
                            <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                              {isMobile ? (
                                <ShowMoreText charLimit={200} text={templeDetails.history ? templeDetails.history.split("<BR>").join("\n") : ''} />
                              ) : (
                                <ShowMoreText charLimit={500} text={templeDetails.history ? templeDetails.history.split("<BR>").join("\n") : ''} />
                              )}
                            </Typography.Text>
                          )}

                          <div className='contribute' >
                            <br />
                            <Typography.Text className='contribute-warning'>{t("label_contribute")}</Typography.Text>
                            <div class="buttons-left-right-jumping mt-4 w-100">
                              <a className="button_move mt-2 mb-2 float-left" type="primary" onClick={showHistoryModal}>
                                {t("label_contribute_hist")}
                              </a>
                            </div>
                          </div>

                        </Form.Item>
                        <div class="buttons-left-right-jumping mt-3">
                          {editable && templeDetails.history ? (

                            <a type="primary" className="button_move" onClick={updateHist}>
                              {t("label_update")}
                            </a>
                          ) : (null)}
                        </div>
                      </div>

                      <div className="tiles-designs">
                        <Form.Item
                          label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                          name="descr"
                        >

                          {editable ? (
                            <TextArea
                              placeholder={t("label_enter_desscription")}
                              rows={4}
                              defaultValue={templeDetails.descr}
                              onChange={handleDescr}
                            />
                          ) : (
                            <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                              {isMobile ? (
                                <ShowMoreText charLimit={200} text={templeDetails.descr ? templeDetails.descr.split("<BR>").join("\n") : ''} />
                              ) : (
                                <ShowMoreText charLimit={500} text={templeDetails.descr ? templeDetails.descr.split("<BR>").join("\n") : ''} />
                              )}
                            </Typography.Text>
                          )}

                          <div className='contribute' >
                            <br />
                            <Typography.Text className='contribute-warning'>{t("label_contribute_det")}</Typography.Text>
                            <div class="buttons-left-right-jumping mt-2 w-100"><a className="button_move mt-3 mb-2 float-left" type="primary" onClick={showDescrModal} >
                              {t("label_contribute_descr")}
                            </a>
                            </div>
                          </div>

                        </Form.Item>
                        <div class="buttons-left-right-jumping mt-3">
                          {editable && templeDetails.descr ? (
                            <a type="primary" className="button_move" onClick={updateDescr}>
                              {t("label_update")}
                            </a>
                          ) : (null)}
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="popup-bg" style={{ display: isHistoryModalVisible ? 'flex' : 'none' }}>
                    <div class="popup-main animate__animated animate__fadeInUp ">
                      <div class="popup-main-header">
                        <span class="popup-main-header-text"> History</span>
                        <div class="popup-close" onClick={handleHistoryCancel}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={handleHistoryCancel}><i class="fa-solid fa-chevron-left"></i></div>
                      </div>
                      <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                          <Form form={form}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Form.Item
                              name="history"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <TextArea class="dialog-content-text-only"



                                onChange={handleHist} // Use updated onChange handler
                                placeholder={t("error_msg_04")}
                              />
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      <div class="popup-main-footer">
                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                          {t("label_reset")}
                        </a>
                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateHist(); setIsHistoryModalVisible(false); }}>
                          {t("label_save")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="popup-bg" style={{ display: isDescrModalVisible ? 'flex' : 'none' }}>
                    <div class="popup-main animate__animated animate__fadeInUp ">
                      <div class="popup-main-header">
                        <span class="popup-main-header-text"> Description</span>
                        <div class="popup-close" onClick={handleDescrCancel}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={handleDescrCancel}><i class="fa-solid fa-chevron-left"></i></div>
                      </div>
                      <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                          <Form form={form}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}

                          >
                            <Form.Item
                              name="descr"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <TextArea


                                onChange={handleDescr}
                                placeholder={t("error_msg_01")}

                              />
                            </Form.Item>


                          </Form>
                        </div>
                      </div>
                      <div class="popup-main-footer">
                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                          {t("label_reset")}
                        </a>
                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateDescr(); setIsDescrModalVisible(false); }}>
                          {t("label_save")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
            }
            <FloatButton.BackTop />

          </div>

          <Footer />
        </div>

      </div >
    </>
  )
}

export default EditTemples;