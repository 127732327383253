import React, { Component, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { getLatestTempleDetails, getLatestTempleDetailsError, getLatestTempleDetailsLoading, getNearestError, getNearestList, getNearestLoading, getTempleBooksError, getTempleBooksList, getTempleBooksLoading, getTempleDetails, getTempleDetailsError, getTempleDetailsInOtherLang, getTempleDetailsLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from "../../../../redux/selectors/selectors";
import { getImageAPI, getMediumImageAPI } from "../../../../http-common";
import { retrieveTempleDetails, fetchTempleDetails, postUploadImageRequest, editAvatar, editTempleStatus, editTempleImageStatus, postImage, editTempleFields, fetchNearest, postFavourites, removeFavourites, fetchTempleBooks, fetchTempleFavourites, fetchLatestTempleDetails, deleteTempleImage, editTempleLocation, editTempleHist, editTempleDescr, fetchTempleDetailsInOtherLang, postTranslate } from '../../../../redux/actions/acions';
import Item from 'antd/es/list/Item';
import { Form, Carousel, Typography, Row, Col, Upload, Button, message, FloatButton, Modal, Image, Switch, Select, Breadcrumb, Card, Input, Popconfirm, Progress, Spin } from 'antd';
import { Link } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { CloseCircleOutlined, } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import Error from '../error';
import Footer from '../footer/footer';
import statesData from '../../states.json';
import { GoogleMap, Marker } from '@react-google-maps/api';
import useScreenWidth from '../Header/useScreenWidth';
import { Option } from 'antd/es/mentions';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import AP_LANGUAGES from "../../../common/appLangs"

const { Title } = Typography;

function LangTranslation() {
    const [editableStr, setEditableStr] = useState('');
    //const [selectedDeityImage, setSelectedDeityImage] = useState(deityImages[templeDetails.maindeity]);
    const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const [did, setDid] = useState(0);
    const [showMaps, setShowMaps] = useState(false)
    const { t } = useTranslation();
    const [enableModal, setEnableModal] = useState(false);
const storedLanguage = localStorage.getItem('lng');
    const videoRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const token = localStorage.getItem('token');
    const states = statesData.states.map((state) => state.name);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(null);

    const startCamera = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    };

    const captureImage = () => {
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageSrc = canvas.toDataURL('image/jpeg');
        setPreviewImage(imageSrc);
        video.srcObject.getTracks().forEach((track) => track.stop());
    };

    const deleteImage = () => {
        setPreviewImage(null);
        startCamera();
    };

    const [messageApi, contextHolder] = message.useMessage();
    // const failure = (msg) => {
    //     messageApi.open({
    //         type: 'error',
    //         content: msg,
    //     });
    // };

    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const [newImagePath, setNewImagePath] = useState('');

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        newImagePath = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const success = (tname) => {
        messageApi.open({
            type: 'success',
            content: `Added ${tname} to favorites`,
        });
    };
    const warning = (tname) => {
        messageApi.open({
            type: 'warning',
            content: `Removed ${tname} from favourites`,
        });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: t('label_loginerror'),
        });
    };

    const saveSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'SUCCESSFULLY EDITED',
        });
        //form.resetFields()
    };
    const saveWarning = (msg) => {
        messageApi.open({
            type: 'warning',
            content: msg,
        });
    };


    const dispatch = useDispatch()

    const { tid } = useParams()

    const { tDetails, tLoading, tError } = useSelector(state => ({
        tDetails: getTempleDetails(state),
        tLoading: getTempleDetailsLoading(state),
        tError: getTempleDetailsError(state)
    }))
    

    useEffect(() => {
        dispatch(fetchTempleDetails(storedLanguage, tid))
    }, [storedLanguage])

    const FORM_FIELDS = [
        { label: "label_temple_name", name: "tname", type: "input", rules: [{ required: editable, message: t("error_msg_07") }] },
        { label: "label_main_deity", name: "deityname", type: "input", rules: [{ required: editable, message: t("error_msg_07") }] },
        { label: "label_other_deities", name: "otherdeities", type: "input" },
        { label: "label_village_area", name: "addr1", type: "input" },
        { label: "label_addr", name: "addr2", type: "input" },
        { label: "label_priest_nam", name: "priestname", type: "input" },
        // { label: "label_priest_phone_no", name: "priestphoneno", type: "input" },
        { label: "label_timings", name: "timings", type: "textarea" },
        { label: "label_daily_pujas", name: "dailypujas", type: "textarea" },
        { label: "label_special_pujas", name: "splpujas", type: "textarea" },
        { label: "label_festival_details", name: "festivals", type: "textarea" },
        { label: "label_how_to_reach", name: "howtoreach", type: "textarea" },
        { label: "label_accommodation", name: "accomodation", type: "textarea" },
        { label: "label_addtl_info", name: "addtl_info", type: "textarea" },
        { label: "label_architecture", name: "architecture", type: "textarea" },
        // { label: "label_email", name: "email", type: "input" },
        // { label: "label_phone_no", name: "phone", type: "input" },
        // { label: "label_website", name: "website", type: "input" },
    ];

    const [selectedLang, setSelectedLang] = useState(localStorage.getItem('selectedLang')|| 'English'); // Default to English

    const tDetailsEng = useSelector(state => getTempleDetails(state, 5)) || {};; // Always fetch English details with value 5
    const tDetailsTel = useSelector(state => getTempleDetailsInOtherLang(state, selectedLang === 'English' ? 5 : selectedLang)) || {};; // Fetch details based on selected language
    const { tlLoading, tlError } = useSelector(state => ({
        tlLoading: getTempleDetailsLoading(state),
        tlError: getTempleDetailsError(state)
    }));

    useEffect(() => {
        // Fetch English details by default
        dispatch(fetchTempleDetails(5, tid));
    }, [tid, dispatch, selectedLang, userid]);

    useEffect(() => {
        // Fetch English details by default
        dispatch(fetchTempleDetailsInOtherLang(selectedLang ? selectedLang : 5, tid));
    }, [tid, dispatch, selectedLang, userid]);



    const handleChange = (value) => {
        localStorage.setItem('selectedLang', value);
        setSelectedLang(value); // Update selected language
        // Fetch details based on the newly selected language
        dispatch(fetchTempleDetailsInOtherLang(value ? value : 5, tid));
    };

    useEffect(() => {
        const savedLang = localStorage.getItem('selectedLang');
        if (savedLang) {
            setSelectedLang(savedLang);
        }
    }, []);


    const { latestDetails, latestLoading, latestError } = useSelector(state => ({
        latestDetails: getLatestTempleDetails(state),
        latestLoading: getLatestTempleDetailsLoading(state),
        latestError: getLatestTempleDetailsError(state)
    }))
    

    const getToken = Boolean(localStorage.getItem('token'));


    const templeDetails = (tLoading || tDetails == null) ? {} : tDetails
    const latestTempleDetails = (latestLoading || latestDetails == null) ? {} : latestDetails



    const [showAllImages, setShowAllImages] = useState(false);

    const handleImageClick = () => {
        // if (enableModal) {
        setShowAllImages(true);
        // }
    };



    const userid = localStorage.getItem('urole')


    const [showCameraModal, setShowCameraModal] = useState(false);


    const onFinish = (imgid) => {
        const data = {
            'active': false
        };
        dispatch(deleteTempleImage(templeDetails.tid, imgid, data));
    };

    const [activeButton, setActiveButton] = useState('Details'); // Initialize with the default active button
    const [activeSection, setActiveSection] = useState('Details');
    const [activeLibrary, setActiveLibrary] = useState('Video');
    const [activeLib, setActiveLib] = useState('Video');
    const [formData, setFormData] = useState({
        "festivals": templeDetails.festivals || '',
        "splpujas": templeDetails.splpujas || '',
        "timings": templeDetails.timings || '',
        "howtoreach": templeDetails.howtoreach || '',
        "priestname": templeDetails.priestname || '',
        "priestphoneno": templeDetails.priestphoneno || '',
        "website": templeDetails.website || '',
        "email": templeDetails.email || '',
        "phoneno": templeDetails.phone || '',
        "inagurated": templeDetails.inagurated || '',
        'deityname': templeDetails.deityname || '',
        'otherdeities': templeDetails.otherdeities || '',
        'dailypujas': templeDetails.dailypujas || '',
        'accomodation': templeDetails.accomodation || '',

    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails]);



    const [currentLocation, setCurrentLocation] = useState(null);
    const [lat, setLat] = useState(null); // Latitude state variable
    const [lon, setLon] = useState(null); // Longitude state variable
    const [showMap, setShowMap] = useState(false);
    const [mapKey, setMapKey] = useState(1);
    const [isRightClick, setIsRightClick] = useState(false);
    const [locationSelected, setLocationSelected] = useState(false);
    const [markerVisible, setMarkerVisible] = useState(false);
    const indiaCenter = { lat: 20.5937, lng: 78.9629 };
    const [descr, setDescr] = useState(null);
    const [history, setHistory] = useState(null);

    const customButtonStyle = {
        marginBottom: '10px',
        marginRight: '20px'
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    setLat(position.coords.latitude); // Update latitude state
                    setLon(position.coords.longitude); // Update longitude state
                    setShowMap(true);
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.warn('Geolocation is not supported by this browser.');
        }
    };

    const handleGetLatLonFromMap = () => {
        if (currentLocation) {
            // Update latitude and longitude state variables
            setLat(currentLocation.lat.toFixed(6));
            setLon(currentLocation.lng.toFixed(6));
            form.setFieldsValue({
                lat: currentLocation.lat.toFixed(6),
                lon: currentLocation.lng.toFixed(6),
            });

            // Hide the map and set the locationSelected flag to true
            setShowMap(false);
            setLocationSelected(true);
            setCurrentLocation(null); // Reset currentLocation
            setLat(null); // Reset latitude state
            setLon(null); // Reset longitude state
            setShowMap(false); // Show the map
            //setShowResults(false); // Hide the results
            setMapKey((prevKey) => prevKey + 1); // Change the key to re-mount the GoogleMap component
        }
    };

    const handleMarkerDragEnd = (e) => {
        setCurrentLocation({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        });

        // Update latitude and longitude state variables
        setLat(e.latLng.lat().toFixed(6));
        setLon(e.latLng.lng().toFixed(6));
    };


    const handleLocationButtonClick = () => {
        setShowMap(!showMap);
        setMarkerVisible(true);
        setLocationSelected(false);
    };

    const handleSaveLocation = (values) => {
        //const values = Form.getFieldsValue();
        const dataToSend = {
            "latit": parseFloat(values.lat),
            "longi": parseFloat(values.lon),
        };
        dispatch(editTempleLocation(templeDetails.tid, dataToSend));
    };

    const handleDescr = (e) => {
        setDescr(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateDescr = () => {
        dispatch(editTempleDescr(storedLanguage, templeDetails.tid, { descr: descr }, success, failure));
    };


    const handleHist = (e) => {
        setHistory(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateHist = () => {
        dispatch(editTempleHist(storedLanguage, templeDetails.tid, { history: history }, success, failure));
    };


    // const [activeButton, setActiveButton] = useState('Details');
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));


    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        setButtonText(t('edit'));
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: templeDetails.deityname });
            setFormData({ ...formData, otherdeities: templeDetails.otherdeities });
        } else {
            setButtonText(t('edit'));
        }
        setEditable(!editable);
    };

    // Function to handle the "Save" button click
    const handleSaveClick = () => {
        form.validateFields()
            .then(async (values) => {
                // Extract only the values corresponding to the selected language
                const filteredValues = {};
                Object.keys(values).forEach(key => {
                    if (selectedLang === '1' && key.endsWith('_tel')) {
                        filteredValues[key] = values[key];
                    }
                    // Add other conditions if needed for different languages
                    // For example, if you have a suffix for Hindi or other languages
                });

                // If no data fields are present, log an error
                if (Object.keys(filteredValues).length === 0) {
                    console.error('No data fields to send to the API');
                    alert('No data fields to send to the API');
                    return;
                }

                try {
                    const response = await dispatch(editTempleFields(selectedLang, tid, templeDetails.ludt, filteredValues, saveSuccess, saveWarning));

                    if (response.error) {
                        console.error('Edit temple fields error:', response.error);
                        alert('An error occurred while saving: ' + response.error.message);
                    } else {
                        dispatch(fetchLatestTempleDetails(storedLanguage, tid));
                        setEditable(false);
                        setButtonText(t('edit'));
                    }
                } catch (error) {
                    console.error('Error during save operation:', error);
                    alert('An error occurred while saving: ' + error.message);
                }
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo); // Debugging line
                alert('Validation failed: ' + errorInfo.message);
            });
    };








    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    const handleLibraryChange = (library) => {
        setActiveLibrary(library);
        setActiveSection('Library');
    };

    const location = useLocation();
    const navigate = useNavigate();
    // Extract the current pathname
    const [previousPageName, setPreviousPageName] = useState('');

    // const location = useLocation();

    useEffect(() => {
        const previousLocation = new URLSearchParams(window.location.search).get('prevPage');
        setPreviousPageName(getPreviousPageName(previousLocation));
    }, []);


    const getPreviousPageName = (previousLocation) => {
        if (!previousLocation) {
            return 'Unknown Page';
        }

        const paths = previousLocation.split('/').filter((path) => path !== '');
        const lastPath = paths[paths.length - 1];

        switch (lastPath) {
            case 'nearby':
                return 'NearBy Temples'; // Replace with actual logic to get the name
            case 'searchFavourite':
                return 'Favourite Temples';
            case 'recentlyViewed':
                return 'Recently Viewed';
            case 'contribution':
                return 'My Contributions';
            case 'templesList':
                return 'Famous Temples'; // Replace with actual logic to get the name
            case 'templeGroup':
                return 'state';
            case 'templeGroup':
                return 'Temple Group';
            case '108 Divya Desams':
                return '108 Divya Desams';
            case '51 Shakthi Peethalu':
                return '51 Shakthi Peethalu';
            case 'Jyotir Lingas':
                return 'Jyotir Lingas';
            case 'templeDashboard':
                return 'Temple Dashboard';
            default:
                return 'Unknown Page';
        }
    };

    const filterId = location.state ? location.state.filterId : null;

    function navigateBackToTemplesList() {
        navigate(-1);
    }

    const transSuccess = () => {
        showToast('success', t('label_translate_sucess'))
    };
    const transFailure = (msg) => {
        showToast('failure', msg)
    };

    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    function postTranslat() {
        dispatch(postTranslate(storedLanguage, tid, selectedLang, transSuccess, transFailure))
        // window.location.reload()
    }

    const handleTranslateClick = async () => {
        setIsLoading(true);
        setProgress(30); // Initial progress state (you can adjust this logic)
        try {
            await postTranslat(5, tid, selectedLanguage);
            setProgress(100); // Complete progress state
        } catch (error) {
            console.error('Translation failed:', error);
        } finally {
            setTimeout(() => setIsLoading(false), 500); // Hide progress bar after a short delay
        }
        // window.location.reload()
    }



    // Use navigate to go back to the previous page
    // const handleGoBack = () => {
    //     navigate(-1);
    // };

    let link = <a href="https://ebooks.tirumala.org/downloads/maha_bharatham_vol_1_adi_parvam_p-1.pdf" target="_blank" rel="noopener noreferrer">More Info</a>
    const formattedTimings = templeDetails && templeDetails.timings ? templeDetails.timings.replace(/<br>/g, '\n') : '';
    const formattedFestivals = templeDetails && templeDetails.festivals ? templeDetails.festivals.replace(/<br>/g, '\n') : '';
    const formattedHistory = templeDetails && templeDetails.history ? templeDetails.history.replace(/<br>/g, '\n') : '';
    const formattedDescr = templeDetails && templeDetails.descr ? templeDetails.descr.replace(/<br>/g, '\n') : '';
    const formattedDescri = tDetailsTel && tDetailsTel.descr ? tDetailsTel.descr.replace(/<br>/g, '\n') : '';
    const formattedHist = tDetailsTel && tDetailsTel.history ? tDetailsTel.history.replace(/<br>/g, '\n') : '';

    const handleCameraClick = () => {
        // Handle the camera button click
    };


    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                dispatch(postUploadImageRequest(tid, formData, success, failure));
                // Simulating an asynchronous upload process
                setTimeout(() => {
                    // Get the URL or path of the uploaded image and update the state
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                }, 1000); // Simulating a delay for upload

                event.target.value = null; // Clear the input to allow selecting the same file again
            }
        });

        document.body.appendChild(fileInput);
        fileInput.click();
    };




    function Tooltip({ text, children }) {
        const [showButtons, setShowButtons] = useState(false);

        const handleMouseEnter = () => {
            setShowButtons(true);
        };

        const handleMouseLeave = () => {
            setShowButtons(false);
        };

        return (
            <div
                className="tooltip-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="tooltip-text">{text}</div>
                {showButtons && <div className="tooltip-buttons">{children}</div>}
            </div>
        );
    }

    const [modalVisible, setModalVisible] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const closeIconStyle = {
        // Add styles to make the close icon visible
        color: '#ffc107', // You can customize the color
        fontSize: '20px', // You can adjust the font size
    };

    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const detailsRef = useRef(null);
    const galleryRef = useRef(null);
    const historyRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('label_loginerror.');
    };

    const handleStateChange = (value) => {
        // Update the selected state and list of cities
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(newState);
        setCities(newState ? newState.cities : []);
    };

    useEffect(() => {
        if (selectedState) {
            form.setFieldsValue({ city: selectedState.cities[0] });
        }
    }, [selectedState]);

    const screenWidth = useScreenWidth();
    const isMobile = screenWidth <= 768;

    const getDetailsByLang = (details, name) => {
        return details[name] || '';
    };

    const detailsByLanguage = selectedLanguage === 'en' ? tDetailsEng : tDetailsTel;

    const handleLanguageChange = (value) => {
        i18next.changeLanguage(value);
        setSelectedLanguage(value);
    };

    return (
        <>
            {contextHolder}
            
            <div className='mainsec'>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                    <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">{t('Home')}</span></Link> -
                    <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-active-link">{t("label_temples_list")}</span></Link> -
                    <span className="bc-active-link" onClick={() => navigateBackToTemplesList(filterId)}>
                        {t("label_tpl_det")}
                    </span> -
                    <span className="bc-link">{t("label_lang_trans")}</span>
                </div>

                <div class={`bg-details-highlight `}>
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {templeDetails.tname}</div>

                        <div class="d-flex" >


                            <div class="" >

                                {editable && (
                                    <a   class="button_move"
                                        onClick={handleSaveClick}
                                        style={{ marginRight: '5px' }}
                                    >
                                        {t("label_save")}
                                    </a>
                                )}
                                {token ? (
                                    <>
                                        <a   class="button_move"
                                            onClick={editable ? handleCancelClick : handleEditClick}> {buttonText} </a></>
                                ) : (<><a   class="button_move"
                                    onClick={handleLinkClick}> {buttonText} </a></>)}
                                <a   class="button_move"
                                    onClick={() => navigateBackToTemplesList(filterId)}
                                    style={{ marginLeft: '5px' }}
                                >
                                    {t("back")}
                                </a>
                                {userid === 'AD' || 'AS' ? (
                                    <a
                                         
                                        className="button_move"
                                        onClick={handleTranslateClick}
                                        style={{ marginLeft: '5px' }}
                                    >
                                        {t("label_translate")}
                                    </a>
                                ) : (null)}


                            </div>
                        </div>
                    </div>
                    <div className="details-icons-main">
                        <div className="container details-icons">
                            <div className={`details-icons-list `} onClick={() => scrollToSection(detailsRef)}>
                                <i className="fa-regular fa-newspaper"></i>
                                <span className='names'>{t("label_details")}</span>
                            </div>
                            {/* <div className={`details-icons-list `} onClick={() => scrollToSection(galleryRef)}>
                                <i className="fa-regular fa-image"></i>
                                <span>{t('label_gallery')}</span>
                            </div> */}
                            <div className={`details-icons-list `} onClick={() => scrollToSection(historyRef)}>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>{t('label_descr_history')}</span>
                            </div>



                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    {isLoading && (
                        <div style={{ marginTop: '10px' }}>
                            <Error />
                        </div>
                    )}
                    <div className='container'>

                        {
                            tLoading ? <Error /> :
                                <div class="details-page"  >




                                    <div className="container" ref={detailsRef}>
                                        <h5 className="card-text-title">
                                            {t("label_details")} 
                                            {/* <span>| {t('label_detail_head')}</span> */}
                                        </h5>
                                        <div className='language1'>
                                            <Select
                                                // options={[{ value: '', label: t('label_select_language') }, ...AP_LANGUAGES]}
                                                className="select"
                                                value={selectedLang}
                                                onChange={handleChange}
                                                // style={{ width: '12%' }}
                                            >
                                                {AP_LANGUAGES.map((item) => (
                                                    <Option key={item.value} value={item.value}>
                                                        {isMobile ? item.label2 : item.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <Form
                                            form={form}
                                            initialValues={{
                                                ...tDetailsEng,
                                                ...Object.fromEntries(Object.entries(tDetailsTel).map(([key, value]) => [`${key}_tel`, value]))
                                            }}
                                        >
                                            <div className="temple-details-container">
                                                <div className="active-details">
                                                    <Form layout="vertical">
                                                        {FORM_FIELDS.every(({ name }) => !tDetailsEng[name] && !tDetailsTel[name]) && !editable ? (
                                                            <Typography.Text type="warning">
                                                                No details are available, please edit and provide history details.
                                                            </Typography.Text>
                                                        ) : (
                                                            <>
                                                                <div className="form-item-row1" >
                                                                    <div className="form-item-section">
                                                                        <Typography.Title level={5} className="language-label" style={{ color: "#ff7732" }}>Default Language: English</Typography.Title>
                                                                    </div>
                                                                    <div className="form-item-section">
                                                                        <Typography.Title level={5} className="language-label" style={{ color: "#ff7732" }}>Translated Language: {selectedLang === '1' ? "తెలుగు" : selectedLang === '5' ? "English" : "English"}</Typography.Title>
                                                                    </div>
                                                                </div>
                                                                {FORM_FIELDS.map(({ label, name, type, rules }) => (
                                                                    (tDetailsEng[name] || tDetailsTel[name] || editable) && (
                                                                        <div key={name} className="tiles-designs">
                                                                            <div className="form-item-row">
                                                                                <Typography.Title level={4} className="form-item-label">{t(label)}</Typography.Title>
                                                                                <div className="form-item-inputs">
                                                                                    {/* First row for default language (English) */}
                                                                                    <div className="form-item-section">
                                                                                        {editable ? (
                                                                                            type === 'textarea' ? (
                                                                                                <TextArea
                                                                                                    rows={4}
                                                                                                    style={{ height: '40px' }}
                                                                                                    placeholder={t('enter_placeholder', { field: t(label) })}
                                                                                                    defaultValue={tDetailsEng[name] || ''}
                                                                                                />
                                                                                            ) : (
                                                                                                <Input
                                                                                                    style={{ height: '35px', marginBottom: '20px', width: '100%' }}
                                                                                                    placeholder={t('enter_placeholder', { field: t(label) })}
                                                                                                    defaultValue={tDetailsEng[name] || ''}
                                                                                                />
                                                                                            )
                                                                                        ) : (
                                                                                            tDetailsEng[name] ? (
                                                                                                <Typography.Text level={5} className="typ">
                                                                                                    <div dangerouslySetInnerHTML={{ __html: tDetailsEng[name].replace(/(?:\r\n|\r|\n)/g, '<br>\n') }} />
                                                                                                </Typography.Text>
                                                                                            ) : (
                                                                                                <span>No data available</span>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                    {/* Second row for translated language (Telugu) */}
                                                                                    <div className="form-item-section">
                                                                                        {editable ? (
                                                                                            <Form.Item name={`${name}_tel`} rules={rules}>
                                                                                                {type === 'textarea' ? (
                                                                                                    <TextArea
                                                                                                        rows={4}
                                                                                                        style={{ height: '40px' }}
                                                                                                        placeholder={t('enter_placeholder', { field: t(label) })}
                                                                                                        defaultValue={tDetailsTel[name] || ''}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Input
                                                                                                        style={{ height: '35px', marginBottom: '20px', width: '100%' }}
                                                                                                        placeholder={t('enter_placeholder', { field: t(label) })}
                                                                                                        defaultValue={tDetailsTel[name] || ''}
                                                                                                    />
                                                                                                )}
                                                                                            </Form.Item>
                                                                                        ) : (
                                                                                            tDetailsTel[name] ? (
                                                                                                <Typography.Text level={5} className="typ">
                                                                                                    <div dangerouslySetInnerHTML={{ __html: tDetailsTel[name].replace(/(?:\r\n|\r|\n)/g, '<br>\n') }} />
                                                                                                </Typography.Text>
                                                                                            ) : (
                                                                                                <span>No data available</span>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </>
                                                        )}
                                                    </Form>
                                                </div>
                                            </div>



                                        </Form>








                                        {showMap && (
                                            <Modal
                                                // title="Select Location"
                                                visible={showMap}
                                                onCancel={() => setShowMap(false)}
                                                footer={[
                                                    <Button key="back" class="button_move" type="primary" onClick={() => setShowMap(false)} style={customButtonStyle} >
                                                        {t("label_cancel")}
                                                    </Button>,
                                                    <Button key="button" class="button_move" type="primary" onClick={handleGetCurrentLocation} style={customButtonStyle}>
                                                        Use Current Location
                                                    </Button>,
                                                    <Button key="submit" class="button_move" type="primary" onClick={handleGetLatLonFromMap} style={customButtonStyle}>
                                                        Use this Location
                                                    </Button>,
                                                ]}
                                            >
                                                <div class="diag-header">{t('Select Location')}
                                                    <span class="diag-close"><i class="fa-solid fa-xmark" onClick={() => setShowMap(false)}></i></span>
                                                </div>
                                                <GoogleMap
                                                    center={currentLocation || indiaCenter}
                                                    zoom={currentLocation ? 14 : 5}
                                                    mapContainerStyle={{ width: '100%', height: '400px' }}
                                                >
                                                    {currentLocation && markerVisible && (
                                                        <Marker
                                                            position={currentLocation}
                                                            draggable={true}
                                                            onDragEnd={handleMarkerDragEnd}
                                                        // visible={markerVisible}
                                                        />
                                                    )}
                                                </GoogleMap>
                                            </Modal>
                                        )}

                                        <div class="container" ref={historyRef}><h5 class="card-text-title">{t('label_descr_history')} <span>| {t('label_gallery_hist')}</span></h5>
                                        </div>
                                        <div>

                                            <div className="temple-details-container">
                                                <div className="active-details">
                                                    <Form layout="vertical">
                                                        {!formattedHistory && !formattedHist && !formattedDescr && !formattedDescri && !editable ? (
                                                            <Typography.Title level={5} type="warning" style={{ color: "#ff7732" }}>
                                                                No details are available, please edit and provide history details.
                                                            </Typography.Title>
                                                        ) : (
                                                            <>
                                                                {(!formattedHistory && !formattedHist && !editable) ? null : (
                                                                    <div className="tiles-designs">
                                                                        <Typography.Title level={4} className="form-item-label">{t("label_descr_history")}</Typography.Title>
                                                                        <div className="form-item-inputs">
                                                                            <div className="form-item-section">
                                                                                {editable ? (
                                                                                    <TextArea
                                                                                        style={{ height: '80px' }}
                                                                                        placeholder={t("Enter History")}
                                                                                        defaultValue={getDetailsByLang(tDetailsEng, 'history')}
                                                                                        onChange={handleHist}
                                                                                    />
                                                                                ) : (
                                                                                    <Typography.Text level={5} className="typ">
                                                                                        <div dangerouslySetInnerHTML={{ __html: formattedHistory }} />
                                                                                    </Typography.Text>
                                                                                )}
                                                                            </div>
                                                                            <div className="form-item-section">
                                                                                {editable ? (
                                                                                    <TextArea
                                                                                        style={{ height: '80px' }}
                                                                                        placeholder={t("Enter History")}
                                                                                        defaultValue={getDetailsByLang(tDetailsTel, 'history')}
                                                                                        onChange={handleHist}
                                                                                    />
                                                                                ) : (
                                                                                    <Typography.Text level={5} className="typ">
                                                                                        {formattedHist ? (
                                                                                            <div dangerouslySetInnerHTML={{ __html: formattedHist }} />
                                                                                        ) : (
                                                                                            <span>No data available</span>
                                                                                        )}
                                                                                    </Typography.Text>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {editable && (
                                                                    <div className="tiles-designs">
                                                                        <Form.Item>
                                                                            <button className="button_move" type="primary" onClick={updateHist}>
                                                                                {t("Update History")}
                                                                            </button>
                                                                        </Form.Item>
                                                                    </div>
                                                                )}

                                                                {(!formattedDescr && !formattedDescri && !editable) ? null : (
                                                                    <div className="tiles-designs">
                                                                        <Typography.Title level={4} className="form-item-label">{t('label_descr')}</Typography.Title>
                                                                        <div className="form-item-inputs">
                                                                            <div className="form-item-section">
                                                                                {editable ? (
                                                                                    <TextArea
                                                                                        style={{ height: '80px' }}
                                                                                        placeholder={t("Enter Description")}
                                                                                        defaultValue={getDetailsByLang(tDetailsEng, 'descr')}
                                                                                        onChange={handleDescr}
                                                                                    />
                                                                                ) : (
                                                                                    <Typography.Text level={5} className="typ">
                                                                                        <div dangerouslySetInnerHTML={{ __html: formattedDescr }} />
                                                                                    </Typography.Text>
                                                                                )}
                                                                            </div>
                                                                            <div className="form-item-section">
                                                                                {editable ? (
                                                                                    <TextArea
                                                                                        style={{ height: '80px' }}
                                                                                        placeholder={t("Enter Description")}
                                                                                        defaultValue={getDetailsByLang(tDetailsTel, 'descr')}
                                                                                        onChange={handleDescr}
                                                                                    />
                                                                                ) : (
                                                                                    <Typography.Text level={5} className="typ">
                                                                                        {formattedDescri ? (
                                                                                            <div dangerouslySetInnerHTML={{ __html: formattedDescri }} />
                                                                                        ) : (
                                                                                            <span>No data available</span>
                                                                                        )}
                                                                                    </Typography.Text>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {editable && (
                                                                    <div className="tiles-designs">
                                                                        <Form.Item>
                                                                            <button className="button_move" type="primary" onClick={updateDescr}>
                                                                                {t("Update Description")}
                                                                            </button>
                                                                        </Form.Item>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Form>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                        }
                        <FloatButton.BackTop />
                    </div>
                    <Footer />
                </div>
            </div >
        </>
    )
}

export default LangTranslation;