import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import i18next from 'i18next';
import Footer from '../pages/Homepage/footer/footer';
import { postPrIest, postPrest, postPriest, postPriestReq } from '../../redux/actions/acions';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';



const normFile = (e) => {

    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const AddPriestReq = () => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { id } = useParams()
    const { name } = useParams()
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const { TextArea } = Input;

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t(`label_addpriestreq_success`))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY SUBMITTED',
        // });
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t(`label_addpriestreq_failure`))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,

        // });
    };

    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    "pid": id,
                    "ctgry": values.ctgry,
                    "reqtype": values.reqtype,
                    "query": values.query
                };

                const success = () => {
                    navigate('/myReq');
                };

                const failure = (error) => {
                    console.error('Request failed:', error);
                };

                dispatch(postPriestReq(data, success, failure));
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });
    };

const storedLanguage = localStorage.getItem('lng');
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const handleChange = (value) => {
        localStorage.setItem('lng', value);
        setSelectedLanguage(value);
        // Make API request here with the selected language
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
        // setButtonText('Edit');
        // setEditable(false);
    };

    return (
        <>
            {contextHolder}

            <div className='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_add_priest_request')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> - <span className="bc-link">{t("label_add_priest_request")}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt-singleLine container">
                        <div class="header_txt"> {t('label_add_priest')}</div>
                        <div class="details-headertxt-singleLine-btns">
                            <a   class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t("label_cancel")}
                            </a>
                            <a   class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                                {t("label_save")}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages" onFinish={onFinish}

                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }} 
                        >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_add_priest_request")} <span>| priest ID, Category, Type etc .</span></h5></div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='pid'
                                    label={<Typography.Title level={4}>{t('label_priest_id')}</Typography.Title>}
                                >
                                    {(id === 0 || id === '0') ? (
                                        <Input style={{ height: '30px', width: '30.5vw' }} placeholder={t('label_enter_prname')} />
                                    ) : (
                                        <Typography.Title level={4}>{id}</Typography.Title>
                                    )}
                                </Form.Item>

                                <Form.Item
                                    name='name'
                                    label={<Typography.Title level={4}>{t('label_prname')}</Typography.Title>}
                                >
                                    {(name === '' || name === 0 || name === '0') ? (
                                        <Input style={{ height: '30px', width: '30.5vw' }} placeholder={t('label_enter_prname')} />
                                    ) : (
                                        <Typography.Title level={4}>{name}</Typography.Title>
                                    )}
                                </Form.Item>

                            </div>

                            <div class="tiles-designs" >
                                <Form.Item
                                    name='ctgry'
                                    label={<Typography.Title level={4}>{t('label_categ')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select placeholder={t('label_select_categ_type')}
                                        placeholderStyle={{ fontSize: '2px' }}>
                                        <Option value="">{t("-- Please select Category Type --")}</Option>
                                        <Select.Option value="1">{t('label_horo')}</Select.Option>
                                        <Select.Option value="2">{t('label_muhurtham')}</Select.Option>
                                        <Select.Option value="3">{t('label_puja')}</Select.Option>
                                        <Select.Option value="4">{t('label_homam')}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>


                            <div class="tiles-designs" >
                                <Form.Item
                                    name='reqtype'
                                    label={<Typography.Title level={4}>{t('label_req_type')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select placeholder={t('label_sel_req_type')}
                                        placeholderStyle={{ fontSize: '2px' }}>
                                        <Option value="">{t("-- Please select Request Type --")}</Option>
                                        <Select.Option value="J">{t('label_jathakam')}</Select.Option>
                                        <Select.Option value="V">{t('label_vratham')}</Select.Option>

                                        {/* <Option value="mantras">{("label_man")}</Option> */}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='query'
                                    label={<Typography.Title level={4}>{t('label_query')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} placeholder={t('label_enter_query')} />
                                </Form.Item>
                            </div>

                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>
        </>
    );
};
export default AddPriestReq;