import React, { useEffect, useState } from 'react'

import { Breadcrumb, Modal, Select, Skeleton, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import famousTemples from '../../../../assets/images/famous-temple.jpg'
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import Images from '../../../common/images';
import showToast from '../../../../utils/showToast';
import defImage from '../../../../assets/images/recentlyviewed.jpeg';
import favourite from '../../../../assets/images/favouritetemples.jpeg';
import templeSearch from '../../../../assets/images/temple-search.png';
import map from '../../../../assets/images/maprelated.jpeg';
import AP_LANGUAGES from "../../../common/appLangs"

const TempleCtgry = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };



    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };



    const { nList, nLoading } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));
    console.log(nList)

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups(storedLanguage));
    }, [storedLanguage]);

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);

    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', t('label_loginerror'));
    };

    const handleRecentlyViewed = () => {
        navigate('/recentlyViewed')
    };

    const recentlyViewed = [
        { id: 1, title: t("label_fam_temples"), image: famousTemples, link: "/templesList" },
        { id: 2, title: t("lable_recent_view"), image: defImage, link: "/recentlyViewed" },
        { id: 3, title: t("label_fav_temples"), image: favourite, link: "/searchFavourite" },
        { id: 4, title: t("label_temples_by_map"), image: map, link: "/nearby" }
    ];

    return (
        <div class="mainsec temple2-bg   ">
            <div className='breadcrum '>

                <div className='breadcrum-block'>
                    <div class="d-flex">
                        <Link to='/' className='search-back'><i class="fa-solid fa-angle-left"></i></Link>
                        <Breadcrumb
                            items={[
                                {

                                    title: <Link to='/'><span style={{ color: 'white' }}>Home</span></Link>
                                },
                                {
                                    title: (
                                        <>

                                            <span style={{ color: 'white' }}>{("label_temples_list")}</span>
                                        </>
                                    ),
                                },

                            ]}
                        />
                    </div>
                    <div className='Ind'>
                        <Typography.Text style={{ color: 'white' }}>Ind -  {selectedLanguage}</Typography.Text>
                        <span className="color-yellow" onClick={() => setDialogVisible(true)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                        </span>
                    </div>
                </div>


                <Modal
                    title={t("label_select_language")}
                    visible={dialogVisible}
                    onCancel={() => setDialogVisible(false)}
                    onOk={() => setDialogVisible(false)}

                >

                    <Select
                        value={selectedLanguage}
                        onChange={handleChange}
                        options={AP_LANGUAGES}
                    />
                </Modal>
            </div>

            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t("label_temples_list")}</span>
                </div>
                <div></div>
            </div>





            <div className=' mb-3 mt-3  breadcrumb-link container  justify-content-between align-items-center'>
                <div class=" white-space-pre  d-flex">
                    <span class="d-flex align-items-center">
                        <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link><span class="ml-2 mr-2"> - </span></span>
                    <span class="bc-link">{t("label_temples_list")}</span>
                </div>

                <div class="f2 font-weight-bold  container title-head-txt pageheader-buttons">
                    {token ? (
                        <Link to='/addPage'>
                            <button type="button" class="declineButton" title={t("add_temple")}>{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton" title={t("add_temple")}>{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                    {/* {token ? (
                        <Link to='/recentlyViewed'>
                            <button type="button" className="declineButton" title={t("lable_recent_view")} onClick={() => { handleRecentlyViewed() }}>
                                {t("lable_recent_view")} <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        </Link>
                    ) : <Link className="my-link2" onClick={handleLinkClick}>
                        <button type="button" class="declineButton" title={t("lable_recent_view")}>{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>}
                    <Link to='/nearby'>
                        <button type="button" class="declineButton" title={t("label_temples_by_map")}>{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'>
                            <button type="button" class="declineButton" title={t("page_title_fav")}>{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton " title={t("page_title_fav")}>{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )} */}
                </div>
                <div>

                </div>

            </div>
            <div class="mainsec-block">

                <div className="container">
                    <div className="container">
                        <h5 class="card-text-title">{t("Categories")}
                        </h5>
                    </div>
                          
                 
            <div class="temples-search-icon"><img src={templeSearch} />
            </div>

                    <div className="tiles-main">
                        {recentlyViewed.map((item) => (
                            <Link key={item.id} to={item.link}>
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" title={item.title} alt="temple-category" src={item.image} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{item.title}</span> <span>{t("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                        {nLoading ? (
                            <Skeleton active />
                        ) :
                            nList && nList
                                .filter(temple => !temple.group_name.startsWith("Temples in" || temple.gid === 507)&&temple.gid !== 123 && 
                                temple.gid !== 124)
                                .map((temple, index) => (
                                    <div className="tiles-design-temple-cat animate__animated animate__flipInX" key={index} onClick={() => {
                                        if (temple.gid === 125) {
                                            navigate('/outsideIndia');
                                        }
                                        else if (temple.group_name.toLowerCase() === 'remedy temples') {
                                            navigate('/remedytemples');
                                        }
                                        else if (temple.gid === 507) {
                                            navigate(`/inIndia/${temple.gid}`);
                                        }   
                                        else if(temple.gid < 100){
                                            navigate(`/subCategery/${temple.gid}`)
                                        }        
                                        else {
                                            fetchGroupTemples(temple.gid);
                                        }
                                    }}>
                                        <div className="title-img-bg ">
                                            <img className="card-img-temple-cat " alt="temples" src={Images[temple.imgpath] || defImage} />
                                        </div>
                                        <div className="tile-text-temple-cat">
                                            <span>{temple.group_name}</span> <span>{("label_temples_list")}</span>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>

                <Footer />
            </div>
        </div>



    )

}

export default TempleCtgry;