import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
    Tooltip,
    Popconfirm,
    Checkbox,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CloseCircleOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { editArchitecture, editResource, editTitle, editTxt, fetchBooksFavourites, fetchGodNames, fetchResource, fetchResourceDetails, postBooksFavourites, postLibraryUploadImageRequest, postResource, postTemples, removeBooksFavourites, retrieveGodNames, translateResourcelang } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceDetailsList, getResourceDetailsLoading, getResourceList } from '../../../../redux/selectors/selectors';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import 'moment/locale/en-gb';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import ShowMoreText from '../../../common/showMoreText';
import { getMediumImageAPI, getAudioAPI } from "../../../../http-common";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import deityImages from '../../../common/godImages';
import aum from '../../../../assets/icons/om.jpg'
import YouTube from './youtube';
import { Option } from 'antd/es/mentions';
import Error from '../error';
import ResponsiveAd from '../Advertisements/responsiveAd';
import AdBlock from '../Advertisements/adBlock';
import AP_LANGUAGES from '../../../common/appLangs';

const normFile = (e) => {

    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const EditResource = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { rid } = useParams()
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedLang, setSelectedLang] = useState();
    const [date, setDate] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [newImagePath, setNewImagePath] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const userrole = localStorage.getItem('urole');
    const [previewImage, setPreviewImage] = useState(null);
    const getToken = Boolean(localStorage.getItem('token'));
    const [refresh, setRefresh] = useState(false);
    const [name, setName] = useState(null);

    const handleImageLinkClick = () => {
        showToast('error', t(`Please login to upload image.`))
    };

    const handleCameraClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);

                dispatch(postLibraryUploadImageRequest(tDetails.res_type, rid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                    console.warn('Image uploaded successfully');
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            } else {
                console.warn('No file selected');
            }

            document.body.removeChild(fileInput); // Clean up the file input
        });


        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const [cropper, setCropper] = useState(null);
    const imageRef = useRef();

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result); // Show image in modal
                    setIsModalVisible(true); // Open modal
                };
                reader.readAsDataURL(selectedFile);
            }
        });

        fileInput.click();
    };


    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const localEnv = false;
    const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy(); // Destroy cropper instance when modal is closed
        }
    };
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleCropAndUpload = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData();
                formData.append('file', blob);
                setLoading(true);
                setHasError(false);
                // Replace the following line with your image upload logic
                dispatch(postLibraryUploadImageRequest(tDetails.res_type, rid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(blob);
                    setNewImagePath(uploadedImagePath);
                    handleImageCloseModal(); // Close modal after uploading
                    setLoading(false);
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                    setLoading(false);
                    setHasError(true);
                }));
            });
        }
    };

    useEffect(() => {
        if (isModalVisible && imageRef.current) {
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
                viewMode: 1,
                autoCropArea: 1, // Ensure the crop area covers the whole image
                responsive: true, // Ensure responsiveness
                scalable: false,
                zoomable: true,
                cropBoxResizable: true,
                minCropBoxHeight: 200, // Min height as per your style
                maxCropBoxHeight: 200, // Max height as per your style
                minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
                maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
            });
            setCropper(cropperInstance);
        }

        return () => {
            if (cropper) {
                cropper.destroy(); // Cleanup when the modal is closed or component unmounts
            }
        };
    }, [isModalVisible]);


    const closeIconStyle = {
        // Add styles to make the close icon visible
        color: '#ffc107', // You can customize the color
        fontSize: '20px', // You can adjust the font size
    };

    function Tooltip({ text, children }) {
        const [showButtons, setShowButtons] = useState(false);

        const handleMouseEnter = () => {
            setShowButtons(true);
        };

        const handleMouseLeave = () => {
            setShowButtons(false);
        };

        return (
            <div
                className="tooltip-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="tooltip-text">{text}</div>
                {showButtons && <div className="tooltip-buttons">{children}</div>}
            </div>
        );
    }

    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();

                const data = {
                    title: values.title,
                    author: values.author,
                    maindeity: values.maindeity,
                    src_url: values.src_url,
                    txt: values.txt,
                    res_type: values.res_type,
                    lang: values.lang,
                    publish_date: values.publish_date,
                    tag1: values.tag1,
                    tag2: values.tag2,
                    avatar: values.avatar || selectedDeity || form.getFieldValue('avatar'),
                    src: values.src,
                    img_path: values.img_path || null,
                    ctgry: 0
                };
                dispatch(editResource(rid, data, editSuccess, editFailure));

            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
            });

    };

const storedLanguage = localStorage.getItem('lng');
    // const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    // const handleChange = (value) => {
    //     localStorage.setItem('lng', value);
    //     setSelectedLanguage(value);
    //     // Make API request here with the selected language
    // };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])



    const [showAllImages, setShowAllImages] = useState(false);

    useEffect(() => {
        if (tDetails && tDetails.avatar !== undefined) {
            setSelectedDeity(tDetails.avatar);
        }
    }, [tDetails]);

    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (deityNumber) => {
        setSelectedDeity(deityNumber);
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_editresource_success'))
        // messageApi.open({
        //     type: 'success',
        //
        form.resetFields()
    };

    const titlesuccess = () => {
        showToast('success', t('label_edittitle_success'))
        form.resetFields()
    };

    const titlefailure = (msg) => {
        showToast('error', msg)
    };

    const txtsuccess = () => {
        showToast('success', t('label_edittxt_success'))
        form.resetFields()
    };

    const txtfailure = (msg) => {
        showToast('error', msg)
    };



    const handleTranslate = () => {
        const slang = (tDetails && tDetails.lang) || '';
        const tlang = selectedLang || '';
        dispatch(translateResourcelang(rid, { slang, tlang }, editSuccess, editFailure));
        setSelectedLang(undefined);
    };

    const failure = (error) => {
        let msg;
        if (error.response && error.response.data && error.response.data.error) {
            // If error.response exists, and data and error properties exist within it
            msg = error.response.data.error;
        } else if (error.message) {
            // If there's a message property in the error object
            msg = error.message;
        } else {
            // If none of the above conditions are met, fallback to a generic error message
            msg = "An error occurred";
        }

        messageApi.open({
            type: "failed",
            content: msg,
        });

        showToast('fail', t('label_editresource_failure'));
    };

    const editSuccess = () => {
        showToast('success', t('label_editresource_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        form.resetFields()
        setButtonText('Edit');
        setEditable(false);
    };

    const favSuccess = (tname) => {
        showToast('success', t(`Added ${tname} to favorites`))

    };

    const warning = (title) => {
        showToast('info', t(`Removed ${title} from favourites`))
        // messageApi.open({
        //   type: 'warning',
        //   content: `Removed ${title} from favourites`,
        // });
    };

    const editFailure = (msg) => {
        showToast('error', msg)
    };


    const token = localStorage.getItem('token');

    const { tDetails } = useSelector(state => ({
        tDetails: getResourceDetailsList(state),
    }))

    function formatBoldText(txt) {
        if (txt) {
            return <div dangerouslySetInnerHTML={{ __html: formatPlainText(txt) }} />;
        } else {
            return '';
        }
    }
    
    function formatPlainText(txt) {
        if (txt) {
            let allLines = txt.split("<BR>");
            return allLines.map(line => line.includes("<B>") ? `<p>${line}</p>` : `<p>${line}</p>`).join('');
        } else {
            return '';
        }
    }


    useEffect(() => {
        dispatch(fetchResourceDetails(storedLanguage, rid, failure));
    }, [storedLanguage, dispatch]);


    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails])

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        setEditable(false);
        setButtonText('Edit');
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));

        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('Please login to edit details.');
    };

    // useEffect(() => {
    //     if (tDetails && tDetails.publish_date) {
    //         setDate(moment(tDetails.publish_date)); // Ensure the date is formatted correctly
    //     }
    // }, [tDetails]);


    function toggleFavorite(tDetails) {
        if (getToken) {
            const templeId = rid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, tDetails.title);
                // warning(temple.tname);
                // Update favList by removing the temple ID
                // setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, tDetails.title);
                // success(temple.tname);
                // Update favList by adding the temple ID
                // setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }


    const [filled, setFilled] = useState({});

    function toggleFilled(rid) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [rid]: !prevFilled[rid]
        }));
    }
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);

    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getBooksFavouritesList(state),
        fLoading: getBooksFavouritesLoading(state),
        fError: getBooksFavouritesError(state),
    }));

    useEffect(() => {
        {
            getToken && (
                dispatch(fetchBooksFavourites())
            )
        }
    }, [getFavouriteTemples, refresh]);



    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
        }
    }, [fList]);


    useEffect(() => {
        if (getToken) {
            const cachedFavourites = JSON.parse(localStorage.getItem('Booksfavourites'));
            if (cachedFavourites) {
                setFavList(cachedFavourites);
            } else {
                dispatch(fetchBooksFavourites());
            }
        }
    }, [getToken]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
            localStorage.setItem('Booksfavourites', JSON.stringify(fList.fav_list));
        }
    }, [fList]);


    function addFavourites(rid, tname) {
        dispatch(postBooksFavourites(rid, failure))
        setFavList(prevFavList => [...prevFavList, rid]);
        localStorage.setItem('Booksfavourites', JSON.stringify([...favList, rid]));
        favSuccess(tname);
    }

    function deleteFavourites(deleteId, tname) {
        dispatch(removeBooksFavourites(deleteId, failure));
        setFavList(prevFavList => prevFavList.filter(favId => favId !== deleteId));
        localStorage.setItem('Booksfavourites', JSON.stringify(favList.filter(favId => favId !== deleteId)));
        warning(tname);
    }

    const [favList, setFavList] = useState([]);

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleName = (title) => {
        dispatch(editTitle(storedLanguage, rid, { title: title }, titlesuccess, titlefailure));
        setIsTitleChanged(false);
    };


    const handleTxt = (txt) => {
        dispatch(editTxt(storedLanguage, rid, { txt: txt }, txtsuccess, txtfailure));
        setIsTitleChanged(false);
    };


    const [isTxtChanged, setIsTxtChanged] = useState(false);
    const [isTitleChanged, setIsTitleChanged] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    function getYouTubeVideoId(url) {
        // This function extracts the YouTube video ID from a YouTube URL
        const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    return (
        <>
            {contextHolder}

            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_view_lib_details')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> - <span className="bc-active-link" onClick={handleGoBack}> {t('label_resource')} </span> - <span className="bc-link">{rid === '0' ? (
                        <span>{t('label_create_resource')}</span>
                    ) : (
                        <span>{t('label_view_lib_details')}</span>
                    )}</span>
                </div>
                <div class={`bg-details-highlight `}>
                    <div class="details-headertxt-singleLine container">
                        <div class="header_txt with-share-btn"> {rid === '0' ? (
                            <span class="with-share-btn-header">{t('label_create_resource')}</span>
                        ) : (
                            <>
                                <span class="with-share-btn-header">{tDetails && tDetails.title ? tDetails.title : ''}</span>
                            </>
                        )}</div>
                        <div className="details-headertxt-singleLine-btns">
                            {(userrole === "AD" || userrole === "AS") && (
                                <>
                                    {editable ? (
                                        <a
                                            className="button_move"
                                            onClick={onFinish}
                                            style={{ marginRight: '5px' }}
                                        >
                                            {t("label_save")}
                                        </a>
                                    ) : null}
                                    <a
                                        className="button_move mr-3"
                                        onClick={editable ? handleCancelClick : handleEditClick}
                                    >
                                        {buttonText}
                                    </a>
                                    <Select
                                        name="lang"
                                        value={selectedLang}
                                        onChange={(value) => setSelectedLang(value)}
                                        className="select-element mr-3"
                                        placeholder={t("label_select_language")}
                                    >
                                        {AP_LANGUAGES.map((item) => (
                                            <Option key={item.value} value={item.value}>
                                                {item.label}
                                            </Option>
                                        ))}
                                    </Select>
                                    <a className="button_move" onClick={handleTranslate}>
                                        {t('label_translate')}
                                    </a>
                                </>
                            )}
                        </div>

                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <Form form={form} name="nest-messages" onFinish={onFinish}
                        >
                            <div class="float-left w-100" ><h5 class="card-text-title">{t("label_resource_details")}
                            </h5></div>
                            <div class="active-details">
                                <div class="tiles-designs">
                                    <Form.Item
                                        name="title"
                                        label={<Typography.Title level={4}>{t('Subject')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Title"
                                                rows={4}
                                                defaultValue={tDetails.title}
                                                onBlur={(e) => handleName(e.target.value)}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className="typ">
                                                {tDetails && tDetails.title}
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                {(tDetails && (tDetails.res_type === "articles" || tDetails.res_type === "mantras")) && (
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="txt"
                                            label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            {editable ? (
                                                <TextArea
                                                    placeholder="Enter Details"
                                                    rows={4}
                                                    defaultValue={tDetails.txt ? tDetails.txt.split("<BR>").join("\n") : ""}
                                                    onBlur={(e) => handleTxt(e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text
                                                    style={{ whiteSpace: "pre-wrap" }}
                                                    level={5}
                                                    className="typ"
                                                >
                                                    <ShowMoreText
                                                        charLimit={200}
                                                        text={tDetails.txt ? tDetails.txt.split("<BR>").join("\n") : ""}
                                                    />
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                )}


                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t('label_avatar')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        {editable ? (
                                            <>
                                                <img
                                                    src={selectedDeity ? deityImages[selectedDeity] : deityImages[tDetails && tDetails.avatar]}
                                                    alt="Selected Deity Image"
                                                    onClick={handleImageClick}
                                                    style={{ cursor: 'pointer', width: '5%' }}
                                                />
                                                <p class="nmt-2">Click on the image to change</p>
                                                <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                                                    <div class="popup-main-filter animate__animated animate__fadeInUp ">
                                                        <div class="popup-main-header">
                                                            <span class="popup-main-header-text">{t('label_god_categ')}</span>
                                                            <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                            <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                        </div>
                                                        <div class="popup-main-container">
                                                            <div class="d-block htinner-popup-main-container">
                                                                <div className=" god-category-list">
                                                                    <div className="all-gods-sec">All</div>
                                                                    <div className="god_select">
                                                                        <div className="god_pic">
                                                                            <img
                                                                                className="dietyImg"
                                                                                src={aum}
                                                                                alt="All"
                                                                                onClick={() => handleDeityImageClick(null)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="all-gods-sec">{t("label_filter_by_god")}</div>
                                                                    <Row gutter={[16, 16]}>
                                                                        {godCtgryList && godCtgryList.map((godCtgry) => (
                                                                            <Col key={godCtgry.avatar}>
                                                                                <div className="god_select">
                                                                                    <div className="god_pic">
                                                                                        <img
                                                                                            src={deityImages[godCtgry.avatar]}
                                                                                            alt="Deity Image"
                                                                                            style={{
                                                                                                width: '100px',
                                                                                                height: '100px',
                                                                                                margin: '5px',
                                                                                                cursor: 'pointer',
                                                                                                border: '1px solid #ddd'
                                                                                            }}
                                                                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                                                        />
                                                                                    </div>
                                                                                    <p>{godCtgry.deity}</p>
                                                                                </div>
                                                                            </Col>
                                                                        ))}
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="popup-main-footer">
                                                            <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            // View mode: display image based on API data
                                            <img
                                                src={deityImages[tDetails && tDetails.avatar]}
                                                alt="Deity Image"
                                                style={{ width: '5%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </div>


                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='author'
                                        label={<Typography.Title level={4}>{t('label_author_speaker')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input placeholder="Enter Author" rows={4}
                                                defaultValue={tDetails.author}

                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{tDetails && tDetails.author}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='src'
                                        label={<Typography.Title level={4}>{t('label_source')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input placeholder="Enter Source" rows={4}
                                                defaultValue={tDetails.src}

                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{tDetails && tDetails.src}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                {/* </Col>
                                <Col span={12}> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        name='src_url'
                                        label={<Typography.Title level={4}>{t('label_source_link')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Source URL"
                                                rows={4}
                                                defaultValue={tDetails && tDetails.src_url}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>
                                                {tDetails && tDetails.src_url ? (
                                                    <Typography.Link
                                                        href={tDetails.src_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: 'blue', textDecoration: 'underline' }}
                                                    >
                                                        {tDetails.src_url}
                                                    </Typography.Link>
                                                ) : (
                                                    t('label_notchosen')
                                                )}
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                {/* </Col>
                            </Row> */}

                            </div>
                            {tDetails && tDetails.res_type === 'livetv' && (
                                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_library')}
                                </h5>

                                    <YouTube
                                        key={rid}
                                        temple={tDetails}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        favList={favList}
                                        getYouTubeVideoId={getYouTubeVideoId}
                                    />

                                </div>
                            )}
                            {tDetails && tDetails.res_type === 'videos' && (
                                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_library')}
                                </h5>

                                    <YouTube
                                        key={rid}
                                        temple={tDetails}
                                        toggleFavorite={toggleFavorite}
                                        toggleFilled={toggleFilled}
                                        favList={favList}
                                        getYouTubeVideoId={getYouTubeVideoId}
                                    />

                                </div>
                            )}
                            {tDetails && tDetails.res_type === 'audios' && (
                                <div class="float-left w-100" ><h5 class="card-text-title">{t('label_library')}
                                </h5>

                                    <div >
                                        <audio
                                            controls
                                        >
                                            <source src={getAudioAPI() + tDetails.src_url} type="audio/mpeg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>

                                </div>
                            )}
                            <AdBlock />
                            <div class="float-left w-100" ><h5 class="card-text-title">{t('label_additi_det')}
                                {/* <span>| {t('label_restype_lang')}</span> */}
                            </h5></div>

                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='res_type'
                                        label={<Typography.Title level={4}>{t('label_resource_type')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Typography.Text level={5} className='typ'>
                                            {tDetails && tDetails.res_type ? (
                                                (() => {
                                                    switch (tDetails.res_type) {
                                                        case "videos":
                                                            return t("label_videos");
                                                        case "audios":
                                                            return t("label_audios");
                                                        case "livetv":
                                                            return t("label_livetv");
                                                        case "books":
                                                            return t("label_books");
                                                        case "articles":
                                                            return t("label_articles");
                                                        case "mantras":
                                                            return t("label_mantras");
                                                        default:
                                                            return t('label_notchosen');
                                                    }
                                                })()
                                            ) : (
                                                t('label_notchosen')
                                            )}
                                        </Typography.Text>
                                    </Form.Item>
                                </div>


                                {/* </Col>
                                <Col span={12}> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="lang"
                                        label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a language.',
                                            },
                                        ]}
                                    >
                                        <Typography.Text level={5} className="typ">
                                            {tDetails ? (
                                                (() => {
                                                    const langValue = tDetails.lang ? tDetails.lang.toString().trim() : '';
                                                    switch (langValue) {
                                                        case '5':
                                                            return 'English';
                                                        case '1':
                                                            return 'తెలుగు';
                                                        case '2':
                                                            return 'ಕನ್ನಡ';
                                                        case '3':
                                                            return 'தமிழ்';
                                                        case '4':
                                                            return 'हिंदी';
                                                        case '6':
                                                            return 'हिंदी';
                                                        case '7':
                                                            return 'বাংলা';
                                                        default:
                                                            return t('label_notchosen');
                                                    }
                                                })()
                                            ) : (
                                                t('label_notchosen')
                                            )}
                                        </Typography.Text>
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="publish_date"
                                        label={<Typography.Title level={4}>{t('label_pub_date')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        {editable ? (
                                            <Input
                                                type="date"
                                                style={{ height: '42px', width: '200px' }}
                                                defaultValue={tDetails && tDetails.publish_date ? tDetails.publish_date : ''}
                                            />
                                        ) : (
                                            <Typography.Text level={3} className='typ'>
                                                {tDetails && tDetails.publish_date ? tDetails.publish_date : t('label_not_available')}
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs">
                                    <Form.Item
                                        name="tag1"
                                        label={<Typography.Title level={4}>{t('label_tag1')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Tag1"
                                                rows={4}
                                                defaultValue={tDetails.tag1}
                                            />
                                        ) : (
                                            tDetails && tDetails.tag1 && (
                                                <Typography.Text
                                                    level={5}
                                                    style={{
                                                        backgroundColor: 'orange',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    {tDetails.tag1}
                                                </Typography.Text>
                                            )
                                        )}
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs">
                                    <Form.Item
                                        name="tag2"
                                        label={<Typography.Title level={4}>{t('label_tag2')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Tag2"
                                                rows={4}
                                                defaultValue={tDetails.tag2}
                                            />
                                        ) : (
                                            tDetails && tDetails.tag2 && (
                                                <Typography.Text
                                                    level={5}
                                                    style={{
                                                        backgroundColor: 'orange',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    {tDetails.tag2}
                                                </Typography.Text>
                                            )
                                        )}
                                    </Form.Item>
                                </div>



                                {/* <button type="submit" class="btn btn-warning shadow-0 yellowbtn" data-mdb-ripple-color="dark"  >
                                    {t("label_save")}
                                </button> */}
                            </div>
                        </Form>
                        {userrole === 'AS' || userrole === 'AD' ? (
                            <>
                                <div className="float-left w-100">
                                    <h5 className="card-text-title">{t('label_cover_img')}</h5>
                                </div>
                                <div className="active-details">
                                    <div className="tiles-designs">
                                        {token ? (
                                            <div text="Add Image">
                                                {previewImage ? (
                                                    <img src={previewImage} alt="Captured Image" />
                                                ) : (
                                                    <>
                                                        {isMobile && (
                                                            <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                                                {t('label_camera')}
                                                            </button>
                                                        )}
                                                    </>
                                                )}

                                                <button className="button_move" onClick={handleGalleryClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>
                                                    {t('label_upload')}
                                                </button>
                                            </div>
                                        ) : (
                                            <div text="Add Image">
                                                {previewImage ? (
                                                    <img src={previewImage} alt="Captured Image" />
                                                ) : (
                                                    <>
                                                        {isMobile && (
                                                            <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                                                {t('label_camera')}
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                                <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>
                                            </div>
                                        )}
                                        <div className="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                                            <div className="popup-main animate__animated animate__fadeInUp ">
                                                <div className="popup-main-header">
                                                    <span className="popup-main-header-text">Main header</span>
                                                    <div className="popup-close" onClick={handleImageCloseModal}><i className="fa-solid fa-xmark"></i></div>
                                                    <div className="popup-back" onClick={handleImageCloseModal}><i className="fa-solid fa-chevron-left"></i></div>
                                                </div>
                                                <div className="popup-main-container">
                                                    <div className="d-block htinner-popup-main-container">
                                                        {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                                    </div>
                                                </div>
                                                <div className="popup-main-footer">
                                                    <button className="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                                                </div>
                                                {loading && <Error />}
                                                {hasError && <div className="error-message">Upload failed. Please try again.</div>}
                                            </div>
                                        </div>
                                        {tDetails && tDetails.img_path ? (
                                            <div className="tiles-grid">
                                                <Image.PreviewGroup>
                                                    <div>
                                                        <div className="detail-container detail-container-image">
                                                            <Image
                                                                className="card-img"
                                                                src={tDetails && tDetails.img_path ? getMediumImageAPI() + tDetails.img_path : 'default-image-path.jpg'}
                                                                alt="images"
                                                            />
                                                            {editable ? (
                                                                <Popconfirm
                                                                    title="Are you sure you want to delete?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <CloseCircleOutlined className="close-icon" />
                                                                </Popconfirm>
                                                            ) : null}
                                                            {editable ? (
                                                                <Popconfirm
                                                                    title="Are you sure you want to update?"
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <Checkbox className="close-icon1" />
                                                                </Popconfirm>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="detail-container detail-container-image">
                                                        {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                        {newImagePath && editable ? (
                                                            <CloseCircleOutlined className="close-icon" onClick={onFinish} />
                                                        ) : null}
                                                    </div>
                                                </Image.PreviewGroup>
                                            </div>
                                        ) : (
                                            <div className="detail-container detail-container-image">
                                                <img
                                                    className="card-img"
                                                    alt="temples"
                                                    src={deftemples}
                                                    style={{
                                                        width: '20%',
                                                        height: '20%',
                                                        marginBottom: '2px',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : null}

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};
export default EditResource;