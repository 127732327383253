import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceDetailsList } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooksByTag, fetchBooksFavourites, fetchResArticles, fetchResourceByTxt, fetchResourceDetails, postBooksFavourites, removeBooksFavourites } from '../../../../redux/actions/acions';
import { Col, FloatButton, Result, Row, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { CloseCircleOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI, getAudioAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import aum from '../../../../assets/icons/om.jpg'
import deityImages from '../../../common/godImages';
import Hammer from 'react-hammerjs';
import ResourceCard from '../../../common/resourceCard';
import godNames from '../../../common/godNames';

let last_rec = 0;
const CommonResource = ({ title, currentPage, bDetails, fetchResource, mainSecBlockRef, text, tDetails, sList, nextTemplesList, prevTemplesList }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [tag, setTag] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const { uid } = useParams()

  const shareResLink = (title, txt, url) => {
    if (navigator.share) {
        navigator.share({
            title: title,
            text: txt,
            url: 'https://www.templeswiki.com/share/'+url
        })
        .then(() => console.warn("Share successful."))
        .catch((error) => console.error("Error sharing:", error));
    } else {
        // Fallback for browsers that do not support the Web Share API
        console.error("Web Share API not supported.");
        window.flutter_inappwebview.callHandler('shareResLink', title, txt, url);
    }
  };

  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  const { godCtgryList } = useSelector(state => ({

    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  const [refresh, setRefresh] = useState(false);

  const success = (tname) => {
    showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
  };

  const warning = (tname) => {
    showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
  };

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };

  const [getFavouriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    {
      getToken && (
        dispatch(fetchBooksFavourites(text))
      )
    }
  }, [getFavouriteTemples, refresh]);

  useEffect(() => {
    setIsFavourite(favList.includes(selectedArticle));
  }, [selectedArticle, favList]);


  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
      // localStorage.setItem('articles', JSON.stringify(fList.fav_list));
    }
  }, [fList]);

  const [favList, setFavList] = useState([]);

  // useEffect(() => {
  //   if (fList && fList.fav_list) {
  //     setFavList(fList.fav_list);
  //   }
  // }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(text, rid, failure));
    setFavList(prevFavList => {
      const updatedFavList = [...prevFavList, rid];
      localStorage.setItem('articles', JSON.stringify(updatedFavList));
      return updatedFavList;
    });
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(text, deleteId, failure));
    setFavList(prevFavList => {
      const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
      localStorage.setItem('articles', JSON.stringify(updatedFavList));
      return updatedFavList;
    });
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;

      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        // setFavList(prevFavList => {
        //   const updatedFavList = prevFavList.filter(id => id !== templeId);
        //   localStorage.setItem('articles', JSON.stringify(updatedFavList));
        //   return updatedFavList;
        // });
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        // setFavList(prevFavList => {
        //   const updatedFavList = [...prevFavList, templeId];
        //   localStorage.setItem('articles', JSON.stringify(updatedFavList));
        //   return updatedFavList;
        // });
      }
    } else {
      // Handle the case when there's no token
      failure("Please log in to favorite temples");
    }
  }

  function formatBoldText(txt) {
    if (txt) {
        return <div dangerouslySetInnerHTML={{ __html: formatPlainText(txt) }} />;
    } else {
        return '';
    }
}

function formatPlainText(txt) {
    if (txt) {
        let allLines = txt.split("<BR>");
        return allLines.map(line => line.includes("<B>") ? `<p>${line}</p>` : `<p>${line}</p>`).join('');
    } else {
        return '';
    }
}



  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }

  useEffect(() => {
    if (uid) {
      setSelectedItem(uid === "all" ? null : uid);
    }
  }, [uid]);

  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null); // Reset selectedItem to null to enable all buttons     
      dispatch(fetchResource(text, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  useEffect(() => {
    if (selectedItem === null) {
      dispatch(fetchResource(text, storedLanguage, did, 0))
    }
    else if (selectedItem != null) {
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, 0));
    }
  }, [storedLanguage, did])

  const categoryButtons = (text === 'audios')
    ? ['yoga', 'chanting', 'songs', 'bhajans', 'keertans']
    : (text === 'mantras')
      ? ['sahasra', 'ashtakam', 'pujas', 'chanting', 'vedic', 'chalisa', 'keertan', 'bhajan', 'festival']
      : (text === 'books')
        ? ['puranas', 'bhagavath', 'kids', 'gods', 'stories', 'spiritual', 'keerthanas', 'goddess', 'vedic']
        : (text === 'articles')
          ? ['festivals', 'pujas', 'sukthulu', 'gods', 'puranas', 'gurus']
          : [];

  const [isArticleModalVisible, setIsArticleModalVisible] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  const closeModal = () => {
    setIsArticleModalVisible(false);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (isArticleModalVisible) {
        closeModal();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (isArticleModalVisible && event.key === 'Escape') {
        closeModal();
        event.preventDefault();
      }
    };
    if (isArticleModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isArticleModalVisible, closeModal]);


  const handleArticleClick = (temple) => {
    // Merge all available `rid` lists
    const allDetails = [...(bDetails || []), ...(sList || []), ...(tDetails || [])];

    if (allDetails.length > 0) {
      const ridList = allDetails.map((article) => article.rid); // Extract all `rid`s
      localStorage.setItem('ridList', JSON.stringify(ridList));
      localStorage.setItem('currentRidIndex', ridList.indexOf(temple.rid)); // Store current index
    }

    // Check if the src_url is "www.templeswiki.com"
    if (temple.src_url === 'www.templeswiki.com') {
      localStorage.setItem('src_url', temple.src_url); // Store src_url in localStorage
      setSelectedArticleId(temple.rid); // Set selected article
      setIsArticleModalVisible(true); // Show popup
    } else {
      // Make sure the URL starts with http:// or https://
      let templeUrl = temple.src_url.trim();

      // If no protocol is present, prepend http://
      if (!templeUrl.startsWith('http://') && !templeUrl.startsWith('https://')) {
        templeUrl = `http://${templeUrl}`;
      }

      // Detect if the device is mobile
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // For mobile devices, navigate to the absolute URL
        window.location.href = templeUrl;
      } else {
        // For web, try opening the URL in a new tab
        const newWindow = window.open(templeUrl, '_blank', 'noopener,noreferrer');

        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          // Fallback to opening in the same tab if popups are blocked
          window.location.href = templeUrl;
        }
      }
    }
  };






  // Function to handle closing the article popup
  const handleArticleCancel = () => {
    setIsArticleModalVisible(false);
  };



  const handleNextArticle = () => {
    const ridList = JSON.parse(localStorage.getItem('ridList')) || [];
    const currentIndex = parseInt(localStorage.getItem('currentRidIndex'), 10);

    if (currentIndex >= 0 && currentIndex < ridList.length - 1) {
      const nextIndex = currentIndex + 1;
      setSelectedArticleId(ridList[nextIndex]);
      localStorage.setItem('currentRidIndex', nextIndex);

    }

  };

  const handlePrevArticle = () => {
    const ridList = JSON.parse(localStorage.getItem('ridList')) || [];
    const currentIndex = parseInt(localStorage.getItem('currentRidIndex'), 10);

    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setSelectedArticleId(ridList[prevIndex]);
      localStorage.setItem('currentRidIndex', prevIndex);
    }
  };


  const [isFavourite, setIsFavourite] = useState(false);
  useEffect(() => {
    const cachedFavourites = JSON.parse(localStorage.getItem('articles')) || [];
    if (cachedFavourites) {
      setIsFavourite(cachedFavourites.includes(selectedArticle));
    }
    else {
      dispatch(fetchResArticles(storedLanguage, last_rec));
    }
  }, [selectedArticle]);




  // useEffect(() => {
  //   dispatch(fetchResourceByTxt(text, storedLanguage, searchText, 0));
  // }, [searchText]);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (uid) {
      setSearchText(uid);
      if (uid.length >= 5) {
        dispatch(fetchResourceByTxt(text, storedLanguage, uid, 0));
      }
    }
  }, [uid, dispatch]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(text, storedLanguage, newValue, 0));
    }
  };

  const [isGodModalVisible, setIsGodModalVisible] = useState(false);

  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      dispatch(fetchResource(text, storedLanguage, 0, 0));
    } else {
      setDid(gDid);
      setFilterId(gDid);
      dispatch(fetchResource(text, storedLanguage, gDid, 0));
    }
  }

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (bDetails || sList || tDetails) {
      setLoading(false);
    }
  }, [bDetails, sList, tDetails]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Return an empty string if string is null or undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderContent = () => {
    const CardComponent = ResourceCard;

    if (loading) {
      return (
        <div className="loading">
          <Error />
        </div>
      );
    }

    if (!searchText && !selectedItem) {
      if (bDetails && bDetails.length > 0) {
        return bDetails.map((temple) => {
          const templeUrl = temple.src_url.startsWith("http") ? temple.src_url : `http://${temple.src_url}`;
          const target = (text === 'mantras' ? "_self" : "_blank");

          return (
            <CardComponent
              key={temple.rid}
              id={temple.rid}
              imgpath={temple.imgpath}
              title={temple.title}
              city={formatBoldText(temple.info)}
              temple={temple}
              favList={favList}
              toggleFavorite={toggleFavorite}
              toggleFilled={toggleFilled}
              link={(text === 'audios' ? getAudioAPI() + temple.src_url : templeUrl)}
              target={target}
              handleClick={() => handleArticleClick(temple)}
              text={text}
              publish_date={temple.publish_date}
              author={temple.author}
            />
          );
        });
      }

      return (
        <div className="centered-container">
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_resources_msg")}
        />
        </div>
      );
    }

    if (uid >= 5 || (searchText && searchText.length >= 5)) {
      if (sList && sList.length > 0) {
        return sList.map((temple) => (
          <CardComponent
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={formatBoldText(temple.info)}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
            handleClick={() => handleArticleClick(temple)}
            text={text}
            publish_date={temple.publish_date}
            author={temple.author}
          />
        ));
      }
      return (
        <div className="centered-container">
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_resources_msg")}
        />
        </div>
      );
    }

    if (selectedItem != null) {
      if (tDetails && tDetails.length > 0) {
        return tDetails.map((temple) => (
          <CardComponent
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={formatBoldText(temple.info)}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
            handleClick={() => handleArticleClick(temple)}
            text={text}
            publish_date={temple.publish_date}
            author={temple.author}
          />
        ));
      }
      return (
        <div className="centered-container">
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_resources_msg")}
        />
        </div>
      );
    }

    if (bDetails && bDetails.length > 0) {
      return bDetails.map((temple) => (
        <CardComponent
          key={temple.rid}
          id={temple.rid}
          imgpath={temple.imgpath}
          title={temple.title}
          city={formatBoldText(temple.info)}
          temple={temple}
          favList={favList}
          toggleFavorite={toggleFavorite}
          toggleFilled={toggleFilled}
          link={(text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
          target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
          handleClick={() => handleArticleClick(temple)}
          text={text}
          publish_date={temple.publish_date}
          author={temple.author}
        />
      ));
    }

    return (
      <div className="centered-container">
      <Result
        status="404"
        title={t("label_nodata_found")}
        subTitle={t("label_no_resources_msg")}
      />
      </div>
    );
  };


  const { resDetails } = useSelector(state => ({
    resDetails: getResourceDetailsList(state) || [], // Default to empty array if null/undefined
  }));

  useEffect(() => {
    if (selectedArticleId) {
      const ridList = JSON.parse(localStorage.getItem('ridList')) || [];

      if (ridList.length > 0) {
        console.log("Fetching details for rid:", selectedArticleId);
        dispatch(fetchResourceDetails(storedLanguage, selectedArticleId, failure));
      } else {
        console.error("ridList is empty. Cannot navigate to next/prev article.");
      }
    }
  }, [selectedArticleId, storedLanguage, dispatch]);


  const selectedArticle = resDetails && resDetails.rid === selectedArticleId ? resDetails : null;

  const [animationDuration, setAnimationDuration] = useState("40s"); // Default duration
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const textHeight = textRef.current.scrollHeight;
      const containerHeight = textRef.current.offsetHeight;

      if (textHeight > containerHeight) {
        // Adjust duration based on content height
        const duration = textHeight / 100; // Adjust the divisor for speed
        setAnimationDuration(`${duration}s`);
      }
    }
  }, [text]);

  // const [scrolling, setScrolling] = useState(false);

  // const handlePlayPause = () => {
  //   setScrolling((prevState) => !prevState); // Toggle scrolling state
  // };

  return (
    <>
      {contextHolder}

      <div class="mainsec">

        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span className="bc-link">{title}</span>
          </div>

          <div class="right-sec-mob-nav d-flex align-items-center">
            <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
              <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>
              {did !== 0 && (
                <>
                  <div className="detail-container">
                    <span className="god-button">{godNames[filterId]}</span>
                    <CloseCircleOutlined
                      className="close-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFilterId(0);
                        setDid(0);
                        dispatch(fetchResource(storedLanguage, 0, 0));
                      }}
                    />
                  </div>
                </>
              )}
              {did === 0 && (
                <div className="detail-container d-flex align-items-center">
                  <span className="detail-but-god ml-2">{t("label_all")}</span>
                </div>
              )}

            </button>
          </div>
        </div>


        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
          <div class="d-flex">
            {/* <Link to='/library' style={{ color: 'black' }} className='search-back'><i class="fa-solid fa-angle-left"></i></Link> */}

            <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">
              {t('Home')}</span>
            </Link>&nbsp;-&nbsp;{' '}
            <Link to='/library'>
              <span className="bc-active-link" > {t('label_library')}</span></Link>&nbsp;- <span className="bc-link ml-2 f2"> {title}</span>{' '}&nbsp;&nbsp;
            <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
              <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>
              {did !== 0 && (
                <>
                  <div className="detail-container">
                    <span className="god-button">{godNames[filterId]}</span>
                    <CloseCircleOutlined
                      className="close-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFilterId(0);
                        setDid(0);
                        dispatch(fetchResource(storedLanguage, 0, 0));
                      }}
                    />
                  </div>
                </>
              )}
              {did === 0 && (
                <div className="detail-container d-flex align-items-center">
                  <span className="detail-but-god ml-2">{t("label_all")}</span>
                </div>
              )}

            </button>
          </div>
        </div>

        <div class="popup-bg" style={{ display: isGodModalVisible ? 'flex' : 'none' }}>
          <CustomToastContainer />
          <div class="popup-main-filter animate__animated animate__fadeInUp ">
            <div class="popup-main-header-filter">
              <span class="popup-main-header-text">{t("label_god_categ")}</span>
              <div class="popup-close" onClick={handleFilterGodCancel}><i class="fa-solid fa-xmark"></i></div>
              <div class="popup-back" onClick={handleFilterGodCancel}><i class="fa-solid fa-chevron-left" title={t('label_previous')}></i></div>
            </div>
            <div class="popup-main-container-filter">
              <span className="f13-fw-600 d-block float-left">{t('label_god_categ')}</span>
              <div class="d-block float-left content-filter">
                <div className=" god-category-list1">


                  <div class="all-gods-sec">
                    {t('label_all')}
                  </div>
                  <div class="god_select">
                    <div class="god_pic">
                      <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                        onClick={() => {
                          setFilterId(0);
                          setDid(0);
                          dispatch(fetchResource(storedLanguage, 0, 0));
                          setIsGodModalVisible(false);
                        }}
                      />
                    </div>
                  </div>


                  <div class="all-gods-sec">
                    {t('label_filter_by_god')}
                  </div>
                  <Row gutter={[16, 16]}>
                    {godCtgryList &&
                      godCtgryList.map((godCtgry, index) => (
                        <Col key={godCtgry.avatar}>
                          <div class="god_select">
                            <div class="god_pic">
                              <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                                onClick={() => {
                                  filterTemplesList(godCtgry.avatar);
                                  setIsGodModalVisible(false);
                                }}
                              />
                              <p>{godCtgry.deity}</p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
            <div class="popup-main-footer">
              <a className="button_move" onClick={handleFilterGodCancel}>{t('label_cancel')}</a>
            </div>
          </div>
        </div>
        <div class="details-icons-main"><div class="container details-icons">
          <button
            className={`details-icons-list ${selectedItem === null ? 'selected' : ''}`}
            onClick={() => handleButtonClick("All")}>{t('label_all')}</button>
          {categoryButtons.map((category, index) => (
            <button
              key={index}
              type="button"
              className={`details-icons-list ${selectedItem === category ? 'selected' : ''}`}
              onClick={() => handleButtonClick(category)}
            >
              {t('label_' + category)}

            </button>
          ))}
        </div>
        </div>
        <div class="mainsec-block-addtemple-detailspage2" ref={mainSecBlockRef}>
          <div className="container pt-3">
            <Row>
              {renderContent()}
            </Row>
            {(text === 'articles' || text === 'mantras') && (
              <div class="popup-bg" style={{ display: isArticleModalVisible ? 'flex' : 'none' }}>
                <CustomToastContainer />
                <div class="popup-main-artical animate__animated animate__fadeInUp "

                >

                  <div class="popup-main-header-artical">
                    <div class="flex-grow-1 overflow-hidden">
                      <span class="popup-main-header-text-artical">{capitalizeFirstLetter(resDetails && resDetails.title) || 'N/A'}</span>
                      <div class="popup-close" onClick={handleArticleCancel}><i class="fa-solid fa-xmark"></i></div>
                      <div class="popup-back" onClick={handleArticleCancel}><i class="fa-solid fa-chevron-left" title={t('label_previous')}></i></div>
                      <p>
                        <span className="article-titletxt">{t('label_author')} : </span>
                        <Link to={`/publicprofile/${temple.rid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {capitalizeFirstLetter(resDetails && resDetails.author) || 'N/A'}
                        </Link>
                      </p>
                      <p>
                        <span className="article-titletxt">{t('label_pub_date')} : </span>
                        <Link to={`/publishdate/${temple.rid}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          {capitalizeFirstLetter(resDetails && resDetails.publish_date) || 'N/A'}
                        </Link>
                      </p>
                      {/* <div class="detailstxt-artical-icon">
                        {scrolling ? (
                          <i className="fa-solid fa-pause" title="Pause" onClick={handlePlayPause}></i>
                        ) : (
                          <i className="fa-solid fa-play" title="Play" onClick={handlePlayPause}></i>
                        )}
                      </div> */}
                    </div>
                    <div class="article-image">
                      <img
                        src={deityImages[resDetails && resDetails.avatar]}
                        alt="Selected Deity Image"
                        style={{ width: '110px', height: '100%' }}
                      />
                    </div>
                    <div>
                    </div>

                  </div>
                  <Hammer
                    onSwipe={(event) => {
                      if (event.direction === 2) { // Left swipe for next
                        handleNextArticle();
                      } else if (event.direction === 4) { // Right swipe for previous
                        handlePrevArticle();
                      }
                    }}
                  >
                    <div class="popup-main-container-artical">
                      <div class="htinner-popup-main-container-artical ">
                        {/* <Link className='ignore-styles' to={`/editResource/${selectedArticle && selectedArticle.rid}?prevPage=${window.location.pathname}`}>
                      <img
                        className="grp-descr-img"
                        alt={selectedArticle ? selectedArticle.tname : 'Default Temple'}
                        src={selectedArticle && selectedArticle.imgpath ? getImageAPI() + selectedArticle.imgpath : deftemples}
                      />
                    </Link> */}


                        <div className='dialog-content-text-artical ' style={{ whiteSpace: "pre-wrap" }}>
                          <Hammer
                            onSwipe={(event) => {
                              if (event.direction === 2) { // Left swipe for next
                                handleNextArticle();
                              } else if (event.direction === 4) { // Right swipe for previous
                                handlePrevArticle();
                              }
                            }}
                          >
                            <div class="dialog-content-text-articaltxt">
                              <Hammer
                                onSwipe={(event) => {
                                  if (event.direction === 2) {
                                    handleNextArticle(); // Left swipe for next
                                  } else if (event.direction === 4) {
                                    handlePrevArticle(); // Right swipe for previous
                                  }
                                }}
                              >
                                <p class={`dialog-content-text-articaltxt-articalmain-data ${text === "mantras" ? "scrollable auto-scroll" : ""}`}>
                                  <span className='bold-text-line-popup'>
                                  {/* <span id="scrollableText" class="scrollable-text" ref={textRef} style={{ animationPlayState: scrolling ? 'running' : 'paused' }}> */}
                                    {formatBoldText(resDetails && resDetails.txt)}
                                  </span>
                                </p>

                              </Hammer>
                            </div>

                          </Hammer>
                          <div class="like-share-section " >
                            <button class="detailstxt-artical-icon ml-3" onClick={handlePrevArticle} style={{ float: 'left', marginRight: "2px" }}>
                              <i class="fa-solid fa-chevron-left" title={t('label_previous')}></i></button>

                            <div class="article_btm-btns">

                              <button class="detailstxt-artical-icon">
                                <Link to={`/editResource/${selectedArticleId}`}>
                                  <i class=" fa-solid fa-circle-info" title={t('Information')}></i>
                                </Link>
                                {/* <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i>
                            </button>
                            <button class="detailstxt-artical-icon">
                              <i className="fa-solid fa-share" style={{ fontSize: '24px' }}></i> */}
                                <a
                                  className="share-button"
                                  onClick={() => shareResLink("Know " + title,
                                        "Know " + title,
                                        `templeDetails/${temple.rid}?prevPage=${window.location.pathname}`
                                  )}
                                >
                                  <i title={t('label_share')} className="fa-solid fa-share-nodes" style={{ fontSize: '24px' }}></i>
                                </a>

                                <a
                                  className="share-button"
                                  onClick={() => toggleFavorite(selectedArticleId, temple.title)}
                                >
                                  {favList.some(t => t === temple.rid) ? (
                                    <i className="fa-solid fa-heart" title={t('Favourites')}></i>
                                  ) : (
                                    <i className="fa-regular fa-heart"></i>
                                  )}
                                </a>

                                {/* <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === temple.rid) ? (
                                    <i className="fa-solid fa-heart" style={{fontSize: '24px'}}></i>
                                ) : (
                                    <i className="fa-regular fa-heart" style={{fontSize: '24px'}}></i>
                                )}
                            </a> */}
                                {/* <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i> */}

                              </button>
                            </div>
                            {isLoading && (<Error />)}
                            <button class="detailstxt-artical-icon mr-3" onClick={handleNextArticle} style={{ float: 'right' }}>
                              <i class="fa-solid fa-chevron-right" title={t('label_next')}></i></button>
                          </div>

                        </div>


                      </div>
                      <div class="popup-main-footer d-none">
                        <button className="button_move" onClick={handlePrevArticle} style={{ float: 'left', marginRight: "2px" }}>{t('label_previous')}</button>
                        <button className="button_move" onClick={handleNextArticle} style={{ float: 'right' }}>{t('label_next')}</button>
                      </div>

                    </div>
                  </Hammer>
                </div>
              </div>
            )}

            <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} />
          </div>
          <div className="next-row">
            {bDetails && bDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={currentPage === 0 ? null : prevTemplesList}
                  disabled={currentPage === 0}
                >
                  {t("label_previous")}
                </a>
              </Col>
            ) : (
              ''
            )}
            {bDetails && bDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={bDetails.length < 12 ? null : nextTemplesList}
                  disabled={bDetails.length < 12}
                >
                  {t("label_next")}
                </a>
              </Col>
            ) : (
              ''
            )}
          </div>
          <Footer />
        </div>
      </div>

    </>
  )
}


export default CommonResource;