import React, { useEffect, useState } from 'react';

import { Button, Col, Row, Select, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceError, getResourceList, getResourceLoading } from '../../../../redux/selectors/selectors';
import { fetchResource, publishResource, removeResource } from '../../../../redux/actions/acions';
import { getImageAPI } from '../../../../http-common';
import { Link, useNavigate, useParams } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.png';
import { DeleteOutlined, EditOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TempleCard from '../../../common/card';

function Resource() {
  const [isPublished, setIsPublished] = useState(false);
const storedLanguage = localStorage.getItem('lng') || 5;
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const rid = useParams();
  const { t } = useTranslation();



  const dispatch = useDispatch();
  const { nList, nLoading, nError } = useSelector((state) => ({
    nList: getResourceList(state),
    nLoading: getResourceLoading(state),
    nError: getResourceError(state),
  }));

  useEffect(() => {
    // Initial API request on component mount
    dispatch(fetchResource(storedLanguage, 0));
  }, [storedLanguage, dispatch]);

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  function deleteResource() {
    dispatch(removeResource(rid));

  }

  function publishResources(rid) {
    dispatch(publishResource(nList.rid));
    setIsPublished(!isPublished);
  }

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div class='mainsec'>
      <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
        <Link style={{ color: 'black' }} to='/'>
          <span className="bc-active-link">{t('Home')}</span>
        </Link> -
        {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
        {/* &nbsp; <span className="bc-active-link" onClick={handleGoBack}>Resources</span> - */}
        &nbsp; <span className="bc-link">{t("label_libraries")}</span>
      </div>
      <div class="bg-details-highlight">
        <div class=" details-headertxt container">
          <div class="header_txt"> Resources List</div>
          <div class="d-flex flex-row header_star mr-5">
            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
            </span>
          </div>
        </div>
      </div>
      <div className="container" style={{ display: 'flex', justifyContent: 'end' }}>
        <Link to='/editResource/0'><button type="button" class="btn1 btn btn-secondary " style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('Create Resource')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
      </div>
      <div class="mainsec-block-addtemple-detailspage">
        <div className='container'>
          <section className="container h-100 d-flex flex-column">
            <div className="search-rt-sec1 ">
              <Row>
                {
                  nList && nList.map(temple => {
                    return (
                      <>
                      {nList && nList.length > 0 ? (
                        nList.map((temple) => (
                          <TempleCard
                            key={temple.rid}
                            id={temple.rid}
                            imgpath={temple.imgpath}
                            title={temple.title}
                            city={temple.publish_date}
                            state={
                              <a href={temple.src_url} target="_blank" rel="noopener noreferrer">
                                Click Here
                              </a>
                            }
                            temple={temple}
                            //favList={favList}
                            //toggleFavorite={toggleFavorite}
                            //toggleFilled={toggleFilled}
                            link={temple.src_url}
                          />
                        ))
                      ) : (
                        <p className='container'>No Resources Found</p>
                      )}
                      </>
                    )
                  })
                }
              </Row>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Resource;
